import React, { useState } from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { WarningFilled } from '@carbon/icons-react';

//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { useGrid } from 'app/contexts/gridProvider';

import { GridFields } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './EditableTextCellRenderer.module.pcss';

const b = block(style);

interface EditableTextCellRendererProps extends ICellRendererParams {
  onBlur: (value: string) => void;
  value: string;
  errorItems?: Record<string, string[]>[];
  errorMessage?: string;
  disabledCellInputs?: Record<string, string>[];
  header?: string;
}

const EditableTextCellRenderer: React.FC<EditableTextCellRendererProps> = (props: EditableTextCellRendererProps) => {
  const { onBlur, value, errorItems, errorMessage, disabledCellInputs, header, node } = props;
  const { isCommentEditable } = useGrid();
  const [text, updateText] = useState(value);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const errorItem = errorItems?.find((item) => item[node.id]);
  const hasError = errorItem?.[node.id]?.includes(GridFields.COMMENT);

  const onTextChange = (e) => {
    setIsTooltipVisible(false);
    updateText(e.target.value);
  };

  const onBlurText = () => {
    onBlur(text);
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      onBlur(text);
    }
  };

  const onMouseOver = (e) => {
    const { scrollWidth, offsetWidth } = e.target;

    if (scrollWidth <= offsetWidth) {
      setIsTooltipVisible(false);
    } else {
      setIsTooltipVisible(true);
    }
  };

  const onMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const disabledItem = disabledCellInputs?.find((item) => item[node.id]);
  const shouldBeDisabled = disabledItem?.[node.id]?.includes(header) || !isCommentEditable;
  const className = b('editableInput', { disabled: shouldBeDisabled, error: hasError });

  return (
    <div className={b('editableInputWrapper')} data-testid="text-cell-renderer-wrapper">
      {hasError && (
        <MessageTooltip
          data-testid="error-tooltip"
          content={errorMessage}
          target={
            <span className={b('errorIcon')}>
              <WarningFilled data-testid="error-icon" />
            </span>
          }
          placement={'left'}
        />
      )}
      <MessageTooltip
        data-testid="text-cell-renderer-tooltip"
        className={b('editableInputTooltip')}
        content={text}
        placement={'top'}
        theme={'light'}
        isOpen={isTooltipVisible}
        target={
          <input
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onChange={onTextChange}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            onKeyUp={onKeyUp}
            onBlur={onBlurText}
            value={text}
            autoFocus
            className={className}
            data-testid="text-cell-renderer-input"
            disabled={shouldBeDisabled}
          />
        }
      />
    </div>
  );
};

export default EditableTextCellRenderer;
