import React from 'react';

import {
  WatsonHealthStackedMove,
  TrashCan,
  WarningAlt,
  IncompleteCancel,
  AddAlt,
  Undo,
  CubeView,
  View
} from '@carbon/icons-react';

import { MapActions } from 'app/models';

export const MapActionIcons: Record<MapActions, JSX.Element> = {
  Undo: <Undo />,
  FixConflict: <WarningAlt />,
  Delete: <TrashCan />,
  Create: <AddAlt />,
  Reassign: <WatsonHealthStackedMove />,
  Unassign: <IncompleteCancel />,
  Isolate: <CubeView />,
  Ignore: <View />
};
