import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { Placement } from '@blueprintjs/popover2';
import { AddAlt, Edit, Error, WarningAltFilled } from '@carbon/icons-react';

//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { IconButtonCellRendererType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './IconButtonCellRenderer.module.pcss';

const b = block(style);

const IconButtonCellRendererMap = {
  [IconButtonCellRendererType.EDIT_TERRITORY]: {
    content: formatMessage('EDIT_TERRITORY'),
    icon: <Edit data-testid="edit-cell-renderer-icon" />
  },
  [IconButtonCellRendererType.EDIT_ASSIGNMENT]: {
    content: formatMessage('EDIT_ASSIGNMENT'),
    icon: <Edit data-testid="edit-assignment-cell-renderer-icon" />
  },
  [IconButtonCellRendererType.ADD]: {
    content: formatMessage('CREATE_FIELD'),
    icon: <AddAlt size={24} data-testid="add-icon" tabIndex="0" style={{ marginLeft: '-4px' }} />
  },
  [IconButtonCellRendererType.NON_MAPPABLE]: {
    content: formatMessage('NON_MAPPABLE_TOOLTIP'),
    icon: <Error data-testid="non-mappable-button" style={{ cursor: 'not-allowed' }} />
  },
  [IconButtonCellRendererType.CONFLICT]: {
    content: formatMessage('CONFLICTING_RULES_GRID_TOOLTIP'),
    icon: <WarningAltFilled data-testid="rule-conflict-icon" style={{ color: 'var(--intent-warning)' }} />
  }
};

interface IconButtonCellRendererProps extends ICellRendererParams {
  onEditCellClick?: (data) => void;
  onAssignmentViewClick?: (data) => void;
  iconCellRendererType: IconButtonCellRendererType;
  tooltipPosition?: Placement;
}

const IconButtonCellRenderer: React.FC<IconButtonCellRendererProps> = ({
  onEditCellClick,
  onAssignmentViewClick,
  iconCellRendererType = IconButtonCellRendererType.EDIT_TERRITORY,
  tooltipPosition = 'left',
  data
}: IconButtonCellRendererProps) => {
  const isEditButton = iconCellRendererType === IconButtonCellRendererType.EDIT_TERRITORY && !!onEditCellClick;
  const isAssignmentViewButton =
    iconCellRendererType === IconButtonCellRendererType.EDIT_ASSIGNMENT && !!onAssignmentViewClick;

  if (!data) {
    return null;
  }

  return (
    <div
      className={b()}
      onClick={(event) => {
        event.stopPropagation();

        if (isEditButton) {
          onEditCellClick(data);
        } else if (isAssignmentViewButton) {
          onAssignmentViewClick(data);
        }
      }}
      data-testid={IconButtonCellRendererMap[iconCellRendererType].content}
    >
      <MessageTooltip
        content={IconButtonCellRendererMap[iconCellRendererType].content}
        target={<div className={b('icon')}>{IconButtonCellRendererMap[iconCellRendererType].icon}</div>}
        placement={tooltipPosition}
      />
    </div>
  );
};

export default IconButtonCellRenderer;
