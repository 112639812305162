import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { UpsertSegment, UpsertSegmentVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const UPSERT_SEGMENT = gql`
  mutation UpsertSegment($input: UpsertSegmentInput!) {
    upsertSegment(input: $input) {
      __typename
      segmentId
    }
  }
`;

export const useUpsertSegment = createUseMutation<UpsertSegment, UpsertSegmentVariables>(UPSERT_SEGMENT);
