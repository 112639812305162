import React from 'react';

import { Classes } from '@blueprintjs/core';
import { Field } from 'formik';

//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import SelectMenu from 'components/SelectMenu/SelectMenu';

import { MeasureFieldType, MeasureFormatType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SheetsAddFieldFormFields.module.pcss';

const b = block(style);

interface CustomMeasureFormPartOneProps {
  isEditable: boolean;
}

const typeDropDownData = [
  { key: formatMessage('HISTORICAL'), value: MeasureFieldType.HISTORICAL },
  { key: formatMessage('INPUT'), value: MeasureFieldType.INPUT },
  { key: formatMessage('CALCULATED'), value: MeasureFieldType.CALCULATED }
];

const formatDropDownData = [
  { key: formatMessage('CURRENCY'), value: MeasureFormatType.CURRENCY },
  { key: formatMessage('PERCENTAGE'), value: MeasureFormatType.PERCENTAGE },
  { key: formatMessage('NUMERIC'), value: MeasureFormatType.NUMERIC }
];

export const CustomMeasureFormPartOne: React.FC<CustomMeasureFormPartOneProps> = ({
  isEditable
}: CustomMeasureFormPartOneProps) => {
  const tooltipMessage = !isEditable ? formatMessage('BUILT_IN_MEASURES_CANNOT_EDIT') : '';

  return (
    <MessageTooltip
      content={tooltipMessage}
      disabled={isEditable}
      target={
        <div data-testid="form-one">
          <div className={b('formInput')} data-testid="measure-field-type-field">
            <label className={`${b('label')} ${Classes.LABEL}`}>{formatMessage('SOURCE')}</label>
            <Field
              name="measureFieldType"
              theme="default"
              component={SelectMenu}
              placeHolderText={formatMessage('SELECT')}
              items={typeDropDownData}
              shouldValidateOnTouch={false}
              disabled={!isEditable}
            />
          </div>
          <div className={b('formInput')} data-testid="measure-format-type-field">
            <label className={`${b('label')} ${Classes.LABEL}`}>{formatMessage('FORMAT')}</label>
            <Field
              name="measureFormatType"
              theme="default"
              placeHolderText={formatMessage('SELECT')}
              component={SelectMenu}
              items={formatDropDownData}
              shouldValidateOnTouch={false}
              disabled={!isEditable}
            />
          </div>
        </div>
      }
      placement={'top'}
      className={b('formOneTooltip')}
    />
  );
};

export default CustomMeasureFormPartOne;
