import React from 'react';

import { ChevronRight, Currency, Diagram, RulerAlt, WarningAltFilled } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';

import { MenuItem } from 'components/menu/MenuItem';
//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { getNumberOfCurrencies } from 'app/containers/Currency/currencyPageUtils';

import { useLocalization } from 'app/contexts/localizationProvider';

import { CommandCenterMenuItem, ConversionRates, Hierarchy, PlanTargetsMeasure, QuotaComponent } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CommandCenterMenu.module.pcss';

const b = block(style);

export const CommandCenterMenuPlanningCycle = ({
  changeActiveMenuItem,
  quotaComponentList,
  conversionRates,
  isOnFullMenuView,
  activeMenu,
  handleHierarchyItemClicked,
  measuresData
}: {
  changeActiveMenuItem: ({ key, hierarchy }: { key: string; hierarchy: Hierarchy }) => void;
  quotaComponentList: QuotaComponent[];
  conversionRates: ConversionRates[];
  isOnFullMenuView: boolean;
  activeMenu: string | unknown;
  handleHierarchyItemClicked: () => void;
  measuresData: PlanTargetsMeasure[];
}): React.ReactElement => {
  const { doesInvalidCurrencyConversionExist } = useLocalization();

  const getCurrencyLabel = () => {
    let currencyLabel;

    const numOfCurrencies = getNumberOfCurrencies(conversionRates);

    if (!numOfCurrencies) {
      currencyLabel = formatMessage('CURRENCIES_WITH_COUNT', { count: '0' });
    } else if (numOfCurrencies === 1) {
      currencyLabel = formatMessage('CURRENCY_WITH_COUNT', { count: numOfCurrencies });
    } else {
      currencyLabel = formatMessage('CURRENCIES_WITH_COUNT', { count: numOfCurrencies });
    }

    return currencyLabel;
  };

  // Get the sub-title display for Quota components.
  const getQuotaComponentLabel = () => {
    let quotaComponentLabel;

    if (!quotaComponentList?.length) {
      quotaComponentLabel = formatMessage('QUOTA_COMPONENTS_WITH_VALUE', { value: '0' });
    } else if (quotaComponentList?.length === 1) {
      quotaComponentLabel = formatMessage('QUOTA_COMPONENT_WITH_VALUE', { value: quotaComponentList.length });
    } else if (quotaComponentList.length > 1) {
      quotaComponentLabel = formatMessage('QUOTA_COMPONENTS_WITH_VALUE', { value: quotaComponentList.length });
    }

    return quotaComponentLabel;
  };

  return (
    <>
      <li className={b('menuHeader')} data-testid={'menu-header-planning-cycle'}>
        {isOnFullMenuView && <HTMLHeading tagLevel="h5" text={formatMessage('PLANNING_CYCLE')} />}
      </li>
      <MenuItem
        data-testid={`cc-menu-item-currencies`}
        className={b('menuItem', { active: activeMenu === CommandCenterMenuItem.CURRENCIES })}
        icon={<Currency size={24} />}
        key={CommandCenterMenuItem.CURRENCIES}
        target={CommandCenterMenuItem.CURRENCIES}
        onClick={() => changeActiveMenuItem({ key: CommandCenterMenuItem.CURRENCIES, hierarchy: null })}
        text={
          <div className={b('menuItemTextContainer')}>
            <div>
              <div className={b('menuItemText')} data-testid={'menu-item-text-currencies'}>
                <HTMLHeading tagLevel="h6" text={formatMessage('CURRENCIES')} />
              </div>
              <div className={b('menuItemSubtext')} data-testid={'menu-item-subtext-currencies'}>
                {getCurrencyLabel()}
              </div>
            </div>
            <div>
              {doesInvalidCurrencyConversionExist ? (
                <MessageTooltip
                  popoverClassName="tooltipContent"
                  content={
                    <span data-testid="currency-conversion-warning-tooltip">
                      {formatMessage('SET_CONVERSION_RATE')}
                    </span>
                  }
                  placement={'top'}
                  target={
                    <WarningAltFilled
                      size={24}
                      className={b('currencyConversionWarningIndicator')}
                      tabIndex="0"
                      data-testid="currency-conversion-warning-indicator"
                    />
                  }
                />
              ) : null}
              <ChevronRight />
            </div>
          </div>
        }
      />
      <MenuItem
        data-testid={`cc-menu-item-quota-components`}
        className={b('menuItem', { active: activeMenu === CommandCenterMenuItem.QUOTA_COMPONENTS })}
        icon={<Diagram size={24} />}
        key={CommandCenterMenuItem.QUOTA_COMPONENTS}
        target={CommandCenterMenuItem.QUOTA_COMPONENTS}
        onClick={() =>
          changeActiveMenuItem({
            key: CommandCenterMenuItem.QUOTA_COMPONENTS,
            hierarchy: null
          })
        }
        text={
          <div className={b('menuItemTextContainer')}>
            <div>
              <div className={b('menuItemText')} data-testid={'menu-item-text-quota-components'}>
                <HTMLHeading tagLevel="h6" text={formatMessage('QUOTA_COMPONENTS')} />
              </div>
              <div className={b('menuItemSubtext')} data-testid={'menu-item-subtext-quota-components'}>
                {getQuotaComponentLabel()}
              </div>
            </div>
            <div>
              <ChevronRight />
            </div>
          </div>
        }
      />
      {/* The Measures tab is hidden */}
      <MenuItem
        data-testid={`cc-menu-item-measures`}
        className={b('menuItem', { active: activeMenu === CommandCenterMenuItem.MEASURES, hide: true })}
        icon={<RulerAlt size={24} />}
        key={CommandCenterMenuItem.MEASURES}
        target={CommandCenterMenuItem.MEASURES}
        onClick={() => handleHierarchyItemClicked()}
        text={
          <div className={b('menuItemTextContainer')}>
            <div>
              <div className={b('menuItemText')} data-testid={'menu-item-text-measures'}>
                <HTMLHeading tagLevel="h6" text={formatMessage('MEASURES')} />
              </div>
              <div className={b('menuItemSubtext')} data-testid={'menu-item-subtext-measures'}>
                {formatMessage('AVAILABLE_WITH_COUNT', { count: measuresData?.length ?? 0 })}
              </div>
            </div>
            <div>
              <ChevronRight />
            </div>
          </div>
        }
      />
    </>
  );
};
