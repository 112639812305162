import React, { FC } from 'react';

import Dialog from 'components/Dialog/Dialog';

import { useDeleteSegment } from 'app/graphql/mutations/deleteSegment';
import { GET_SEGMENTS_NAME } from 'app/graphql/queries/getSegments';

import { formatMessage } from 'utils/messages/utils';

export interface DeleteSegmentDialogProps {
  segmentId: number;
  onClose: () => void;
}

const DeleteSegmentDialog: FC<DeleteSegmentDialogProps> = ({ segmentId, onClose }) => {
  const [deleteSegment, { loading: isDeletingSegment }] = useDeleteSegment({
    variables: { segmentId },
    onCompleted() {
      onClose();
    },
    refetchQueries: [GET_SEGMENTS_NAME],
    awaitRefetchQueries: true
  });

  return (
    <Dialog
      isOpen
      title={formatMessage('DELETE_SEGMENT')}
      confirmButtonLoading={isDeletingSegment}
      size="small"
      bodyMinHeight={0}
      confirmButtonText={formatMessage('DELETE')}
      onSubmit={() => deleteSegment()}
      onClose={onClose}
    >
      {formatMessage('DELETE_SEGMENT_DIALOG_BODY')}
    </Dialog>
  );
};

export default DeleteSegmentDialog;
