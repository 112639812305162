import React, { CSSProperties, FC, useMemo } from 'react';

import { Checkbox, Divider } from '@varicent/components';
import { FixedSizeList } from 'react-window';

import EllipsisText from 'components/EllipsisText/EllipsisText';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { MapSelectionTarget } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatCurrency, formatMessage } from 'utils/messages/utils';

import { useOnZoomBreakpointChange } from './hooks/useOnZoomBreakpointChange';
import { useVisuallySelectedAccounts } from './hooks/useVisuallySelectedAccounts';
import style from './IndivisibleClusterList.module.pcss';
import { clusterBreakpointZoomLevels, MapStyleTokens } from './MapStyleTokens';

const b = block(style);

const LIST_ITEM_HEIGHT = 76;
const LIST_WIDTH = 240;
const LIST_MAX_HEIGHT = Math.floor(LIST_ITEM_HEIGHT * 3.5);
const DIVIDER_HEIGHT = 1;

export type AccountForClusterList = {
  accountName: string;
  accountId: number;
  measureValue: number | null;
  territoryName: string | null;
  territoryId: string | null;
  ruleColor: string | null;
  ruleId: number | null;
};

export interface IndivisibleClusterListProps {
  accounts: AccountForClusterList[];
  selectionTarget: MapSelectionTarget;
  onClickAccount: (accountId: number) => void;
  onClose: () => void;
}

const IndivisibleClusterList: FC<IndivisibleClusterListProps> = ({
  accounts,
  selectionTarget,
  onClickAccount,
  onClose
}) => {
  useOnZoomBreakpointChange(clusterBreakpointZoomLevels, onClose);
  const visuallySelectedAccounts = useVisuallySelectedAccounts();
  const sortedAccounts = useSortedAccounts(accounts, selectionTarget);
  const totalHeight = accounts.length * LIST_ITEM_HEIGHT;
  const listHeight = Math.min(totalHeight, LIST_MAX_HEIGHT);
  return (
    <FixedSizeList
      width={LIST_WIDTH}
      itemSize={LIST_ITEM_HEIGHT}
      height={listHeight}
      itemData={{ items: sortedAccounts, visuallySelectedAccounts, selectionTarget, onClickAccount }}
      itemCount={accounts.length}
    >
      {ItemRenderer}
    </FixedSizeList>
  );
};

export default IndivisibleClusterList;

const ItemRenderer = ({
  index,
  style,
  data
}: {
  index: number;
  style: React.CSSProperties;
  data: {
    items: AccountForClusterList[];
    selectionTarget: MapSelectionTarget;
    visuallySelectedAccounts: Set<number>;
    onClickAccount: (accountId: number) => void;
  };
}) => {
  const accountPin = data.items[index];
  const { selectedBattleCard } = useMapContextRedistributor();

  const { accountName, measureValue, territoryName, territoryId, ruleColor, ruleId, accountId } = accountPin;
  const isSelected = data.visuallySelectedAccounts.has(accountId);
  const isUnassigned = !ruleId;

  return (
    <div style={style} key={accountId} data-testid={`indivisible-cluster-item-${index}`}>
      <Checkbox
        className={b()}
        style={checkboxStyle}
        checked={isSelected}
        disabled={data.selectionTarget === MapSelectionTarget.territories && isUnassigned}
        onChange={() => data.onClickAccount(accountId)}
        data-testid={`indivisible-cluster-checkbox-${accountId}`}
      >
        <div className={b('textWrapper')}>
          <EllipsisText text={accountName} />
          <EllipsisText
            text={`${formatMessage('PRIOR_YEAR_ACTUAL')} ${formatCurrency(measureValue, selectedBattleCard.localCurrencyCode)}`}
          />
          <div className={b('territoryNameWrapper')}>
            <span
              className={b('coloredSquare')}
              style={{ '--territory-color': ruleColor ?? MapStyleTokens.colors.unassignedPin } as CSSProperties}
            />
            {isUnassigned ? (
              formatMessage('UNASSIGNED')
            ) : (
              <EllipsisText text={formatMessage('TERRITORY_NAME_AND_ID', { territoryName, territoryId })} />
            )}
          </div>
        </div>
      </Checkbox>
      <Divider />
    </div>
  );
};

const checkboxStyle = { height: LIST_ITEM_HEIGHT - DIVIDER_HEIGHT };

const useSortedAccounts = (accounts: AccountForClusterList[], selectionTarget: MapSelectionTarget) => {
  const shouldPlaceAssignedAccountsFirst = selectionTarget === MapSelectionTarget.territories;
  return useMemo(
    () => [...accounts].sort(createComparatorForIndivisibleClusterList(shouldPlaceAssignedAccountsFirst)),
    [accounts, shouldPlaceAssignedAccountsFirst]
  );
};

export const createComparatorForIndivisibleClusterList = (shouldPlaceAssignedAccountsFirst: boolean) => {
  return (a: AccountForClusterList, b: AccountForClusterList) => {
    if (shouldPlaceAssignedAccountsFirst) {
      if (a.ruleId && !b.ruleId) return -1;
      if (!a.ruleId && b.ruleId) return 1;
    }
    return a.accountName.localeCompare(b.accountName) || a.accountId - b.accountId;
  };
};
