import { useEffect, useRef } from 'react';

import debounce from 'lodash.debounce';
import { useMap } from 'react-map-gl';

import { getClosestZoomBreakpoint } from 'utils/helpers/territoryMapUtils';

export const useOnZoomBreakpointChange = (zoomBreakpoints: number[], onChange: () => void, debounceDuration = 100) => {
  const mapboxMapRef = useMap();
  const lastBreakpointRef = useRef(getClosestZoomBreakpoint(zoomBreakpoints, mapboxMapRef.current.getZoom()));

  useEffect(() => {
    const handleZoom = () => {
      const breakpoint = getClosestZoomBreakpoint(zoomBreakpoints, mapboxMapRef.current.getZoom());
      if (lastBreakpointRef.current === breakpoint) return;
      lastBreakpointRef.current = breakpoint;
      onChange();
    };

    const debouncedZoomHandler = debounce(handleZoom, debounceDuration);
    mapboxMapRef.current.on('zoom', debouncedZoomHandler);
    return () => {
      mapboxMapRef.current.off('zoom', debouncedZoomHandler);
    };
  }, [onChange, debounceDuration, zoomBreakpoints]);
};
