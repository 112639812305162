import { PeriodicGroupMeasure } from 'app/models';

export const formatTotalsFooter = (totals: Array<PeriodicGroupMeasure>): Array<{ [key: string]: number }> => {
  const formattedTotals = {};

  totals?.forEach((total: PeriodicGroupMeasure) => {
    const measureName = total.measureName;
    Object.assign(formattedTotals, { [measureName]: total.measureValue?.value });
    if (total?.measureValue?.years) {
      total.measureValue.years.forEach((year) => {
        year?.halves?.forEach((half) => {
          half?.quarters?.forEach((quarter) => {
            quarter?.months?.forEach((month) => {
              const monthHeader = `${measureName}_${month.year}_CY_${month.month}`;
              Object.assign(formattedTotals, { [monthHeader]: month.value });
            });
            const quarterHeader = `${measureName}_${year.year}_FY_${quarter.quarter}`;
            Object.assign(formattedTotals, { [quarterHeader]: quarter.value });
          });
          const halfHeader = `${measureName}_${year.year}_FY_${half.half}`;
          Object.assign(formattedTotals, { [halfHeader]: half.value });
        });
        const yearHeader = `${measureName}_${year.year}`;
        Object.assign(formattedTotals, { [yearHeader]: year.value });
      });
    }
  });

  return [formattedTotals];
};
