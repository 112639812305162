import React, { useEffect, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@apollo/client';
import { Intent, Spinner, SpinnerSize } from '@blueprintjs/core';
import { ArrowLeft, Menu } from '@carbon/icons-react';
import { DividerV2 } from '@varicent/components';
import { useHistory, useParams } from 'react-router-dom';

import IconButton from 'components/Buttons/IconButton/IconButton';
import DeploymentModelToaster from 'components/DeploymentModelToaster/DeploymentModelToaster';
import EllipsisText from 'components/EllipsisText/EllipsisText';
//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import Tab from 'components/Tab/Tab';
import Tabs from 'components/Tabs/Tabs';
import ToastMessage from 'components/ToastMessage/ToastMessage';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';

import AssistantMenu from 'app/components/Assistant/AssistantMenu';
import HelpButton from 'app/components/HelpButton/HelpButton';
import PageLoadingBar from 'app/components/PageLoadingBar/PageLoadingBar';
import ScenarioMenu from 'app/components/PlanningCyclePageHeader/ScenarioMenu';
import StatusMenu from 'app/components/StatusMenu/StatusMenu';
import TenantMenu from 'app/components/TenantMenu/TenantMenu';
import UserMenu from 'app/components/UserMenu/UserMenu';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCommandCenter } from 'app/contexts/commandCenterProvider';
import { useDataTray } from 'app/contexts/dataTrayProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';
import { useTerritoryOptimization } from 'app/contexts/territoryOptimizationProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { SplitFeatures } from 'app/global/features';

import {
  DeploymentModelTypeEnum,
  RequestDeploymentModelClone,
  RequestDeploymentModelCloneVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { REQUEST_DEPLOYMENT_MODEL_CLONE } from 'app/graphql/mutations/requestDeploymentModelClone';

import useCanUser from 'app/hooks/useCanUser';
import useMakePlanningPath from 'app/hooks/useMakePlanningPath';
import usePhase from 'app/hooks/usePhase';
import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import {
  CommandCenterDrawerState,
  DeploymentModelPhase,
  DeploymentModelSpec,
  PlanningPath,
  SectionName,
  SelectedPlanningType,
  UserRoleType
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

import ActivatePlanningCycleDialog from './ActivatePlanningCycleDialog/ActivatePlanningCycleDialog';
import { CompareModeMenu } from './CompareModeMenu';
import style from './PlanningCyclePageHeader.module.pcss';

const b = block(style);

const ToggleSpinner = () => <Spinner intent={Intent.PRIMARY} size={SpinnerSize.SMALL} />;

type DeploymentModelSlugFromParams = {
  deploymentModelSlug: string | undefined;
};

export interface PlanningCyclePageHeaderProps {
  planningCycleName: string;
  manageDeploymentModelId?: number;
  planDeploymentModelId?: number;
  publishedDeploymentModel: Pick<DeploymentModelSpec, 'deploymentModelId' | 'deploymentModelName'>;
  isQuotaWorkflowStarted?: boolean;
}

const PlanningCyclePageHeader: React.FC<PlanningCyclePageHeaderProps> = ({
  planningCycleName,
  isQuotaWorkflowStarted,
  manageDeploymentModelId,
  planDeploymentModelId,
  publishedDeploymentModel
}: PlanningCyclePageHeaderProps) => {
  const history = useHistory();
  const { userRole, userMembershipSpecs } = useUser();
  const {
    selectedTenant,
    selectedPlanningType,
    selectedPlanningCycle,
    setSelectedPlanningCycle,
    setSelectedDeploymentModelId
  } = useScope();
  const {
    battleCardLookupMap,
    expandedBattleCardId,
    setExpandedBattleCardId,
    setSelectedBattleCardId,
    setExitingBattleCardId,
    setSectionName,
    setCardIsExpanded,
    setSelectedQuotaComponentId,
    isBattleCardDataLoading,
    setResetExpandedView
  } = useBattleCard();
  const { isMapLoading, isDataTrayMapOpen, isMultiMapOpen, setIsMultiMapOpen } = useMapVariant();
  const { setIsLocalCurrencyMode } = useLocalization();
  const { setTrayOpen } = useDataTray();
  const { commandCenterDrawerState, setCommandCenterDrawerState, commandCenterBackButtonCallback, setActiveMenu } =
    useCommandCenter();
  const { setTerritoryOptimizationDrawerState } = useTerritoryOptimization();
  const { setSelectedPillIdTDR } = useTerritoryDefineAndRefine();
  const { setSelectedPillIdPlanTargets } = usePlanTargets();
  const deploymentModelPhase = usePhase();
  const { deploymentModelSlug } = useParams<DeploymentModelSlugFromParams>();

  const canViewPlan = useCanUser(UserAction.PLAN_VIEW);
  const canViewManage = useCanUser(UserAction.MANAGE_VIEW);

  const [isCopilotOn] = useTreatment(SplitFeatures.COPILOT);
  const [showCoinsortStatusMenu] = useTreatment(SplitFeatures.MATCHING_ENGINE);

  const showToast = useShowToast();

  const [showActivationDialog, setShowActivationDialog] = useState<boolean>(false);
  const isPlanningCycleActivated = !!manageDeploymentModelId;
  const [isActivationLoading, setIsActivationLoading] = useState<boolean>(false);
  const [isActivationFinished, setIsActivationFinished] = useState(false);

  const isLoadingBarLoading = (isMapLoading && isDataTrayMapOpen) || isBattleCardDataLoading;

  const [requestDeploymentModelClone] = useMutation<RequestDeploymentModelClone, RequestDeploymentModelCloneVariables>(
    REQUEST_DEPLOYMENT_MODEL_CLONE,
    {
      onCompleted() {
        setIsActivationLoading(true);
        showToast(
          <ToastMessage
            title={formatMessage('ACTIVATION_STARTED')}
            message={formatMessage('ACTIVATION_STARTED_MESSAGE', { name: planningCycleName })}
          />,
          'primary'
        );
      },
      onError({ graphQLErrors, networkError }) {
        showToast(
          <ToastMessage
            title={formatMessage('ACTIVATION_FAILED')}
            message={formatMessage('ACTIVATION_FAILED_MESSAGE', { name: planningCycleName })}
          />,
          'danger'
        );
        handleError(graphQLErrors, networkError);
      }
    }
  );

  // if there is a battle card expanded, only show
  // those that are attached to current battle card as QC options

  const showPlanningCyclesPage = () => {
    //reset state when navigating back to the home page

    // battle card and territory group state
    setExpandedBattleCardId(null);
    setSelectedBattleCardId(null);
    setExitingBattleCardId(null);
    setSectionName(null);
    setCardIsExpanded(null);
    setTrayOpen(false);
    setSelectedQuotaComponentId(null);
    setSelectedPlanningCycle(null);
    setSelectedPillIdTDR(null);
    setSelectedPillIdPlanTargets(null);

    // currency mode
    setIsLocalCurrencyMode(false);

    // territory optimization drawer
    setTerritoryOptimizationDrawerState(null);

    history.push(`/${selectedTenant?.slug}`);
  };

  const isCommandCenterOpen = commandCenterDrawerState !== CommandCenterDrawerState.CLOSE;
  // eslint-disable-next-line no-restricted-syntax
  const isContributor = userRole === UserRoleType.CONTRIBUTOR;

  const makePlanningPath = useMakePlanningPath();

  let homeBackButton = null;
  let callback = null;
  if (isMultiMapOpen) {
    callback = () => {
      // reset data tray map and compare map
      setIsMultiMapOpen(false);

      // clean up the path to show the default battle card canvas view
      history.push(
        makePlanningPath({ deploymentModelSlug: DeploymentModelTypeEnum.Plan, viewParam: PlanningPath.territory })
      );
    };
  } else if (expandedBattleCardId) {
    callback = () => setExpandedBattleCardId(null);
  } else if (commandCenterDrawerState === CommandCenterDrawerState.EXPAND) {
    callback = commandCenterBackButtonCallback;
  }

  homeBackButton = (
    <IconButton
      type="button"
      icon={<ArrowLeft size={24} />}
      onClick={callback ?? showPlanningCyclesPage}
      className={b('homeBtn')}
      testId={'home-btn'}
      title={formatMessage('BACK')}
    />
  );

  const handleTabClick = (selectedSection: SectionName) => {
    if (expandedBattleCardId) {
      if (!battleCardLookupMap[expandedBattleCardId]?.battlecardParentId) {
        // reset expanded battlecard context for root battlecard
        setResetExpandedView(true);
      }
    }
    setSectionName(selectedSection);
  };

  const handleShowActivationDialog = () => setShowActivationDialog(true);

  const switchToDeploymentTypeManage = () => {
    handleTabClick(SectionName.PLAN_TARGETS);
    history.push(
      makePlanningPath({
        deploymentModelSlug: DeploymentModelPhase.manage
      })
    );
    // Needed to ensure the phase is always in sync with the selected DM
    setSelectedDeploymentModelId(manageDeploymentModelId);
  };

  useEffect(() => {
    // If the deploymentModelSlug is in manage phase and user can only view plan, switch to plan
    if (deploymentModelSlug === DeploymentModelPhase.manage && !canViewManage && canViewPlan) {
      switchToDeploymentTypePlan();
      return;
    }
    // If the planning cycle is not activated or deploymentModelSlug is in manage phase, exit early
    if (!isPlanningCycleActivated || deploymentModelSlug === DeploymentModelPhase.manage) return;

    // Switch to manage if no slug is defined or user can only view manage
    if (!deploymentModelSlug && canViewManage && !canViewPlan) {
      switchToDeploymentTypeManage();
    }
  }, [deploymentModelSlug, isPlanningCycleActivated, canViewManage, canViewPlan]);

  useEffect(() => {
    // If the deploymentModelSlug is in plan phase and user can only view manage, switch to manage
    if (deploymentModelSlug === DeploymentModelPhase.plan && !canViewPlan && canViewManage) {
      switchToDeploymentTypeManage();
      return;
    }
    // If the deploymentModelSlug is already in plan phase, exit early
    if (deploymentModelSlug === DeploymentModelPhase.plan) return;

    // Switch to plan if no slug is defined or user can only view plan
    if (!deploymentModelSlug && !canViewManage && canViewPlan) {
      switchToDeploymentTypePlan();
    }
  }, [deploymentModelSlug, canViewManage, canViewPlan]);

  // NOTE: The following is a workaround - In the ticket TQP-14991 we will refactor the logic to ensure that this is done correctly
  // however for now this is the best solution
  useEffect(() => {
    if (isActivationFinished && canViewManage) {
      switchToDeploymentTypeManage();
    }
  }, [isActivationFinished]);

  useEffect(() => {
    if (!userMembershipSpecs || !selectedTenant || !selectedPlanningCycle) {
      return;
    }

    const planningCycles = userMembershipSpecs.find((ms) => ms?.tenantId === selectedTenant.id)?.planningCycles;
    const deploymentModels = planningCycles?.find(
      (pc) => pc?.planningCycleId === selectedPlanningCycle.id
    ).deploymentModels;

    if (!deploymentModels) {
      return;
    }
  }, [userMembershipSpecs, selectedTenant, selectedPlanningCycle, deploymentModelPhase]);

  const switchToDeploymentTypePlan = () => {
    handleTabClick(SectionName.TERRITORY_DEFINE);
    history.push(
      makePlanningPath({
        deploymentModelSlug: DeploymentModelPhase.plan
      })
    );
    // Needed to ensure the phase is always in sync with the selected DM
    setSelectedDeploymentModelId(planDeploymentModelId);
  };

  const handleSubmit = async (deploymentModelId, clonedDeploymentModelType) => {
    setShowActivationDialog(false);
    try {
      await requestDeploymentModelClone({
        variables: {
          deploymentModelId,
          clonedDeploymentModelType
        }
      });
    } catch (_error) {
      setIsActivationLoading(false);
    }
  };

  const handleActivation = () => {
    setIsActivationFinished(true);
  };

  // eslint-disable-next-line no-restricted-syntax
  const isUserAdmin = userRole === UserRoleType.ADMIN;
  const renderPhaseTabs = () => (
    <div className={b('phaseTabs')}>
      {isPlanningCycleActivated || isActivationLoading ? (
        <ToggleSwitch
          leftToggleLabel={formatMessage('PLAN')}
          rightToggleLabel={formatMessage('MANAGE')}
          rightToggleIcon={isActivationLoading && ToggleSpinner}
          onLeftToggle={switchToDeploymentTypePlan}
          onRightToggle={switchToDeploymentTypeManage}
          currentSelection={deploymentModelPhase === DeploymentModelPhase.manage}
          isLeftToggleDisabled={!canViewPlan}
          isRightToggleDisabled={!canViewManage || isActivationLoading}
        />
      ) : (
        <>
          {showActivationDialog && (
            <ActivatePlanningCycleDialog
              onSubmit={() => handleSubmit(publishedDeploymentModel.deploymentModelId, DeploymentModelTypeEnum.Manage)}
              onClose={() => {
                setShowActivationDialog(false);
              }}
              isOpen={showActivationDialog}
              deploymentModelName={publishedDeploymentModel.deploymentModelName}
            />
          )}
        </>
      )}
    </div>
  );

  const toggleCommandCenterDrawer = () => {
    if (commandCenterDrawerState === CommandCenterDrawerState.CLOSE) {
      setCommandCenterDrawerState(CommandCenterDrawerState.OPEN);
    } else {
      setCommandCenterDrawerState(CommandCenterDrawerState.CLOSE);
      setActiveMenu(null);
    }
  };

  const canViewTerritoryTab = canViewPlan;
  const renderTerritoryQuotaHeaderTabs = () => {
    const handleChange = (tabId: SectionName) => {
      const viewParam = tabId === SectionName.TERRITORY_DEFINE ? PlanningPath.territory : PlanningPath.quota;
      handleTabClick(tabId);
      history.push(makePlanningPath({ viewParam }));
    };
    const selectedTabId =
      selectedPlanningType === SelectedPlanningType.QUOTA_PLANNING
        ? SectionName.PLAN_TARGETS
        : SectionName.TERRITORY_DEFINE;

    return (
      <div className={b('header', { territoryQuotaTabs: true })}>
        <Tabs id="section-name" onChange={handleChange} large selectedTabId={selectedTabId}>
          <Tab
            title={
              <MessageTooltip
                target={<>{formatMessage('TERRITORY')}</>}
                content={formatMessage('TERRITORY_TAB_NO_PERMISSIONS')}
                placement={'auto'}
                disabled={canViewTerritoryTab || !isContributor}
              />
            }
            id={SectionName.TERRITORY_DEFINE}
            disabled={isContributor && !canViewTerritoryTab}
          />
          <Tab
            title={
              <MessageTooltip
                target={<>{formatMessage('QUOTA')}</>}
                content={formatMessage('QUOTA_WORKFLOW_NOT_STARTED')}
                placement={'auto'}
                disabled={isQuotaWorkflowStarted || !isContributor}
              />
            }
            id={SectionName.PLAN_TARGETS}
            disabled={isContributor && !isQuotaWorkflowStarted}
          />
        </Tabs>
      </div>
    );
  };

  return (
    <>
      <div className={b()}>
        <header className={b('headerWrapper')}>
          <div className={b('header')}>
            <div className={b('logoArea')}>
              {homeBackButton}

              {selectedPlanningCycle && (
                <>
                  <div className={b('planningCycleName')} data-testid="planning-cycle-name">
                    <EllipsisText text={planningCycleName} className={b('planningCycleNameText')} />
                    {!isMultiMapOpen && renderPhaseTabs()}
                  </div>
                </>
              )}
              {deploymentModelPhase === DeploymentModelPhase.plan && (
                <>
                  <DividerV2 vertical className={b('divider')} />
                  {isMultiMapOpen ? (
                    <CompareModeMenu />
                  ) : (
                    <ScenarioMenu
                      data-testid="scenario-menu"
                      onClickActivate={handleShowActivationDialog}
                      isPlanningCycleActivated={isPlanningCycleActivated}
                    />
                  )}
                </>
              )}
            </div>
            <div className={b('actionArea')}>
              {showCoinsortStatusMenu && !isContributor && <StatusMenu data-testid="status-menu" />}
              <div className={b('tenantMenu')}>
                <TenantMenu tenants={userMembershipSpecs} data-testid="tenant-menu" />
              </div>
              <div className={b('userMenu')}>
                <UserMenu data-testid="user-menu" />
              </div>
              <HelpButton data-testid="help-button" />
              {isUserAdmin && isCopilotOn && !isMultiMapOpen && <AssistantMenu data-testid="assistant-menu" />}
              <div className={b('commandCenterBtnContainer')}>
                {selectedPlanningCycle && !isMultiMapOpen && (
                  <CanUser
                    perform={UserAction.COMMAND_CENTER_VIEW}
                    yes={
                      <IconButton
                        type="button"
                        icon={<Menu size={24} />}
                        onClick={toggleCommandCenterDrawer}
                        className={b('commandCenterBtn')}
                        testId={'command-center-btn'}
                        title={formatMessage('MENU')}
                      />
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </header>
        <div className={b('loadingBarContainer')} data-testid="loading-bar-container">
          {!isCommandCenterOpen && <PageLoadingBar isLoading={isLoadingBarLoading} />}
        </div>
        {deploymentModelPhase === DeploymentModelPhase.plan && !isMultiMapOpen && renderTerritoryQuotaHeaderTabs()}
      </div>
      {isActivationLoading && (
        <DeploymentModelToaster
          planningCycleName={planningCycleName}
          deploymentModelName={publishedDeploymentModel.deploymentModelName}
          onActivate={handleActivation}
        />
      )}
    </>
  );
};

export default PlanningCyclePageHeader;
