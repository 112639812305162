// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+R00O {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  padding: 20px;\n  font-size: 14px;\n}\n\n  .yjPj4 {\n    margin-bottom: var(--m);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EmptyFilterMessage": "+R00O",
	"EmptyFilterMessage_illustration": "yjPj4"
};
export default ___CSS_LOADER_EXPORT___;
