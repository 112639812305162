import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { CalloutV2, Intent } from '@varicent/components';

import { BasicSelect, BasicMultiSelect } from 'components/BasicSelect/BasicSelects';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import { CollectionFilter, CollectionFilterKind, MchQuantity, NamedHierarchy } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CustomHierarchyFilterMenuV2.module.pcss';

const b = block(style);

export const CustomHierarchyFilterMenuV2: FC<{
  hierarchies: NamedHierarchy[];
  filter: CollectionFilter<number>;
  onUpdateFilter: (filter: CollectionFilter<number>) => void;
}> = ({ hierarchies: rawHierarchies, filter: remoteFilter, onUpdateFilter }) => {
  const [currentFilter, setCurrentFilter] = useState<CollectionFilter<number>>(remoteFilter);
  const hierarchyItems = useMemo(
    () => rawHierarchies.map((hierarchy) => ({ text: hierarchy.name, value: hierarchy.hierarchyId })),
    [rawHierarchies]
  );

  useEffect(() => {
    setCurrentFilter(remoteFilter);
  }, [remoteFilter]);

  const { chosenCustomHierarchy } = useDedicatedMapProvider();
  const titleMessage =
    chosenCustomHierarchy.quantity === MchQuantity.SINGULAR
      ? formatMessage('CUSTOM_HIERARCHY_FILTER_DYNAMIC_TITLE', {
          hierarchyName: chosenCustomHierarchy.details.rootName
        })
      : formatMessage('CUSTOM_HIERARCHY_FILTER_TITLE');

  const containerRef = useRef();
  return (
    <section data-testid="custom-hierarchy-filter-menu" className={b('filter')} ref={containerRef}>
      <div>{titleMessage}</div>
      <form className={b('form')} onSubmit={(e) => e.preventDefault()} data-testid="custom-hierarchy-filter-form">
        <BasicSelect<CollectionFilterKind>
          testId="custom-filter-kind"
          items={filterKindItems}
          value={currentFilter.kind}
          minimal
          placement="bottom-start"
          containerElement={containerRef.current}
          label={formatMessage('CUSTOM_HIERARCHY_FILTER_KIND_LABEL')}
          onSelectedValueChange={(kind) => {
            setCurrentFilter({ ids: remoteFilter.ids, kind });
            onUpdateFilter({ kind, ids: remoteFilter.ids });
          }}
        />
        <hr className={b('fieldSeparator')} />
        <BasicMultiSelect<number>
          testId="custom-filter-ids"
          items={hierarchyItems}
          onValuesChange={(ids) => {
            setCurrentFilter({ ids, kind: remoteFilter.kind });
            onUpdateFilter({ ids, kind: remoteFilter.kind });
          }}
          values={currentFilter.ids}
          minimal
          placement="bottom-start"
          className={b('hierarchyMultiSelect')}
          label={formatMessage('CUSTOM_HIERARCHY_FILTER_IDS_LABEL')}
          matchTargetWidth
          containerElement={containerRef.current}
          placeholder={formatMessage(
            hierarchyItems.length === 0 ? 'CUSTOM_HIERARCHY_FILTER_IDS_EMPTY_PLACEHOLDER' : 'SELECT_A_HIERARCHY'
          )}
        />
      </form>
      {remoteFilter.kind !== CollectionFilterKind.EQUALS && (
        <div className={b('filterCallout')}>
          <CalloutV2 intent={Intent.PRIMARY} hideActionText={true} data-testid="filter-callout">
            {formatMessage('MAP_CUSTOM_HIERARCHY_FILTER_CALLOUT_MESSAGE')}
          </CalloutV2>
        </div>
      )}
    </section>
  );
};

const filterKindItems = [
  { value: CollectionFilterKind.EQUALS, text: formatMessage('COLLECTION_FILTER_EQUALS_TO') },
  { value: CollectionFilterKind.NOT_EQUALS, text: formatMessage('COLLECTION_FILTER_NOT_EQUALS_TO') },
  { value: CollectionFilterKind.CONTAINS_ANY, text: formatMessage('COLLECTION_FILTER_CONTAINS_ANY') },
  { value: CollectionFilterKind.NOT_CONTAINS_ANY, text: formatMessage('COLLECTION_FILTER_NOT_CONTAINS_ANY') }
];
