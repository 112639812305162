import React from 'react';

import { Information } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import block from 'utils/bem-css-modules';

import style from './LookupInfoHeaderRenderer.module.pcss';

const b = block(style);

interface LookupInfoHeaderRendererProps {
  displayName: string;
  hover: string;
  tooltipMessage?: string;
  onButtonClicked: () => void;
}

const LookupInfoHeaderRenderer: React.FC<LookupInfoHeaderRendererProps> = ({
  displayName,
  hover,
  tooltipMessage,
  onButtonClicked
}: LookupInfoHeaderRendererProps) => {
  const Header = (
    <div>
      <span data-testid="lookup-info-header-renderer-column-header">{displayName}</span>
      <span className={b('infoIcon')}>
        <IconButton
          type={'button'}
          icon={<Information size={20} />}
          title={hover}
          testId={'info-icon'}
          onClick={onButtonClicked}
        />
      </span>
    </div>
  );

  const TooltipHeader = <MessageTooltip content={tooltipMessage} placement={'bottom'} target={Header} />;

  return <div className={b('label')}>{tooltipMessage ? TooltipHeader : Header}</div>;
};

export default LookupInfoHeaderRenderer;
