import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { DeleteSegment, DeleteSegmentVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const DELETE_SEGMENT = gql`
  mutation DeleteSegment($segmentId: Int!) {
    deleteSegment(input: { segmentId: $segmentId }) {
      segmentId
    }
  }
`;

export const useDeleteSegment = createUseMutation<DeleteSegment, DeleteSegmentVariables>(DELETE_SEGMENT);
