import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { AnchorButton, Button, Intent, Spinner } from '@blueprintjs/core';
import { Placement } from '@blueprintjs/popover2';

//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import block from 'utils/bem-css-modules';

import style from './TextButton.module.pcss';

const b = block(style);

interface TextButtonProps {
  text: string | JSX.Element;
  disabled?: boolean;
  type: 'submit' | 'reset' | 'button';
  intent?: Intent;
  icon?: JSX.Element | null;
  rightIcon?: JSX.Element | null;
  minimal?: boolean;
  large?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  isActiveWhileLoading?: boolean;
  onClick?: (e) => void;
  refProps?: React.MutableRefObject<HTMLButtonElement>;
  outlined?: boolean;
  active?: boolean;
  className?: string;
  testId: string;
  tooltipText?: string;
  tooltipPlacement?: Placement;
}

const TextButton: React.FC<TextButtonProps> = ({
  text,
  disabled,
  type,
  intent,
  icon,
  minimal,
  fullWidth,
  loading,
  isActiveWhileLoading,
  onClick,
  refProps,
  large = true,
  outlined = false,
  rightIcon = null,
  active = false,
  className,
  testId,
  tooltipText,
  tooltipPlacement = 'top'
}: TextButtonProps) => {
  const iconLoading =
    loading && isActiveWhileLoading ? <Spinner intent="primary" size={20} data-testid="loading-spinner" /> : null;

  const CustomButton = tooltipText ? (
    <AnchorButton
      text={text}
      intent={intent}
      disabled={disabled}
      minimal={minimal}
      fill={fullWidth}
      loading={loading && !isActiveWhileLoading}
      onClick={(e) => onClick && onClick(e)}
      outlined={outlined}
      rightIcon={rightIcon}
      icon={iconLoading || icon}
      large={large}
      data-testid={intent ? `${testId}-${intent}` : testId}
      active={active}
      className={`${b({
        primary: intent === 'primary',
        danger: intent === 'danger',
        disabled,
        minimal
      })} ${className || ''}`}
    />
  ) : (
    <Button
      text={text}
      intent={intent}
      disabled={disabled}
      minimal={minimal}
      type={type}
      fill={fullWidth}
      loading={loading && !isActiveWhileLoading}
      onClick={(e) => onClick && onClick(e)}
      elementRef={refProps}
      outlined={outlined}
      rightIcon={rightIcon}
      icon={iconLoading || icon}
      large={large}
      data-testid={intent ? `${testId}-${intent}` : testId}
      active={active}
      className={`${b({
        primary: intent === 'primary',
        danger: intent === 'danger',
        disabled,
        minimal
      })} ${className || ''}`}
    />
  );

  return (
    <span>
      {tooltipText ? (
        <MessageTooltip target={CustomButton} content={tooltipText} placement={tooltipPlacement} />
      ) : (
        CustomButton
      )}
    </span>
  );
};

export default TextButton;
