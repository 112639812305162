import React, { useRef, useState, KeyboardEvent } from 'react';

import { WarningFilled } from '@carbon/icons-react';
import CurrencyInput from 'react-currency-input-field';

//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { CurrencyCode, MeasureFormatType } from 'app/models';

import block from 'utils/bem-css-modules';
import { isDecimalSupportedCurrency } from 'utils/helpers/currencyHelpers';
import { numberUnformatter } from 'utils/helpers/formNumberFormattingUtils';
import { getLocale } from 'utils/helpers/getLocale';

import style from './EditableCurrencyCellRenderer.module.pcss';

const b = block(style);

interface EditableCurrencyCellRendererProps {
  value: string;
  currency: CurrencyCode;
  type?: MeasureFormatType;
  allowNegativeValue?: boolean;
  errorMessage?: string;
  hasErrors?: boolean;
  inputColor?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
}

export const EditableCurrencyCellRenderer: React.FC<EditableCurrencyCellRendererProps> = ({
  value,
  currency,
  errorMessage = '',
  onChange = null,
  onBlur = null,
  hasErrors = false,
  disabled = false,
  inputColor = null,
  allowNegativeValue = false,
  type = MeasureFormatType.CURRENCY,
  onKeyDown
}: EditableCurrencyCellRendererProps) => {
  const [currentValue, setCurrentValue] = useState<string>(!value ? '0' : value.toString());
  const ref = useRef<HTMLInputElement>();
  const decimalScale = isDecimalSupportedCurrency(currency) ? 2 : undefined;

  const handleOnValueChange = (newValue: string | undefined): void => {
    setCurrentValue(newValue);

    if (onChange) {
      onChange(numberUnformatter(newValue) ? numberUnformatter(newValue).toString() : '');
    }
  };

  const handleUpdate = () => {
    if (!currentValue) {
      // setting the value in string to enforce the decimal value
      setCurrentValue('0.00');
    }

    if (onBlur) {
      const finalValue = currentValue ? numberUnformatter(currentValue.toString()) : 0;

      onBlur(finalValue ? finalValue.toString() : '');
    }
  };

  const handleOnBlur = (): void => {
    handleUpdate();
  };

  const handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      ref?.current?.blur();
    }
  };

  return (
    <div>
      {hasErrors && (
        <MessageTooltip
          data-testid="error-tooltip"
          content={errorMessage}
          target={
            <span className={b('errorIcon')}>
              <WarningFilled data-testid="error-icon" />
            </span>
          }
          placement={'left'}
        />
      )}
      <CurrencyInput
        ref={ref}
        allowNegativeValue={allowNegativeValue}
        data-testid="editable-currency"
        className={b('editableInput', { error: hasErrors, disabled })}
        decimalScale={decimalScale}
        value={currentValue}
        disabled={disabled}
        style={{ backgroundColor: inputColor }}
        onKeyUp={(e) => handleOnKeyUp(e)}
        onValueChange={(newValue) => {
          handleOnValueChange(newValue);
        }}
        onBlur={() => {
          handleOnBlur();
        }}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          e.stopPropagation();
          if (onKeyDown) {
            onKeyDown(e);
          }
        }} // to prevent jumping to the other cells and stay focused on the input
        intlConfig={{ locale: getLocale(), currency }}
        suffix={type === MeasureFormatType.PERCENTAGE ? MeasureFormatType.PERCENTAGE : null}
      />
    </div>
  );
};

export default EditableCurrencyCellRenderer;
