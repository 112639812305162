import React, { FC } from 'react';

import { Locked, Unlocked, CircleSolid } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { GridFields, RuleForMap } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './MapLockCellRenderer.module.pcss';

const b = block(style);

type TerritoryMapGridRow = {
  [GridFields.RULE]: RuleForMap;
  [GridFields.TERRITORY_ID_AND_NAME]: string;
  [GridFields.TERRITORY_COLOR]: {
    color: string;
    rule: RuleForMap;
    isMappable: boolean;
    isOverAssigned: boolean;
  };
  [GridFields.RULE_ID]: number;
  [GridFields.TERRITORY_ID]: string;
  [GridFields.TERRITORY_NAME]: string;
  [GridFields.IS_LOCKED]: boolean;
  [GridFields.IS_IGNORED]: boolean;
};

type MapLockCellRendererProps = {
  value: boolean;
  data?: Pick<TerritoryMapGridRow, GridFields.IS_IGNORED>;
  node?: {
    group?: boolean;
    aggData?: { isIgnored: boolean };
    parent?: {
      aggData?: { isLocked: boolean };
    };
  };
};

export const MapLockCellRenderer: FC<MapLockCellRendererProps> = ({ value: isLocked, data, node }) => {
  const isIgnored = data?.isIgnored ?? node?.aggData?.isIgnored;
  const isParentLocked = !!node?.parent?.aggData?.isLocked;
  let icon: JSX.Element;
  if (isParentLocked) {
    icon = <BiIcon />;
  } else if (isLocked) {
    icon = <Locked data-testid="locked-icon" />;
  } else {
    icon = <Unlocked data-testid="unlocked-icon" />;
  }
  return (
    <IconButton
      type="button"
      small
      className={b({ isIgnored, alwaysShow: isLocked })}
      icon={icon}
      testId="map-lock-cell"
      title={getTooltipText(isLocked, node?.group)}
    />
  );
};

const BiIcon = () => (
  <>
    <CircleSolid className={b('biDotIcon')} data-testid="bi-dot-icon" />
    <Locked className={b('biLockedIcon')} data-testid="bi-locked-icon" />
  </>
);

const getTooltipText = (isLocked: boolean, isGroup: boolean) => {
  if (isLocked) {
    if (isGroup) return formatMessage('UNLOCK_TERRITORY_GROUP');
    else return formatMessage('UNLOCK_TERRITORY');
  } else {
    if (isGroup) return formatMessage('LOCK_TERRITORY_GROUP');
    else return formatMessage('LOCK_TERRITORY');
  }
};
