import React from 'react';

import { Tag } from '@blueprintjs/core';
import { WarningAltFilled, Money } from '@carbon/icons-react';

import Icon from 'components/Icon/Icon';
//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './TargetComparison.module.pcss';

const b = block(style);

interface TargetComparisonProps {
  target?: string;
  updatedQuota?: string;
  revisedTerritoryQuota?: string;
  assignedSellerQuota?: string;
  allocationMessage?: string;
}

const TargetComparison: React.FC<TargetComparisonProps> = ({
  target,
  updatedQuota,
  revisedTerritoryQuota,
  assignedSellerQuota,
  allocationMessage
}: TargetComparisonProps) => {
  return (
    <div className={b()} data-testid="target-comparison">
      <Money />
      <div className={b('values')}>
        {target && (
          <div className={b('valueContainer')} data-testid="target-comparison-target">
            <span className={b('valueLabel')}>{formatMessage('NEW_BUSINESS_TARGET')}</span>
            <span className={b('value')} data-testid="target-comparison-target-value">
              {target}
            </span>
          </div>
        )}
        {updatedQuota && (
          <div className={b('valueContainer')} data-testid="target-comparison-updated-quota">
            <span className={b('valueLabel')}>{formatMessage('UPDATED_QUOTA')}</span>
            <span className={b('value')} data-testid="target-comparison-updated-quota-value">
              {updatedQuota}
            </span>
          </div>
        )}
        {revisedTerritoryQuota && (
          <div className={b('valueContainer')} data-testid="target-comparison-revised-territory-quota">
            <span className={b('valueLabel')}>{formatMessage('REVISED_TERRITORY_QUOTA')}</span>
            <span className={b('value')} data-testid="target-comparison-revised-territory-quota-value">
              {revisedTerritoryQuota}
            </span>
          </div>
        )}
        {assignedSellerQuota && (
          <div className={b('valueContainer')} data-testid="target-comparison-assigned-seller-quota">
            <span className={b('valueLabel')}>{formatMessage('ASSIGNED_SELLER_QUOTA')}</span>
            <span className={b('value')} data-testid="target-comparison-assigned-seller-quota-value">
              {assignedSellerQuota}
            </span>
          </div>
        )}
        {allocationMessage && (
          <MessageTooltip
            className={b('allocationTooltip')}
            target={<Tag intent="warning" icon={<Icon icon={<WarningAltFilled />} />} />}
            content={allocationMessage}
            placement={'right'}
            data-testid="target-comparison-allocation-message"
          />
        )}
      </div>
    </div>
  );
};

export default TargetComparison;
