import React from 'react';

//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import block from 'utils/bem-css-modules';

import style from './MessageTooltipHeaderRenderer.module.pcss';
const b = block(style);

interface MessageTooltipHeaderRendererProps {
  displayName: string;
  tooltipMessage: string;
  alignRight?: boolean;
}

export const MessageTooltipHeaderRenderer: React.FC<MessageTooltipHeaderRendererProps> = ({
  displayName,
  tooltipMessage,
  alignRight = false
}) => {
  return (
    <div className={b()} data-testid="message-tooltip-header-renderer">
      <MessageTooltip
        className={b(alignRight ? 'alignRight' : 'alignLeft')}
        content={tooltipMessage}
        placement={'bottom'}
        target={<span data-testid="message-tooltip-header">{displayName}</span>}
      />
    </div>
  );
};

export default MessageTooltipHeaderRenderer;
