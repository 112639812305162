import { formatMessage } from 'utils/messages/utils';

const BACKEND_ERROR_STRINGS = {
  MISSING_TERRITORY_GROUP: 'No Territory Groups defined.',
  CANCEL_ERROR: "Coin sort isn't running, so there is nothing to cancel.",
  GENERIC_ERROR: "We couldn't run coin sort because we ran into some problems. Please refresh the page and try again.",
  RUN_ERROR:
    "We can't run coin sort right now because there is already a coin sort instance running. Please wait for the current instance to complete and then try again.",
  ACCESS_DENIED_ERROR:
    "Sorry, you don't have permission to run coin sort. Reach out to your Varicent Sales Planning administrator for assistance.",
  MISSING_ACTIVITY_ERROR:
    "You haven't added any sales activity data for this battle card, so there's nothing for us to sort. Add activity data and try again.",
  MISSING_TERRITORY_RULE_ERROR:
    "You don't have any territory rules yet, so we can't sort your sales activities. Create some territory rules and try again.",
  TIMEOUT_ERROR:
    "We couldn't complete coin sort due to high transaction volumes. Please refresh the page and try again."
};

/**
 * This map is designed to handle error messages from the backend and map them to user-friendly messages.
 * It supports two scenarios:
 * 1. When the backend error message is the same as the message we want to display to the user.
 *    In this case, both `backendMessage` and `userMessage` are the same.
 * 2. When the backend error message needs to be mapped to a different user-facing message.
 *    Here, `backendMessage` is the error string from the backend, and `userMessage` is the
 *    corresponding message intended for the user.
 *
 * Example usage:
 * - For direct matches (backend and frontend messages are the same), use the same string for both.
 * - For cases where mapping is needed (like the 'Missing Territory Groups' error), specify both
 *   backend and frontend messages distinctly.
 *
 * This setup is flexible to accommodate more complex error handling scenarios in the future.
 */
export const BACKEND_ERROR_TO_FRONTEND_ERROR_MAP: Record<string, string> = {
  [BACKEND_ERROR_STRINGS.MISSING_TERRITORY_GROUP]: formatMessage('COINSORT_MISSING_TERRITORY_GROUP_ERROR_DESC'),
  [BACKEND_ERROR_STRINGS.CANCEL_ERROR]: formatMessage('COINSORT_CANCEL_ERROR_DESC'),
  [BACKEND_ERROR_STRINGS.GENERIC_ERROR]: formatMessage('COINSORT_GENERIC_ERROR_DESC'),
  [BACKEND_ERROR_STRINGS.RUN_ERROR]: formatMessage('COINSORT_RUN_ERROR_DESC'),
  [BACKEND_ERROR_STRINGS.ACCESS_DENIED_ERROR]: formatMessage('COINSORT_ACCESS_DENIED_ERROR_DESC'),
  [BACKEND_ERROR_STRINGS.MISSING_ACTIVITY_ERROR]: formatMessage('COINSORT_MISSING_ACTIVITY_ERROR_DESC'),
  [BACKEND_ERROR_STRINGS.MISSING_TERRITORY_RULE_ERROR]: formatMessage('COINSORT_MISSING_TERRITORY_RULE_ERROR_DESC'),
  [BACKEND_ERROR_STRINGS.TIMEOUT_ERROR]: formatMessage('COINSORT_TIMEOUT_ERROR_DECS')
};

export const POLL_FREQUENCY_MS = 2000;
export const CLOSE_INDICATOR_AFTER_MS = 1000;
