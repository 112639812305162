import { useSplitTreatments } from '@splitsoftware/splitio-react';

import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { SplitFeatures } from 'app/global/features';

const useTreatment = (flag: SplitFeatures): [boolean, SplitIO.TreatmentWithConfig] => {
  const { selectedTenant } = useScope();
  const { userProfile } = useUser();

  const attributes = {
    tenantId: selectedTenant?.id,
    tenantSlug: selectedTenant?.slug,
    userEmail: userProfile?.emailAddress
  };

  const { treatments } = useSplitTreatments({
    names: [flag],
    attributes
  });

  const isTreatmentOn = treatments[flag].treatment === 'on';
  return [isTreatmentOn, treatments[flag]];
};

export default useTreatment;
