import React, { useState } from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { AnchorButton } from '@blueprintjs/core';
import { Locked } from '@carbon/icons-react';

//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { useUser } from 'app/core/userManagement/userProvider';

import { ContributorTreeData, WorkflowStatus, WorkflowType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ContributorActionCellRenderer.module.pcss';

const b = block(style);

interface ContributorActionCellRendererProps extends ICellRendererParams {
  contributorTreeData: ContributorTreeData[];
  onApprove: (data: ContributorTreeData) => void;
  onReject: (data: ContributorTreeData) => void;
  onSubmit: (data: ContributorTreeData) => void;
  submitWarningMessage: string;
}

enum Action {
  APPROVE = 'approve',
  REJECT = 'reject',
  SUBMIT = 'submit',
  NONE = 'none'
}

const ContributorActionCellRenderer: React.FC<ContributorActionCellRendererProps> = ({
  data,
  contributorTreeData,
  onApprove,
  onReject,
  onSubmit,
  submitWarningMessage
}: ContributorActionCellRendererProps) => {
  const { userProfile } = useUser();
  const [actionLoadingStatus, setActionLoadingStatus] = useState<Action>(Action.NONE);
  if (!data) {
    return null;
  }

  const getApproverEmail = () => {
    // This is add to check if the current user is both owner and approver when parentTerritoryGroupId is null
    // In case parentTerritoryGroupId is null, the current user is a battlecard owner and territory group type owner, they would still need to be able to approve/ reject and submit their workflow to a higher BC level
    // therefore, we need to check approver email to see if the current user is the approver of bc level or territory group type owner to enable approve or reject action
    const territoryGroupOwnerEmail = contributorTreeData?.find(
      (treeData) => treeData.territoryGroupId === data.parentTerritoryGroupId
    )?.email;

    if (territoryGroupOwnerEmail) {
      return territoryGroupOwnerEmail;
    } else {
      return data.approverEmail;
    }
  };

  // show submit button when current user is the owner and status is in-progress
  if (data.email === userProfile.emailAddress && data.status === WorkflowStatus.IN_PROGRESS) {
    const isBattlecardWorkflow =
      data.territoryGroupIdsPath.length === 1 && data.rootParentType === WorkflowType.BATTLECARD;

    // If current data workflow is battlecard root type, filter out battlecard data from contributor workflows
    // before checking if all driect childrens (territory group and territory group type) are approved
    const allChildrenWorkflows = isBattlecardWorkflow
      ? contributorTreeData?.filter((treeData) => treeData.territoryGroupIdsPath.length > 1)
      : contributorTreeData?.filter((treeData) => treeData.parentTerritoryGroupId === data.territoryGroupId);

    const allChildrenNotApproved =
      !!allChildrenWorkflows?.find((treeData) => treeData.status !== WorkflowStatus.APPROVED) ||
      data.status === WorkflowStatus.NOT_STARTED; // only can submit when all direct children are approved, otherwise button is disabled

    return (
      <MessageTooltip
        data-testid="submit-tooltip"
        content={submitWarningMessage}
        disabled={!allChildrenNotApproved}
        target={
          <AnchorButton
            intent="primary"
            minimal
            data-testid="submit-btn"
            disabled={allChildrenNotApproved}
            text={formatMessage('SUBMIT')}
            onClick={() => {
              setActionLoadingStatus(Action.SUBMIT);
              onSubmit(data);
            }}
            loading={actionLoadingStatus === Action.SUBMIT}
          />
        }
        placement={'top'}
      />
    );
  }

  // show read-only text when status is approved or
  // when the status is submitted and the current user is neither the current owner nor the higher level approver
  else if (
    data.status === WorkflowStatus.APPROVED ||
    (data.status === WorkflowStatus.SUBMITTED &&
      data.email === userProfile.emailAddress &&
      data.email !== getApproverEmail())
  ) {
    return (
      <div className={b('action')} data-testid="read-only-action">
        <Locked />
        {formatMessage('READONLY')}
      </div>
    );
  }

  // show approve/reject button when the status is submitted and the the current user is the approver
  else if (data.status === WorkflowStatus.SUBMITTED && userProfile.emailAddress === getApproverEmail()) {
    return (
      <div className={b('action')}>
        <AnchorButton
          intent="primary"
          minimal
          data-testid="approve-btn"
          text={formatMessage('APPROVE')}
          onClick={() => {
            setActionLoadingStatus(Action.APPROVE);
            onApprove(data);
          }}
          loading={actionLoadingStatus === Action.APPROVE}
        />
        <span>|</span>
        <AnchorButton
          intent="primary"
          minimal
          data-testid="reject-btn"
          text={formatMessage('REJECT')}
          onClick={() => {
            setActionLoadingStatus(Action.REJECT);
            onReject(data);
          }}
          loading={actionLoadingStatus === Action.REJECT}
        />
      </div>
    );
  }

  return null;
};

export default ContributorActionCellRenderer;
