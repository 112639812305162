import React, { FC, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { MenuItem } from '@varicent/components';

// eslint-disable-next-line no-restricted-imports
import MessageTooltip, { MessageTooltipProps } from 'components/MessageTooltip/MessageTooltip';

import block from 'utils/bem-css-modules';

import style from './MenuItemWithTooltip.module.pcss';

const b = block(style);

type PassedMenuItemProps = Omit<typeof MenuItem.defaultProps, 'onPointerOver' | 'onPointerLeave'>;

type PassedTooltipProps = Omit<MessageTooltipProps, 'open' | 'target' | 'className'> & { target: string | JSX.Element };

export type MenuItemWithTooltipProps = PassedMenuItemProps & {
  tooltip: PassedTooltipProps;
  'data-testid': string;
};

export const MenuItemWithTooltip: FC<MenuItemWithTooltipProps> = ({ tooltip, text, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MenuItem
      {...props}
      onPointerOver={() => setIsOpen(true)}
      onPointerLeave={() => setIsOpen(false)}
      text={<MessageTooltip {...tooltip} isOpen={isOpen} className={b('tooltip')} target={<div>{text}</div>} />}
    />
  );
};
