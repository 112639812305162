import React, { useEffect, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useMutation, useQuery } from '@apollo/client';
import { Spinner } from '@blueprintjs/core';
import { AddFilled, TrashCan, WarningAltFilled } from '@carbon/icons-react';
import { Field, Form, Formik, useFormikContext } from 'formik';
import clonedeep from 'lodash.clonedeep';
import get from 'lodash.get';

import Accordion from 'components/Accordion/Accordion';
import IconButton from 'components/Buttons/IconButton/IconButton';
import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import CurrencySelect from 'components/CurrencySelect/CurrencySelect';
import ConfirmDeleteModal from 'components/Dialog/ConfirmDeleteModal/ConfirmDeleteModal';
import EllipsisText from 'components/EllipsisText/EllipsisText';
//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import { AccordionData, SearchableSelectMenuItem } from 'components/models';

import FormattedCurrencyInput from 'app/components/FormFields/FormattedCurrencyInput/FormattedCurrencyInput';

import ErrorPage, { ErrorType } from 'app/containers/App/ErrorPage/ErrorPage';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCurrency } from 'app/contexts/currencyProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { handleError } from 'app/graphql/handleError';
import { UPSERT_CONVERSION_RATES } from 'app/graphql/mutations/upsertConversionRates';
import { GET_CONVERSION_RATES } from 'app/graphql/queries/getConversionRates';

import useShowToast from 'app/hooks/useShowToast';

import { CurrencyPageFormValues, LocalCurrency, ReportingCurrency } from 'app/models';

import block from 'utils/bem-css-modules';
import { getCurrencyItem, getSortedCurrencyItems } from 'utils/helpers/currencyHelpers';
import { formatMessage } from 'utils/messages/utils';

import style from './CurrencyPage.module.pcss';
import {
  getDesignatedToDefaultSynchronizationData,
  getDisabledCurrencies,
  getInvertedCurrency,
  getSortedAccordionData,
  getSubmissionData,
  invertCurrencyConversions,
  validate
} from './currencyPageUtils';

const b = block(style);

export interface DefaultReportingCurrencyRowProps {
  reportingCurrencyIndex: number;
  currencyItems: SearchableSelectMenuItem[];
}

export interface DesignatedReportingCurrencyRowProps {
  reportingCurrencyIndex: number;
  reportingCurrency: ReportingCurrency;
  accordionData?: AccordionData[];
  setAccordionData?: React.Dispatch<React.SetStateAction<AccordionData[]>>;
  currencyItems: SearchableSelectMenuItem[];
}

export interface LocalCurrencyRowProps {
  reportingCurrencyIndex: number;
  localCurrency?: Partial<LocalCurrency>;
  localCurrencyConversions?: Partial<LocalCurrency>[];
  setLocalCurrencyConversions?: React.Dispatch<React.SetStateAction<Partial<LocalCurrency>[]>>;
  currencyItems: SearchableSelectMenuItem[];
}

export interface ConversionPanelProps {
  reportingCurrencyIndex: number;
  reportingCurrency: ReportingCurrency;
  isDefaultReportingCurrency: boolean;
  localCurrencies: LocalCurrency[];
  accordionData: AccordionData[];
  setAccordionData: React.Dispatch<React.SetStateAction<AccordionData[]>>;
}

const CONVERSION_FIELD_DECIMAL_LIMIT = 4;
const FLAG_WIDTH = 16;
const FLAG_HEIGHT = 16;

const DefaultReportingCurrencyRow: React.FC<DefaultReportingCurrencyRowProps> = ({
  reportingCurrencyIndex,
  currencyItems
}) => {
  const { currenciesInUse } = useLocalization();

  return (
    <div className={b('currencyRow')} data-testid="default-reporting-currency-row">
      <div className={b('currencyRowFields')}>
        <div className={b('currencyField')} data-testid={`${reportingCurrencyIndex}-currency-field`}>
          <Field
            name={`${reportingCurrencyIndex}.currency`}
            items={currencyItems}
            currenciesInUse={currenciesInUse}
            component={CurrencySelect}
            allowPopperEventListeners={false}
            allowFlip={false}
            requireTouchForError={false}
            theme="default"
            showErrors={false}
            showErrorStyle={true}
            disabled={true}
          />
        </div>
      </div>
      <div className={b('deleteCurrencyButtonContainer')}>
        <IconButton
          type="button"
          icon={<TrashCan size={20} />}
          tooltipText={formatMessage('CURRENCY_IN_USE')}
          disabled={true}
          testId={`${reportingCurrencyIndex}-delete-button`}
          title={formatMessage('DELETE')}
        />
      </div>
    </div>
  );
};

const DesignatedReportingCurrencyRow: React.FC<DesignatedReportingCurrencyRowProps> = ({
  reportingCurrencyIndex,
  reportingCurrency,
  accordionData,
  setAccordionData,
  currencyItems
}) => {
  const { values, setValues, setFieldValue, errors } = useFormikContext();
  const { defaultReportingCurrency, setShouldValidateCurrencies, currenciesInUse } = useLocalization();

  const [showDesignatedCurrencyDeleteDialog, setShowDesignatedCurrencyDeleteDialog] = useState<boolean>(false);

  const disabledCurrencies = getDisabledCurrencies(
    values as CurrencyPageFormValues,
    reportingCurrencyIndex,
    defaultReportingCurrency
  );
  const areConversionFieldsInvalid =
    !!get(errors, `${reportingCurrencyIndex}.conversion`) || !!get(errors, `${reportingCurrencyIndex}.baseConversion`);
  const currentCurrencyValue = get(values, `${reportingCurrencyIndex}.currency.value`);

  // the designated currency row needs to be synchronized with the default
  // reporting currency, so get the information required to perform this synchronization
  const { defaultReportingCurrencyValues, designatedCurrencyValues } = getDesignatedToDefaultSynchronizationData(
    values as CurrencyPageFormValues,
    defaultReportingCurrency,
    currentCurrencyValue
  );

  // synchronize the conversion fields with conversions provided in the default reporting currency panel
  useEffect(() => {
    const currentFields = get(values, `${reportingCurrencyIndex}`);
    const updatedFields = {
      ...currentFields,
      baseConversion: designatedCurrencyValues?.baseConversion,
      conversion: designatedCurrencyValues?.conversion
    };

    // don't validate when synchronizing, wait for all synchronization to occur then perform
    // validation - this lets us avoid undesired validation behaviour which occurs
    // due to the fact that Formik's "values" are always bound to a specific render
    setFieldValue(`${reportingCurrencyIndex}`, updatedFields, false);
    setShouldValidateCurrencies(true);
  }, [designatedCurrencyValues?.baseConversion, designatedCurrencyValues?.conversion]);

  return (
    <div className={b('currencyRow')} data-testid={`${reportingCurrencyIndex}-designated-reporting-currency-row`}>
      <div className={b('currencyRowFields')}>
        <div className={b('baseConversionField')} data-testid={`${reportingCurrencyIndex}-base-conversion-field`}>
          <Field
            name={`${reportingCurrencyIndex}.baseConversion`}
            component={FormattedCurrencyInput}
            decimalsLimit={CONVERSION_FIELD_DECIMAL_LIMIT}
            showErrorStyle
            requireTouchForError={false}
            showErrors={false}
            useDefaultIntlFormatting={false}
            hideCurrencySymbol={true}
            disabled={true}
          />
        </div>
        <div className={b('currencyField')} data-testid={`${reportingCurrencyIndex}-currency-field`}>
          <Field
            name={`${reportingCurrencyIndex}.currency`}
            items={currencyItems}
            currenciesInUse={currenciesInUse}
            component={CurrencySelect}
            allowPopperEventListeners={false}
            allowFlip={false}
            disabledItems={disabledCurrencies}
            requireTouchForError={false}
            theme="default"
            showErrors={false}
            showErrorStyle={true}
            disabled={!reportingCurrency?.canDelete}
          />
        </div>
        <div className={b('equalsTextContainer')}>
          <span className={b('equalsText')} data-testid={`${reportingCurrencyIndex}-equals-text`}>
            {formatMessage('EQUALS_SIGN')}
          </span>
        </div>
        <div className={b('conversionField')} data-testid={`${reportingCurrencyIndex}-conversion-field`}>
          <Field
            name={`${reportingCurrencyIndex}.conversion`}
            component={FormattedCurrencyInput}
            decimalsLimit={CONVERSION_FIELD_DECIMAL_LIMIT}
            showErrorStyle
            requireTouchForError={false}
            showErrors={false}
            useDefaultIntlFormatting={false}
            hideCurrencySymbol={true}
            disabled={true}
          />
        </div>
        <div
          className={b('reportingCurrencyIndicatorContainer')}
          data-testid={`${reportingCurrencyIndex}-reporting-currency-indicator`}
        >
          {defaultReportingCurrencyValues?.currency?.icon}
          <EllipsisText
            className={b('reportingCurrencyIndicatorText')}
            text={defaultReportingCurrencyValues?.currency?.key}
            data-testid="reporting-currency-text"
          />
        </div>
        <div className={b('currencyConversionWarningIndicatorContainer')}>
          {areConversionFieldsInvalid ? (
            <MessageTooltip
              popoverClassName="tooltipContent"
              content={
                <span data-testid={`${reportingCurrencyIndex}-currency-conversion-warning-tooltip`}>
                  {formatMessage('SET_CONVERSION_RATE')}
                </span>
              }
              placement={'top'}
              target={
                <WarningAltFilled
                  size={20}
                  className={b('currencyConversionWarningIndicator')}
                  tabIndex="0"
                  data-testid={`${reportingCurrencyIndex}-currency-conversion-warning-indicator`}
                />
              }
            />
          ) : null}
        </div>
      </div>
      <div className={b('deleteCurrencyButtonContainer')}>
        <IconButton
          type="button"
          icon={<TrashCan size={20} />}
          tooltipText={
            !reportingCurrency?.canDelete ? formatMessage('CURRENCY_IN_USE') : formatMessage('DELETE_CURRENCY')
          }
          disabled={!reportingCurrency?.canDelete}
          onClick={() => setShowDesignatedCurrencyDeleteDialog(true)}
          testId={`${reportingCurrencyIndex}-delete-button`}
          title={formatMessage('DELETE')}
        />
      </div>
      <ConfirmDeleteModal
        isOpen={showDesignatedCurrencyDeleteDialog}
        onConfirmDelete={async (e) => {
          e.stopPropagation();

          // when deleting a designated reporting currency row, we need
          // to delete all the values associated with the reporting
          // currency (i.e. all the local currency information)
          // and hide its corresponding accordion panel
          const newAccordionData = clonedeep(accordionData);
          const newValues = clonedeep(values);

          newAccordionData[`${reportingCurrencyIndex}`].hidePanel = true;
          delete newValues[`${reportingCurrencyIndex}`];

          setValues(newValues);
          setAccordionData(newAccordionData);
        }}
        onCancel={(e) => {
          e.stopPropagation();
          setShowDesignatedCurrencyDeleteDialog(false);
        }}
        titleText={formatMessage('DELETE_REPORTING_CURRENCY')}
        bodyText={formatMessage('CONFIRM_DELETE_REPORTING_CURRENCY')}
      />
    </div>
  );
};

const LocalCurrencyRow: React.FC<LocalCurrencyRowProps> = ({
  reportingCurrencyIndex,
  localCurrency,
  localCurrencyConversions,
  setLocalCurrencyConversions,
  currencyItems
}) => {
  const { setFieldValue, values, errors } = useFormikContext();
  const { areCurrencyConversionsInverted, defaultReportingCurrency, currenciesInUse } = useLocalization();

  const [showLocalCurrencyDeleteDialog, setShowLocalCurrencyDeleteDialog] = useState<boolean>(false);

  const disabledCurrencies = getDisabledCurrencies(
    values as CurrencyPageFormValues,
    reportingCurrencyIndex,
    defaultReportingCurrency
  );
  const selectedReportingCurrency = get(values, `${reportingCurrencyIndex}.currency`);
  const fieldName = `${reportingCurrencyIndex}.localCurrencies[${localCurrency?.localCurrencyIndex}]`;
  const areConversionFieldsInvalid =
    !!get(errors, `${fieldName}.conversion`) || !!get(errors, `${fieldName}.baseConversion`);

  return (
    <div className={b('currencyRow')} data-testid={`${fieldName}-local-currency-row`}>
      <div className={b('currencyRowFields')}>
        <div className={b('baseConversionField')} data-testid={`${fieldName}-base-conversion-field`}>
          <Field
            name={`${fieldName}.baseConversion`}
            component={FormattedCurrencyInput}
            decimalsLimit={CONVERSION_FIELD_DECIMAL_LIMIT}
            showErrorStyle
            requireTouchForError={false}
            showErrors={false}
            useDefaultIntlFormatting={false}
            hideCurrencySymbol={true}
            disabled={!areCurrencyConversionsInverted}
          />
        </div>
        <div className={b('currencyField')} data-testid={`${fieldName}-currency-field`}>
          <Field
            name={`${fieldName}.currency`}
            items={currencyItems}
            currenciesInUse={currenciesInUse}
            component={CurrencySelect}
            allowPopperEventListeners={false}
            allowFlip={false}
            disabledItems={disabledCurrencies}
            requireTouchForError={false}
            theme="default"
            showErrors={false}
            showErrorStyle={true}
            disabled={!localCurrency?.canDelete}
          />
        </div>
        <div className={b('equalsTextContainer')}>
          <span className={b('equalsText')} data-testid={`${fieldName}-equals-text`}>
            {formatMessage('EQUALS_SIGN')}
          </span>
        </div>
        <div className={b('conversionField')} data-testid={`${fieldName}-conversion-field`}>
          <Field
            name={`${fieldName}.conversion`}
            component={FormattedCurrencyInput}
            decimalsLimit={CONVERSION_FIELD_DECIMAL_LIMIT}
            showErrorStyle
            requireTouchForError={false}
            showErrors={false}
            useDefaultIntlFormatting={false}
            hideCurrencySymbol={true}
            disabled={areCurrencyConversionsInverted}
          />
        </div>
        <div
          className={b('reportingCurrencyIndicatorContainer')}
          data-testid={`${fieldName}-reporting-currency-indicator`}
        >
          {selectedReportingCurrency?.icon}
          <EllipsisText
            className={b('reportingCurrencyIndicatorText')}
            text={selectedReportingCurrency?.key}
            data-testid="reporting-currency-text"
          />
        </div>
        <div className={b('currencyConversionWarningIndicatorContainer')}>
          {areConversionFieldsInvalid ? (
            <MessageTooltip
              popoverClassName="tooltipContent"
              content={
                <span data-testid={`${fieldName}-currency-conversion-warning-tooltip`}>
                  {formatMessage('SET_CONVERSION_RATE')}
                </span>
              }
              placement={'top'}
              target={
                <WarningAltFilled
                  size={20}
                  className={b('currencyConversionWarningIndicator')}
                  tabIndex="0"
                  data-testid={`${fieldName}-currency-conversion-warning-indicator`}
                />
              }
            />
          ) : null}
        </div>
      </div>
      <div className={b('deleteCurrencyButtonContainer')}>
        <IconButton
          type="button"
          icon={<TrashCan size={20} />}
          tooltipText={!localCurrency?.canDelete ? formatMessage('CURRENCY_IN_USE') : formatMessage('DELETE_CURRENCY')}
          disabled={!localCurrency?.canDelete}
          onClick={() => setShowLocalCurrencyDeleteDialog(true)}
          testId={`${fieldName}-delete-button`}
          title={formatMessage('DELETE')}
        />
      </div>
      <ConfirmDeleteModal
        isOpen={showLocalCurrencyDeleteDialog}
        onConfirmDelete={async (e) => {
          e.stopPropagation();

          // when deleting a local currency row, set its
          // field's value to null and remove it from the panel
          const newConversions = localCurrencyConversions.filter(
            (item) => item?.localCurrencyIndex !== localCurrency?.localCurrencyIndex
          );

          setFieldValue(`${fieldName}`, null);
          setLocalCurrencyConversions(newConversions);
        }}
        onCancel={(e) => {
          e.stopPropagation();
          setShowLocalCurrencyDeleteDialog(false);
        }}
        titleText={formatMessage('DELETE_LOCAL_CURRENCY')}
        bodyText={formatMessage('CONFIRM_DELETE_LOCAL_CURRENCY')}
      />
    </div>
  );
};

const ConversionPanel: React.FC<ConversionPanelProps> = ({
  reportingCurrencyIndex,
  reportingCurrency,
  isDefaultReportingCurrency,
  localCurrencies,
  accordionData,
  setAccordionData
}) => {
  const { currencies } = useCurrency();
  const { areCurrencyConversionsInverted, shouldValidateCurrencies, setShouldValidateCurrencies, currenciesInUse } =
    useLocalization();
  const { setFieldValue, validateForm } = useFormikContext();

  // to ensure "added" fields always have a unique index to be identified
  const [numOfLocalCurrencyConversions, setNumOfLocalCurrencyConversions] = useState<number>(localCurrencies.length);
  const [localCurrencyConversions, setLocalCurrencyConversions] = useState<Partial<LocalCurrency>[]>(localCurrencies);

  const currencyItems = getSortedCurrencyItems(currencies, currenciesInUse, FLAG_WIDTH, FLAG_HEIGHT);

  useEffect(() => {
    if (shouldValidateCurrencies) {
      validateForm();
      setShouldValidateCurrencies(false);
    }
  }, [shouldValidateCurrencies]);

  return (
    <div className={b('conversionPanel')} data-testid={`${reportingCurrencyIndex}-conversion-panel`}>
      {isDefaultReportingCurrency ? (
        <DefaultReportingCurrencyRow reportingCurrencyIndex={reportingCurrencyIndex} currencyItems={currencyItems} />
      ) : (
        <DesignatedReportingCurrencyRow
          reportingCurrencyIndex={reportingCurrencyIndex}
          reportingCurrency={reportingCurrency}
          accordionData={accordionData}
          setAccordionData={setAccordionData}
          currencyItems={currencyItems}
        />
      )}
      <div className={b('columnHeaderContainer')}>
        <div className={b('localCurrencyHeaderContainer')}>
          <span
            className={b('columnHeader', { local: true })}
            data-testid={`${reportingCurrencyIndex}-local-currency-header`}
          >
            {formatMessage('LOCAL_CURRENCY')}
          </span>
        </div>
        <div className={b('conversionRateHeader')}>
          <EllipsisText
            text={formatMessage('CONSTANT_CURRENCY_EXCHANGE_RATE')}
            className={b('columnHeader')}
            data-testid={`${reportingCurrencyIndex}-constant-currency-rate-header`}
          />
        </div>
      </div>
      <>
        {(() => {
          return localCurrencyConversions.map((localCurrency) => {
            return (
              <LocalCurrencyRow
                reportingCurrencyIndex={reportingCurrencyIndex}
                localCurrency={localCurrency}
                localCurrencyConversions={localCurrencyConversions}
                setLocalCurrencyConversions={setLocalCurrencyConversions}
                currencyItems={currencyItems}
                key={`${reportingCurrencyIndex}.localCurrencies[${localCurrency?.localCurrencyIndex}]`}
              />
            );
          });
        })()}
      </>
      <div className={b('addCurrencyButton')}>
        <TextButton
          testId={`${reportingCurrencyIndex}-add-currency-button`}
          type="button"
          icon={<AddFilled />}
          text={formatMessage('ADD_CURRENCY')}
          onClick={() => {
            // add a new local currency row
            const newCurrency = {
              canDelete: true,
              localCurrencyIndex: numOfLocalCurrencyConversions
            };

            // depending on if the conversions are inverted, we need to either
            // give the baseConversion field or the conversion field a default value of 1
            const newFields = `${reportingCurrencyIndex}.localCurrencies[${newCurrency?.localCurrencyIndex}]`;
            const conversionFieldName = areCurrencyConversionsInverted
              ? `${newFields}.conversion`
              : `${newFields}.baseConversion`;

            setFieldValue(conversionFieldName, 1);
            setLocalCurrencyConversions([...localCurrencyConversions, newCurrency]);
            setNumOfLocalCurrencyConversions(numOfLocalCurrencyConversions + 1);
          }}
          minimal
        />
      </div>
    </div>
  );
};

const CurrencyPage: React.FC = () => {
  const { currencies } = useCurrency();
  const {
    defaultReportingCurrency,
    areCurrencyConversionsInverted,
    setAreCurrencyConversionsInverted,
    setShouldValidateCurrencies
  } = useLocalization();
  const { selectedPlanningCycle } = useScope();
  const { setShouldRefetchBattleCardDataImmediately } = useBattleCard();
  const showToast = useShowToast();

  const [accordionData, setAccordionData] = useState<AccordionData[]>([]);
  const [initialFormValues, setInitialFormValues] = useState<CurrencyPageFormValues>({});
  const [hasError, setHasError] = useState<boolean>(false);

  const { data: conversionRates, loading: conversionRatesLoading } = useQuery(GET_CONVERSION_RATES, {
    fetchPolicy: 'network-only',
    variables: {
      planningCycleId: selectedPlanningCycle?.id
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      setHasError(true);
    }
  });

  const [upsertConversionRates, { loading: upsertConversionRatesLoading }] = useMutation(UPSERT_CONVERSION_RATES, {
    onCompleted() {
      showToast(formatMessage('CURRENCY_PAGE_SUCCESS'), 'success');
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('CURRENCY_PAGE_ERROR'), 'danger');
    }
  });

  // initialize the form with all existing currency conversions, and create the necessary accordion components
  useEffect(() => {
    if (conversionRates && !conversionRatesLoading) {
      const newAccordionData = [];
      const newInitialFormValues = {};
      conversionRates?.getConversionRates.forEach((reportingCurrencyData, reportingCurrencyIndex) => {
        const reportingCurrencyFormValues = {};
        const currency = getCurrencyItem(
          currencies,
          reportingCurrencyData?.reportingCurrencyCode,
          FLAG_WIDTH,
          FLAG_HEIGHT
        );

        // initialize the reportingCurrency's form values
        reportingCurrencyFormValues['currency'] = currency;
        reportingCurrencyFormValues['conversion'] = 1.0;
        reportingCurrencyFormValues['baseConversion'] = 1.0;

        // we need to be able to extend the localCurrencies object in order
        // to add a localCurrencyIndex property to uniquely identify each localCurrency object
        const extensibleLocalCurrencies = clonedeep(reportingCurrencyData.localCurrencies);
        const localCurrenciesFormValues = [];
        extensibleLocalCurrencies.forEach((localCurrency, localCurrencyIndex) => {
          const localCurrencyValues = {};

          // add a localCurrencyIndex property to each localCurrency, so it can be uniquely identified
          localCurrency['localCurrencyIndex'] = localCurrencyIndex;

          // initialize the localCurrency's form values
          localCurrencyValues['currency'] = getCurrencyItem(
            currencies,
            localCurrency?.localCurrencyCode,
            FLAG_WIDTH,
            FLAG_HEIGHT
          );

          if (areCurrencyConversionsInverted) {
            localCurrencyValues['conversion'] = 1.0;
            localCurrencyValues['baseConversion'] = getInvertedCurrency(localCurrency?.conversionRate);
          } else {
            localCurrencyValues['conversion'] = localCurrency?.conversionRate;
            localCurrencyValues['baseConversion'] = 1.0;
          }

          localCurrenciesFormValues.push(localCurrencyValues);
        });

        reportingCurrencyFormValues['localCurrencies'] = localCurrenciesFormValues;
        newInitialFormValues[`${reportingCurrencyIndex}`] = reportingCurrencyFormValues;

        const isDefaultReportingCurrency = defaultReportingCurrency === reportingCurrencyData?.reportingCurrencyCode;

        newAccordionData.push({
          name: reportingCurrencyIndex,
          header: isDefaultReportingCurrency
            ? formatMessage('REPORTING_CURRENCY_DEFAULT')
            : formatMessage('REPORTING_CURRENCY_DESIGNATED'),
          subheader: '',
          component: (
            <ConversionPanel
              reportingCurrencyIndex={reportingCurrencyIndex}
              reportingCurrency={reportingCurrencyData}
              isDefaultReportingCurrency={isDefaultReportingCurrency}
              localCurrencies={extensibleLocalCurrencies}
              accordionData={newAccordionData}
              setAccordionData={setAccordionData}
            />
          ),
          isOpen: true
        });
      });

      setAccordionData(newAccordionData);
      setInitialFormValues(newInitialFormValues);
    }
  }, [conversionRatesLoading]);

  const sortedAccordionData = getSortedAccordionData(accordionData);

  return (
    <div className={b()} data-testid="currency-page">
      <Formik
        initialValues={initialFormValues}
        validate={(values) => validate(values, areCurrencyConversionsInverted)}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize
        validateOnMount
        onSubmit={async (values) => {
          await upsertConversionRates({
            variables: {
              upsertConversionRatesInput: getSubmissionData(
                values,
                areCurrencyConversionsInverted,
                selectedPlanningCycle?.id
              )
            },
            refetchQueries: [
              {
                query: GET_CONVERSION_RATES,
                variables: {
                  planningCycleId: selectedPlanningCycle?.id
                }
              }
            ]
          });
          setShouldRefetchBattleCardDataImmediately(true);
        }}
      >
        {({ isSubmitting, isValid, isValidating, values, setFieldValue }) => {
          return (
            <Form className={b('currencyForm')}>
              <div className={b('currencyHeader')} data-testid="currency-header">
                <span data-testid="available-currencies-text">{formatMessage('AVAILABLE_CURRENCIES')}</span>
                <SwitchButton
                  checked={areCurrencyConversionsInverted}
                  disabled={conversionRatesLoading}
                  onChange={() => {
                    invertCurrencyConversions(values, areCurrencyConversionsInverted, setFieldValue);
                    setAreCurrencyConversionsInverted(!areCurrencyConversionsInverted);
                    setShouldValidateCurrencies(true);
                  }}
                  labelElement={
                    <span className={b('invertExchangeRatesText')} data-testid="invert-exchange-rates-text">
                      {formatMessage('INVERT_EXCHANGE_RATES')}
                    </span>
                  }
                  testId="invert-exchange-rates-switch"
                />
              </div>
              {conversionRatesLoading && (
                <div className={b('spinnerContainer')} data-testid="spinner">
                  <Spinner intent="primary" size={40} />
                </div>
              )}
              {!conversionRatesLoading && hasError && <ErrorPage errorType={ErrorType.INTERNAL} />}
              {!conversionRatesLoading && sortedAccordionData?.length > 0 && (
                <div className={b('currencyBody')}>
                  <Accordion
                    data={sortedAccordionData}
                    allowMultipleOpenPanels
                    panelTitleClassName={b('accordionPanelTitle')}
                  />
                  <div className={b('footer')}>
                    <div className={b('submitButton')}>
                      <TextButton
                        testId={'submit-btn'}
                        text={formatMessage('SUBMIT')}
                        type="submit"
                        intent="primary"
                        disabled={!isValid || isValidating}
                        loading={isSubmitting || upsertConversionRatesLoading}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CurrencyPage;
