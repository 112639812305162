import React, { FC } from 'react';

import { WarningAltFilled as OverAssignedIcon, Error as NonMappableIcon } from '@carbon/icons-react';

import EllipsisText from 'components/EllipsisText/EllipsisText';
//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import TerritoryColorCellRenderer from 'app/components/AdvancedGrid/CellRenderers/TerritoryColorCellRenderer/TerritoryColorCellRenderer';
import { TmgTerritoryRow } from 'app/components/TerritoryMap/TerritoryMapGridV2';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './TerritoryMapOmniCellRenderer.module.pcss';
const b = block(style);

type RowData = Pick<TmgTerritoryRow, 'rule' | 'isIgnored' | 'isLocked' | 'territoryNameAndId' | 'groupForLevel'>;

export type TerritoryMapOmniCellRendererProps = {
  data?: RowData;
  onRecolorFinish: () => void;
};

export const TerritoryMapOmniCellRenderer: FC<TerritoryMapOmniCellRendererProps> = ({ data, onRecolorFinish }) => {
  if (!data?.rule) return null;
  const { isIgnored, territoryNameAndId } = data;

  return (
    <span className={b({ isIgnored })} data-testid={`territory-omni-cell-${data.rule.territoryId}`}>
      <Indicator data={data} onRecolorFinish={onRecolorFinish} />
      <EllipsisText text={territoryNameAndId} />
    </span>
  );
};

const Indicator: FC<{ data: RowData; onRecolorFinish: () => void }> = ({ data, onRecolorFinish }) => {
  if (!data.rule.isMappable) {
    return (
      <MessageTooltip
        content={formatMessage('NON_MAPPABLE_TOOLTIP')}
        target={<NonMappableIcon data-testid="non-mappable-icon" className={b('nonMappableIcon')} />}
        className={b('iconTooltip')}
        placement="top"
      />
    );
  }
  if (data.rule.isOverAssigned) {
    return (
      <MessageTooltip
        content={formatMessage('CONFLICTING_RULES_GRID_TOOLTIP')}
        target={<OverAssignedIcon data-testid="rule-conflict-icon" className={b('overAssignedIcon')} />}
        className={b('iconTooltip')}
        placement="top"
      />
    );
  }

  // Todo: Convert to no longer be cell renderer once MapGridv1 is removed
  return (
    <span className={b('colorWrapper')}>
      <TerritoryColorCellRenderer data={data} onUpdateRules={onRecolorFinish} />
    </span>
  );
};
