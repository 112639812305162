// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".r5NwD {\n  display: grid;\n  grid-template-columns: auto 1fr;\n  overflow: hidden;\n  padding: 8px 12px;\n  font-weight: normal;\n  margin-left: 12px;\n}\n\n  .\\+4rrb {\n    display: flex;\n    align-items: center;\n  }\n\n  .UsGze {\n    overflow: hidden;\n  }\n\n  .X0t4K {\n    --translucent-dark: rgba(0,0,0,0.12549);\n\n    box-shadow: 0 0 0 1px inset var(--translucent-dark);\n    background-color: var(--territory-color) !important;\n    min-width: 10px;\n    min-height: 10px;\n    border-radius: 0;\n    margin-right: 4px;\n    opacity: 0.6;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IndivisibleClusterList": "r5NwD",
	"IndivisibleClusterList_territoryNameWrapper": "+4rrb",
	"IndivisibleClusterList_textWrapper": "UsGze",
	"IndivisibleClusterList_coloredSquare": "X0t4K"
};
export default ___CSS_LOADER_EXPORT___;
