import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Field, useFormikContext } from 'formik';

//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import CalculatedFieldsPage from 'app/components/DataPanel/SheetsPanel/SheetDetail/SheetsAddFieldForm/CalculatedFieldsPage';
import CustomMeasureFormPartOne from 'app/components/DataPanel/SheetsPanel/SheetDetail/SheetsAddFieldForm/CustomMeasureFormPartOne';
import CustomMeasureFormPartTwo from 'app/components/DataPanel/SheetsPanel/SheetDetail/SheetsAddFieldForm/CustomMeasureFormPartTwo';
import HistoricalFieldsPage from 'app/components/DataPanel/SheetsPanel/SheetDetail/SheetsAddFieldForm/HistoricalFieldsPage';
import { SheetsAddFieldFormValues } from 'app/components/DataPanel/SheetsPanel/SheetDetail/SheetsAddFieldForm/SheetsAddFieldForm';
import FormCheckbox from 'app/components/FormFields/FormCheckbox/FormCheckbox';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import { GetHierarchyForField } from 'app/graphql/generated/graphqlApolloTypes';

import { DataType, MeasureFieldType, MeasureFormatType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SheetsAddFieldFormFields.module.pcss';

const b = block(style);

interface SheetsAddFieldFormFieldsProps {
  step: number;
  setSelectedFieldType: Dispatch<SetStateAction<MeasureFieldType | string | null>>;
  selectedFieldType: MeasureFieldType | unknown;
  isEditable: boolean;
  hierarchyData?: GetHierarchyForField;
  isDefaultBalancingMetric?: boolean;
  setFormulaErrorCount: Dispatch<SetStateAction<number>>;
}

const BalancingMetricCheckboxes = ({ checkboxDisabled, isDefaultBalancingMetric }) => {
  return (
    <>
      <div className={b('formInput')} data-testid="balancing-metric-checkbox-field">
        <Field
          name="isBalancingMetric"
          component={FormCheckbox}
          placeHolderText={formatMessage('SELECT')}
          type="checkbox"
          labelElement={<span className={b('checkboxLabel')}>{formatMessage('SET_BALANCING_METRIC')}</span>}
          disabled={checkboxDisabled || isDefaultBalancingMetric}
        />
      </div>

      <MessageTooltip
        content={
          checkboxDisabled || isDefaultBalancingMetric
            ? formatMessage('DEFAULT_BALANCING_METRIC_WARNING_MESSAGE')
            : formatMessage('BEFORE_BALANCING_METRIC_DEFAULT')
        }
        target={
          <div
            className={`${b('formInput')} ${b('subCheckbox')}`}
            data-testid="default-balancing-metric-checkbox-field"
          >
            <Field
              name="isDefaultBalancingMetric"
              component={FormCheckbox}
              placeHolderText={formatMessage('SELECT')}
              type="checkbox"
              labelElement={<span className={b('checkboxLabel')}>{formatMessage('MAKE_THIS_DEFAULT')}</span>}
              disabled={checkboxDisabled || isDefaultBalancingMetric}
            />
          </div>
        }
        placement={'left'}
      />
    </>
  );
};

export const SheetsAddFieldFormFields: React.FC<SheetsAddFieldFormFieldsProps> = ({
  step,
  setSelectedFieldType,
  selectedFieldType,
  isEditable,
  hierarchyData,
  isDefaultBalancingMetric,
  setFormulaErrorCount
}: SheetsAddFieldFormFieldsProps) => {
  const [hierarchyId, setHierarchyId] = useState<number | null>(null);
  const [hierarchyCustomPropertiesItems, setHierarchyCustomPropertiesItems] = useState<
    { key: string; value: string }[]
  >([]);
  const [checkboxDisabled, setCheckboxDisabled] = useState<boolean>(
    isDefaultBalancingMetric || selectedFieldType === MeasureFieldType.LOADED
  );

  const hierarchyDataItems = hierarchyData?.getDeploymentModelSpec?.pinnedHierarchies.reduce((acc, curr) => {
    const item = { key: curr.name, value: curr.hierarchyId, type: curr.hierarchyType };
    acc = [...acc, item];
    return acc;
  }, []);

  const { values, setFieldValue } = useFormikContext<SheetsAddFieldFormValues>();

  useEffect(() => {
    // set selected field type
    if (values?.measureFieldType) {
      setSelectedFieldType(values.measureFieldType.value);
    }

    // If the selected field type is Loaded and selected format is percentage, clear the selected
    if (
      values?.measureFieldType?.['value'] === MeasureFieldType.LOADED &&
      values?.measureFormatType?.['value'] === MeasureFormatType.PERCENTAGE
    ) {
      setFieldValue('measureFormatType', '');
    }

    // If the selected field type is Calculated and selected format is non-numeric, clear the selection
    const isNonNumericFormat =
      values?.measureFormatType?.['value'] === MeasureFormatType.DATE ||
      values?.measureFormatType?.['value'] === MeasureFormatType.TEXT;
    if (values?.measureFieldType?.['value'] === MeasureFieldType.CALCULATED && isNonNumericFormat) {
      setFieldValue('measureFormatType', '');
    }
  }, [values]);

  useEffect(() => {
    const formatType = [MeasureFormatType.DATE as string, MeasureFormatType.TEXT as string];
    if (
      (values.measureFormatType && formatType.includes(values?.measureFormatType?.value)) ||
      selectedFieldType === MeasureFieldType.LOADED
    ) {
      setFieldValue('isBalancingMetric', false);
      setFieldValue('isDefaultBalancingMetric', false);
      setCheckboxDisabled(true);
    } else {
      setCheckboxDisabled(false);
    }
  }, [values, selectedFieldType]);

  useEffect(() => {
    if (values?.fieldId && values?.fieldId?.type) {
      setHierarchyId(+values.fieldId.value);
    }
  }, [values]);

  useEffect(() => {
    if (values?.isDefaultBalancingMetric) {
      setFieldValue('isBalancingMetric', true);
    }
  }, [values.isDefaultBalancingMetric]);

  useEffect(() => {
    if (!values?.isBalancingMetric) {
      setFieldValue('isBalancingMetric', false);
      setFieldValue('isDefaultBalancingMetric', false);
    }
  }, [values.isBalancingMetric]);

  useEffect(() => {
    if (hierarchyData && hierarchyId) {
      const selectedHierarchy = hierarchyData?.getDeploymentModelSpec?.pinnedHierarchies.find(
        (pinnedHierarchy) => pinnedHierarchy.hierarchyId === hierarchyId
      );
      const parsedCustomProperties = JSON.parse(selectedHierarchy?.customProperties);
      setHierarchyCustomPropertiesItems(
        parsedCustomProperties.properties
          ?.filter((item) => item.type === DataType.NUMBER.toLocaleLowerCase())
          .map((property) => {
            return { key: property.name, value: property.name };
          })
      );
    }
  }, [hierarchyId, hierarchyData]);

  return (
    <div className={b('measureFormPage')}>
      <Field
        label={formatMessage('COLUMN_NAME')}
        name="measureName"
        type="text"
        component={FormTextInputGroup}
        disabled={!isEditable || step > 0}
      />
      {step === 0 ? (
        <>
          <CustomMeasureFormPartOne isEditable={isEditable} data-testid={'form-one'} />
          <BalancingMetricCheckboxes
            checkboxDisabled={checkboxDisabled}
            isDefaultBalancingMetric={isDefaultBalancingMetric}
          />
        </>
      ) : (
        <>
          {selectedFieldType === MeasureFieldType.LOADED && (
            <CustomMeasureFormPartTwo
              hierarchyDataItems={hierarchyDataItems}
              measureSource={values.measureSource}
              fieldId={values.fieldId}
              hierarchyCustomPropertiesItems={hierarchyCustomPropertiesItems}
              isEditable={isEditable}
              data-testid={'form-two'}
            />
          )}
          {selectedFieldType === MeasureFieldType.CALCULATED && (
            <CalculatedFieldsPage
              isEditable={isEditable}
              setFormulaErrorCount={setFormulaErrorCount}
              data-testid={'calculated-fields-page'}
            />
          )}
          {selectedFieldType === MeasureFieldType.HISTORICAL && (
            <HistoricalFieldsPage data-testid={'historical-page'} />
          )}
        </>
      )}
    </div>
  );
};

export default SheetsAddFieldFormFields;
