import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Spinner } from '@blueprintjs/core';
import { Intent, Tag } from '@varicent/components';
import { MapboxMap, MapLayerMouseEvent, Popup } from 'react-map-gl';

import ToastMessage from 'components/ToastMessage/ToastMessage';

import AccountTooltip from 'app/components/TerritoryMap/AccountTooltip';
import DeleteTerritoryDialog from 'app/components/TerritoryMap/DeleteTerritoryDialog';
import { useLineRulerTool } from 'app/components/TerritoryMap/hooks/useLineRulerTool';
import { useMapQuestion } from 'app/components/TerritoryMap/hooks/useMapQuestion';
import { useMapSelectionActions } from 'app/components/TerritoryMap/hooks/useMapSelectionActions';
import InsightsCard from 'app/components/TerritoryMap/InsightsCard';
import MapActionMenu, { MapActionMenuProps } from 'app/components/TerritoryMap/MapActionMenu';
import MapTooltip from 'app/components/TerritoryMap/MapTooltip';
import PolygonSourceLayers from 'app/components/TerritoryMap/PolygonSourceLayers';
import UpsertTerritoryRuleDialog from 'app/components/TerritoryMap/UpsertTerritoryRuleDialog';

import { useCoinsort } from 'app/contexts/coinsortProvider';
import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';
import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import { SplitFeatures } from 'app/global/features';
import { LASSO_CURSOR, SELECT_POINT_CURSOR } from 'app/global/variables';

import { SourceInput } from 'app/graphql/generated/graphqlApolloTypes';
import { useMapRules } from 'app/graphql/hooks/useMapRules';
import { useSegments } from 'app/graphql/hooks/useSegments';

import { useExposeExternalUIControl } from 'app/hooks/useExposeExternalUIControl';
import useKeyDown from 'app/hooks/useKeyDown';
import useObserveMapSize from 'app/hooks/useObserveMapSize';
import { useSelectionNotificationToasts } from 'app/hooks/useSelectionNotificationToasts';
import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import {
  CustomerVisualization,
  LassoPolygon,
  MapActionMenuEnum,
  MapActions,
  MapContextMenuInfo,
  MapSelectionRestriction,
  MapSelectionTarget,
  MapSelectionTool,
  PolygonTooltip,
  PossibleMapActions,
  AccountTypeFilterOptions,
  CustomerTooltip,
  AccountTerritoryFilterOptions,
  HierarchyType,
  AccountFilter,
  RuleChangeCauses,
  MapWidth,
  AggregatePin,
  ClusterFeature,
  InsightsCardState,
  CollectionFilterKind,
  MapHoverEvent,
  LineRulerUnit,
  SegmentEditorDialogModes
} from 'app/models';

import 'mapbox-gl/dist/mapbox-gl.css';

import block from 'utils/bem-css-modules';
import { reportAnalyticsAction } from 'utils/helpers/analyticsReporter';
import { createScopedLocalStorage } from 'utils/helpers/localStorageHelpers';
import { optimizeNativePolygonIdForHashing } from 'utils/helpers/polygonIdHelpers';
import {
  formatAccountTooltip,
  formatGeoTooltip,
  loadMapImages,
  queryFeaturesInBounds,
  getTerritoryGroupLevelName,
  isLineRulerTool,
  isLassoTool,
  getCoordinatesFromPoint,
  getMapZoomToastText
} from 'utils/helpers/territoryMapUtils';
import { MapCapability } from 'utils/maps/mapCapabilityRegistry';
import { formatMessage } from 'utils/messages/utils';

import AccountMapWarningDialog from './AccountMapWarningDialog';
import { AccountSourceLayers } from './AccountSourceLayers';
import { lassoPinsAndClusters, breakdownCluster } from './ClusterHelper';
import CompareModeDialog from './CompareModeDialog';
import CustomPinSetSourceLayers from './CustomPinSetSourceLayers';
import { DefaultMapboxMap } from './DefaultMapboxMap';
import FixConflictDialog from './FixConflictDialog';
import { GroveHelpersClusterRefHandle } from './GroveClusterAccountSourceLayers';
import { Gutter } from './Gutter/Gutter';
import { useApplyFeatureState } from './hooks/useApplyFeatureState';
import { useCustomerAggregatesFeatures } from './hooks/useCustomerAggregatesFeatures';
import { useCustomPins } from './hooks/useCustomPins';
import { useDynamicMapHierarchyCopy } from './hooks/useDynamicMapHierarchyCopy';
import { useExposeHoverControls } from './hooks/useExposeHoverControls';
import { useGetAvailablePinSets } from './hooks/useGetAvailablePinSets';
import { useHideInactiveCountryLabels } from './hooks/useHideInactiveCountryLabels';
import { useHighwaysHighlighting } from './hooks/useHighwaysHighlighting';
import { useInitialViewState } from './hooks/useInitialViewState';
import { useIsLoadingSources } from './hooks/useIsLoadingSources';
import { useLanguageSuffix } from './hooks/useLanguageSuffix';
import { useIsMapCapable } from './hooks/useMapCapability';
import { useMapDraw } from './hooks/useMapDrawV3';
import { useSetMapLabelLanguage } from './hooks/useSetMapLabelLanguage';
import { useShouldShowAccountMapWarning } from './hooks/useShouldShowAccountMapWarning';
import { useWorkerManagedState } from './hooks/useWorkerManagedState';
import IndivisibleClusterList from './IndivisibleClusterList';
import LineRuler from './LineRuler/LineRuler';
import LineRulerDistanceIndicator from './LineRulerDistanceIndicator/LineRulerDistanceIndicator';
import { MapboxAttribution } from './MapboxAttribution';
import { LayerIds, SourceIds, LINE_RULER_DISTANCE_UNIT_STORAGE_KEY } from './MapStyleTokens';
import AccountFilterMenuV2 from './MapToolbars/AccountFilterMenuV2/AccountFilterMenuV2';
import ActionBarContainerV2 from './MapToolbars/ActionBarContainerV2/ActionBarContainerV2';
import ClearSelectionButtonV2 from './MapToolbars/ClearSelectionButtonV2/ClearSelectionButtonV2';
import LayersToggle from './MapToolbars/LayersToggle/LayersToggle';
import LineRulerButton from './MapToolbars/LineRulerButton/LineRulerButton';
import PinsButtonV2 from './MapToolbars/PinsButtonV2/PinsButtonV2';
import SelectionTargetDropdownV2 from './MapToolbars/SelectionTargetDropdownV2/SelectionTargetDropdownV2';
import SelectionToolSettingsBarV2 from './MapToolbars/SelectionToolSettingsBarV2/SelectionToolSettingsBarV2';
import ZoomBarV2 from './MapToolbars/ZoomBarV2/ZoomBarV2';
import { MapUndoDialog } from './MapUndoDialog';
import ReassignHierarchiesDialog from './PickTerritoryDialog/ReassignHierarchiesDialog';
import PinSetDialog from './PinSetDialog/PinSetDialog';
import { getInteractivePolygonLayers } from './PolygonLayerHelpers';
import SegmentEditorDialog from './SegmentEditorDialog/SegmentEditorDialog';
import DeleteSegmentDialog from './TerritoryMapGrid/DeleteSegmentDialog';
import { TerritoryMapGridV2 } from './TerritoryMapGridV2';
import style from './TerritoryMapV3.module.pcss';
import UnassignFromTerritoryDialog from './UnassignFromTerritoryDialog';
import { useTransformMapboxRequest } from './useTransformMapboxRequest';

const b = block(style);
const mapElementId = `territory-map`;

const defaultAccountFilter = {
  territory: AccountTerritoryFilterOptions.ALL_TERRITORIES,
  override: AccountTypeFilterOptions.BASE_AND_OVERRIDES
};

const allActionsDisabled: Record<MapActions, false> = {
  Create: false,
  Delete: false,
  FixConflict: false,
  Reassign: false,
  Unassign: false,
  Undo: false,
  Isolate: false,
  Ignore: false
};

export type TerritoryMapProps = {
  source?: SourceInput;
};

interface CoinsortObserverProps {
  onFinish: () => void;
}

const distanceUnitStorage = createScopedLocalStorage<LineRulerUnit>(LINE_RULER_DISTANCE_UNIT_STORAGE_KEY);

const CoinsortObserver: React.FC<CoinsortObserverProps> = ({ onFinish }) => {
  const { coinsortRunningLookupMap } = useCoinsort();
  const [isCoinsortRunning, setIsCoinsortRunning] = useState(false);
  const { selectedBattleCard } = useMapContextRedistributor();

  useEffect(() => {
    if (!coinsortRunningLookupMap?.[selectedBattleCard?.battlecardId.toString()] && isCoinsortRunning) {
      onFinish();
      setIsCoinsortRunning(false);
    }
  }, [coinsortRunningLookupMap?.[selectedBattleCard?.battlecardId.toString()], isCoinsortRunning]);

  useEffect(() => {
    if (coinsortRunningLookupMap?.[selectedBattleCard?.battlecardId.toString()]) {
      setIsCoinsortRunning(true);
    }
  }, [coinsortRunningLookupMap[selectedBattleCard?.battlecardId.toString()]]);

  return null;
};

const TerritoryMap: React.FC<TerritoryMapProps> = ({ source }) => {
  const territoryMapElementRef = useRef<HTMLDivElement | null>(null);
  const [mapboxMap, setMapboxMap] = useState<MapboxMap | null>(null);

  // Contexts
  const { selectedBattleCard, isBattlecardDataLoading, isMapPointerDisabled, primaryHierarchy, externalControlSuffix } =
    useMapContextRedistributor();
  const {
    hierarchyBounds,
    isColoringByTerritoryGroup,
    customerVisuals,
    setCustomerVisuals,
    setIsExactCustomHierarchyFilterActive,
    territoryGroupLevel,
    setIsColoringByTerritoryGroup,
    segmentEditorDialogOptions,
    setSegmentEditorDialogOptions
  } = useDedicatedMapProvider();
  const {
    insightsMeasureId,
    isCompareModeDialogOpen,
    setIsCompareModeDialogOpen,
    setIsMapLoading: setIsMapLoadingBarVisible,
    isMultiMapOpen
  } = useMapVariant();

  useEffect(() => {
    reportAnalyticsAction('openMap');
  }, []);

  const isSelectionEnabled = useIsMapCapable(MapCapability.SELECT);
  const isMapActionMenuOn = useIsMapCapable(MapCapability.VIEW_ACTIONS_MENU);
  const showToolbar = useIsMapCapable(MapCapability.TOOLBAR); // Todo TQP-12287 Rename

  // Worker Managed State
  const workerManagedState = useWorkerManagedState();
  const {
    sourceGroups,
    selectedGeoIds,
    selectedAccounts,
    selectedRuleIds,
    polygonNameMap,
    geographyNameMap,
    hierarchySelectionDetails,
    isUndoAvailable,
    customHierarchies,
    customHierarchyFilter,
    rules,
    isLoadingRules,
    lockedRuleIds,
    ignoredRuleIds,
    isAllIsolatedTerritoriesSelected,
    segments
  } = workerManagedState;

  const { loading: isFetchingRules, refetch: refetchRules } = useMapRules({ source }, isLoadingRules);

  const [actionMenu, setActionMenu] = useState<MapContextMenuInfo | null>(null);
  const [actionDialog, setActionDialog] = useState<MapActions | null>(null);

  const [polygonTooltip, setPolygonTooltip] = useState<PolygonTooltip | null>(null);
  const [accountTooltip, setAccountTooltip] = useState<CustomerTooltip | null>(null);

  const [lineRulerUnit, setLineRulerUnit] = useState<LineRulerUnit>(() => distanceUnitStorage.read() || 'kilometers');

  const [selectionTarget, setSelectionTarget] = useState<MapSelectionTarget>(
    primaryHierarchy === HierarchyType.CustomerAccountHierarchy
      ? MapSelectionTarget.accounts
      : MapSelectionTarget.polygons
  );
  const isInteractingWithPins =
    selectionTarget === MapSelectionTarget.accounts || primaryHierarchy === HierarchyType.CustomerAccountHierarchy;

  const [lassoSelectionRestriction, setLassoSelectionRestriction] = useState(MapSelectionRestriction.all);
  const [selectionTool, setSelectionTool] = useState(MapSelectionTool.togglePointer);
  const selectionActions = useMapSelectionActions(selectionTarget, selectionTool, lassoSelectionRestriction);
  const showToast = useShowToast();
  useHighwaysHighlighting(mapboxMap, sourceGroups);

  const {
    addLinePoint,
    lineCoordinates,
    handleDeleteMeasureLine,
    lineRulerIndicatorPosX,
    lineRulerIndicatorPosY,
    lineDistance
  } = useLineRulerTool(lineRulerUnit);
  const [accountFilter, setAccountFilter] = useState<AccountFilter>(defaultAccountFilter);

  useExposeExternalUIControl(`mapSelectionActions${externalControlSuffix}`, selectionActions);

  const { isLoadingSources, onSourceData, onStyleData } = useIsLoadingSources(sourceGroups);
  useApplyFeatureState(mapboxMap, isLoadingSources);
  useSelectionNotificationToasts();

  const interactiveLayerIds = useMemo(() => {
    if (isInteractingWithPins) {
      return customerVisuals === CustomerVisualization.CLUSTER ? [LayerIds.account.cluster.interactiveCircle] : [];
    }
    return getInteractivePolygonLayers(sourceGroups);
  }, [sourceGroups, isInteractingWithPins, customerVisuals]);

  const handleLassoComplete = useCallback(
    async (lassoPolygon: LassoPolygon) => {
      if (!mapboxMap) return;
      if (!isSelectionEnabled) return;

      if (isInteractingWithPins) {
        if (customerVisuals !== CustomerVisualization.CLUSTER) return;

        const accountIds = accountSourceLayersRef.current
          ? [...accountSourceLayersRef.current.lasso(lassoPolygon)]
          : await lassoPinsAndClusters(lassoPolygon, SourceIds.account.cluster, mapboxMap);
        selectionActions.lassoPins(accountIds);
      } else {
        const featuresInBounds = queryFeaturesInBounds(mapboxMap, lassoPolygon, interactiveLayerIds);
        selectionActions.lassoPolygons(lassoPolygon, featuresInBounds);
      }
    },
    [
      mapboxMap,
      interactiveLayerIds,
      isSelectionEnabled,
      customerVisuals,
      isInteractingWithPins,
      selectionActions.lassoPolygons,
      selectionActions.lassoPins
    ]
  );

  const onFinishCoinsort = () => {
    refetchRules(RuleChangeCauses.Coinsort);
    refetchAllCustomerPins();
  };

  const canStartLasso = isSelectionEnabled && selectionTool && isLassoTool(selectionTool);
  const { isDrawing, cancelLasso } = useMapDraw(mapboxMap, canStartLasso, handleLassoComplete);

  const activeInteractiveLayerIds = useMemo(
    () => (isDrawing ? [] : interactiveLayerIds),
    [isDrawing, interactiveLayerIds]
  );

  const [isHighlightHighwaysOn] = useTreatment(SplitFeatures.MAP_HIGHWAY_HIGHLIGHTING);

  const mappableTerritoryRules = useMemo(() => rules.filter((rule) => rule.isMappable), [rules]);
  const editableRuleIds = useMemo(() => {
    return mappableTerritoryRules
      .filter((rule) => rule.isPassingFilter && !lockedRuleIds.has(rule.ruleId) && !ignoredRuleIds.has(rule.ruleId))
      .map((rule) => rule.ruleId);
  }, [mappableTerritoryRules, lockedRuleIds, ignoredRuleIds]);

  const {
    answer: fixConflictDetails,
    ask: getFixConflictDetails,
    isLoading: isLoadingFixConflictDetails
  } = useMapQuestion('get-fix-conflict-details');

  const hasSelection = selectedGeoIds.length > 0 || selectedRuleIds.length > 0 || selectedAccounts.length > 0;
  const possibleMapActions = useMemo((): PossibleMapActions => {
    if (selectionTarget === MapSelectionTarget.territories) {
      const singleSelectedRuleId = selectedRuleIds.length === 1 ? selectedRuleIds[0] : null;
      const selectedRule = mappableTerritoryRules.find((rule) => rule.ruleId === singleSelectedRuleId);
      return {
        ...allActionsDisabled,
        FixConflict: !!singleSelectedRuleId && selectedRule.isOverAssigned,
        Delete: selectedRuleIds.length > 0,
        Isolate: selectedRuleIds.length > 0,
        Undo: isUndoAvailable,
        Ignore: selectedRuleIds.length > 0
      };
    }
    if (!hierarchySelectionDetails) return { ...allActionsDisabled, Undo: isUndoAvailable };

    const { isOnlyUnassigned, atLeastOneModifier } = hierarchySelectionDetails;
    const isTargetingDimensions =
      primaryHierarchy === HierarchyType.CustomerAccountHierarchy || selectionTarget === MapSelectionTarget.polygons;
    return {
      ...allActionsDisabled,
      Create: isOnlyUnassigned,
      Unassign: !isOnlyUnassigned && (isTargetingDimensions || atLeastOneModifier),
      Reassign: mappableTerritoryRules.length > 0,
      Isolate: !isOnlyUnassigned,
      Undo: isUndoAvailable
    };
  }, [
    selectionTarget,
    selectedRuleIds,
    hierarchySelectionDetails,
    mappableTerritoryRules,
    isUndoAvailable,
    primaryHierarchy,
    hasSelection
  ]);

  const handleCloseClusterList = useCallback(
    () =>
      setActionMenu((currentMenu) =>
        currentMenu?.type === MapActionMenuEnum.IndivisibleClusterBreakdown ? null : currentMenu
      ),
    []
  );

  useEffect(() => {
    if (actionDialog !== MapActions.FixConflict) return;

    getFixConflictDetails();
  }, [actionDialog]);

  useEffect(() => {
    const shouldExactFilterBeActive =
      customHierarchyFilter.kind === CollectionFilterKind.EQUALS && customHierarchyFilter.ids.length > 0;

    setIsExactCustomHierarchyFilterActive(shouldExactFilterBeActive);
  }, [customHierarchyFilter]);

  useEffect(() => {
    setActionMenu((currentMenu) =>
      currentMenu?.type === MapActionMenuEnum.IndivisibleClusterBreakdown ? currentMenu : null
    );
  }, [selectedRuleIds, selectedGeoIds, selectedAccounts, actionDialog]);
  useEffect(() => {
    handleCloseClusterList();
  }, [lockedRuleIds, ignoredRuleIds, accountFilter, customerVisuals, rules, selectionTarget, primaryHierarchy]);

  useEffect(() => {
    setAccountFilter(defaultAccountFilter);
    if (selectionTarget === MapSelectionTarget.accounts) {
      setCustomerVisuals((oldVisuals) =>
        oldVisuals === CustomerVisualization.HIDE ? CustomerVisualization.CLUSTER : oldVisuals
      );
    }
  }, [selectionTarget]);

  useEffect(() => {
    if (primaryHierarchy === HierarchyType.CustomerAccountHierarchy) {
      setSelectionTarget(MapSelectionTarget.accounts);
    }
  }, [primaryHierarchy]);

  useEffect(() => {
    if (!isSelectionEnabled) {
      selectionActions.clearSelection();
    }
  }, [isSelectionEnabled]);

  useEffect(() => {
    if (!mapboxMap) return;
    const mapZoomToastText = getMapZoomToastText(sourceGroups, mapboxMap.getZoom());
    if (mapZoomToastText) showToast(mapZoomToastText, 'primary');
  }, [sourceGroups, mapboxMap]);

  useHideInactiveCountryLabels(mapboxMap, sourceGroups);

  useSegments();

  const [showPinSetDialog, setShowPinSetDialog] = useState(false);
  const togglePinSetDialog = () => setShowPinSetDialog((show) => !show);
  const { availablePinSets, isPinSetsLoading } = useGetAvailablePinSets();
  const { pinFeatures: customPinFeatures, loading: isLoadingCustomPins } = useCustomPins(availablePinSets);
  const {
    customerPins: customerPinsForWorker,
    loading: isLoadingCustomerPins,
    refetchAllPins: refetchAllCustomerPins,
    totalPins
  } = useCustomerAggregatesFeatures(insightsMeasureId);
  useSendCustomerPinsToWorker(customerPinsForWorker);
  const showAccountMapWarningDialog = useShouldShowAccountMapWarning(totalPins);

  const isCustomerAccountsLoading = customerVisuals !== CustomerVisualization.HIDE && isLoadingCustomerPins;

  const isMapLoading =
    mapboxMap === null ||
    isFetchingRules ||
    isBattlecardDataLoading ||
    isCustomerAccountsLoading ||
    workerManagedState.isLoadingGeoHierarchy ||
    workerManagedState.isLoadingRules ||
    workerManagedState.isLoadingSelection ||
    workerManagedState.isLoadingCustomerPins;

  useExposeExternalUIControl(`isMapLoading${externalControlSuffix}`, isMapLoading);
  useEffect(() => {
    setIsMapLoadingBarVisible(isMapLoading);
  }, [isMapLoading]);

  const { sameLocationClusterToastText } = useDynamicMapHierarchyCopy();

  const handlePressEscape = useCallback(() => {
    if (actionDialog) {
      return;
    }
    if (isDrawing) {
      cancelLasso();
    } else if (actionMenu) {
      setActionMenu(null);
    } else if (isLineRulerTool(selectionTool) && lineCoordinates.length > 0) {
      handleDeleteMeasureLine();
    } else {
      selectionActions.clearSelection();
    }
  }, [actionDialog, actionMenu, isDrawing, selectionActions.clearSelection, selectionTool, lineCoordinates]);
  useKeyDown('Escape', handlePressEscape);

  const handleHoverAccount = useCallback(
    (event: MapHoverEvent) => {
      const { features } = event;
      const interactiveFeature = features?.find((feature) => interactiveLayerIds.includes(feature.layer?.id));
      if (!interactiveFeature || isDrawing) {
        setAccountTooltip(null);
        return;
      }

      const formattedAccountTooltip = formatAccountTooltip(interactiveFeature);
      setAccountTooltip({
        ...formattedAccountTooltip,
        x: event.lngLat.lng,
        y: event.lngLat.lat
      });
    },
    [interactiveLayerIds, isDrawing]
  );

  const handleHoverPolygon = useCallback(
    (event: MapHoverEvent) => {
      const { features } = event;
      const interactiveFeature = features?.find((feature) => interactiveLayerIds.includes(feature.layer?.id));
      if (!interactiveFeature || isDrawing) {
        setPolygonTooltip(null);
        return;
      }

      const state = interactiveFeature.state;
      if (ignoredRuleIds.has(state?.ruleId)) {
        setPolygonTooltip(null);
        return;
      }

      const polygonId = interactiveFeature.id ? optimizeNativePolygonIdForHashing(interactiveFeature.id) : null;
      const polygonName = polygonNameMap.get(polygonId);

      if (!polygonId || !state || !polygonName) {
        setPolygonTooltip(null);
      } else {
        setPolygonTooltip({
          ...formatGeoTooltip(interactiveFeature),
          polygonId,
          polygonName,
          x: event.lngLat.lng,
          y: event.lngLat.lat
        });
      }
    },
    [polygonNameMap, interactiveLayerIds, isDrawing, ignoredRuleIds]
  );

  const handleMapHover = isInteractingWithPins ? handleHoverAccount : handleHoverPolygon;

  useExposeHoverControls({ mapboxMap, interactiveLayerIds, handleHoverPolygon, handleHoverAccount });

  const handleBreakdownClusterLegacy = useCallback(
    async (feature: ClusterFeature) => {
      const source = mapboxMap.getSource(feature.source);
      if (source?.type !== 'geojson') return;
      const { flyTo } = await breakdownCluster(source, feature);
      if (flyTo) {
        mapboxMap.flyTo(flyTo);
      } else {
        showToast(
          <ToastMessage title={formatMessage('MULTIPLE_ACCOUNTS_TITLE')} message={sameLocationClusterToastText} />,
          'primary'
        );
      }
    },
    [mapboxMap]
  );

  const handleBreakdownCluster = useCallback(
    (feature: GeoJSON.Feature<GeoJSON.Point, { clusterId: string; isClusterDivisible: boolean }>) => {
      if (!accountSourceLayersRef.current) return;

      if (feature.properties.isClusterDivisible) {
        accountSourceLayersRef.current.breakdownCluster(feature);
      } else {
        const [x, y] = getCoordinatesFromPoint(feature);
        setActionMenu({
          x,
          y,
          type: MapActionMenuEnum.IndivisibleClusterBreakdown,
          accounts: accountSourceLayersRef.current.getLeaves(feature.properties.clusterId)
        });
      }
    },
    []
  );

  const handleMapClick = useCallback(
    (e) => {
      setActionMenu(null);

      if (isLineRulerTool(selectionTool)) {
        addLinePoint(e);
        return;
      }
      if (isLassoTool(selectionTool)) return;

      const interactiveFeature = e.features?.find((feature) => interactiveLayerIds.includes(feature.layer?.id));
      if (!interactiveFeature) return;

      if (interactiveFeature.properties.clusterId) {
        handleBreakdownCluster(interactiveFeature);
        return;
      }
      if (interactiveFeature.properties?.cluster_id) {
        handleBreakdownClusterLegacy(interactiveFeature);
        return;
      }
      if (!isSelectionEnabled) return;
      if (!interactiveFeature?.id) return;

      // Actually perform the selection
      if (isInteractingWithPins) {
        selectionActions.clickPin(interactiveFeature.properties.accountId);
      } else {
        selectionActions.clickPolygon(optimizeNativePolygonIdForHashing(interactiveFeature.id));
      }
    },
    [
      selectionTool,
      interactiveLayerIds,
      isInteractingWithPins,
      isSelectionEnabled,
      handleBreakdownCluster,
      handleBreakdownClusterLegacy,
      selectionActions.clickPolygon,
      selectionActions.clickPin,
      lineCoordinates,
      addLinePoint
    ]
  );

  const handleContextMenu = useCallback(
    (e: MapLayerMouseEvent) => {
      e.preventDefault();
      cancelLasso();
      const x = e.lngLat.lng;
      const y = e.lngLat.lat;
      setActionMenu({ type: MapActionMenuEnum.Context, x, y });
    },
    [cancelLasso]
  );

  const handleChooseSelectionTarget = useCallback(
    (newTarget: MapSelectionTarget) => {
      if (newTarget === selectionTarget) return;
      selectionActions.clearSelection();
      setSelectionTarget(newTarget);
    },
    [selectionTarget, selectionActions.clearSelection]
  );

  const handleChooseSelectionTool = (newTool: MapSelectionTool) => {
    if (newTool === selectionTool) return;
    if (!isSelectionEnabled) return;
    cancelLasso();
    setSelectionTool(newTool);
  };

  const handleGridSelection = useHandleGridSelection(setSelectionTarget);

  const handleDialogClose = () => {
    setActionDialog(null);
  };

  const actionMenuProps: MapActionMenuProps = {
    onClickAction: setActionDialog,
    possibleMapActions,
    isMapLoading,
    selectionTarget,
    isMultipleRulesSelected: selectedRuleIds.length > 1,
    isAllIsolatedTerritoriesSelected,
    isLoading: isMapLoading || isLoadingFixConflictDetails,
    selectedRuleIds
  };

  const [insightsState, setInsightsState] = useState<InsightsCardState>(InsightsCardState.None);
  useEffect(() => {
    setInsightsState((prevState) => {
      if (hasSelection) {
        return prevState === InsightsCardState.None ? InsightsCardState.Summary : prevState;
      } else {
        return InsightsCardState.None;
      }
    });
  }, [hasSelection]);

  const languageSuffix = useLanguageSuffix();
  useSetMapLabelLanguage(mapboxMap, languageSuffix);

  const isAccountPointsFilterEnabled = useIsMapCapable(MapCapability.ACCOUNT_POINTS_FILTER);
  const initialViewState = useInitialViewState(hierarchyBounds);
  const isSmallVariant = useObserveMapSize(territoryMapElementRef) !== MapWidth.LARGE;
  const [shouldSkipMapProxy] = useTreatment(SplitFeatures.SKIP_MAP_PROXY);

  const transformMapboxRequest = useTransformMapboxRequest();

  const onRecolorFinish = useCallback(() => refetchRules(RuleChangeCauses.Recolor), [refetchRules]);

  const onChangeLineUnit = (unit: LineRulerUnit) => {
    setLineRulerUnit(unit);
    distanceUnitStorage.write(unit);
  };

  const accountSourceLayersRef = useRef<GroveHelpersClusterRefHandle>();

  return (
    <div
      id={mapElementId}
      ref={territoryMapElementRef}
      data-testid="territory-map"
      className={b('territoryMap', { isMapPointerDisabled })}
    >
      {transformMapboxRequest || shouldSkipMapProxy ? (
        <DefaultMapboxMap
          initialViewState={initialViewState}
          onMouseMove={isLoadingSources ? undefined : handleMapHover}
          onClick={handleMapClick}
          onContextMenu={isMapActionMenuOn && !isDrawing ? handleContextMenu : undefined}
          cursor={isLassoTool(selectionTool) ? LASSO_CURSOR : SELECT_POINT_CURSOR}
          interactiveLayerIds={isLoadingSources ? undefined : activeInteractiveLayerIds}
          transformRequest={shouldSkipMapProxy ? undefined : transformMapboxRequest}
          onSourceData={onSourceData}
          onStyleData={onStyleData}
          onMouseLeave={() => {
            setPolygonTooltip(null);
            setAccountTooltip(null);
          }}
          onLoad={(event) => {
            const map = event.target;
            loadMapImages(map);
            setMapboxMap(map);
          }}
        >
          <div className={b('mapWidgets')}>
            <div className={b('controlContainer', { gutter: true })}>
              <Gutter
                insightsState={insightsState}
                onChangeInsightsState={setInsightsState}
                gridContent={
                  <TerritoryMapGridV2
                    territoryRules={rules}
                    loading={isFetchingRules}
                    selectedRuleIds={selectedRuleIds}
                    lockedRuleIds={workerManagedState.lockedRuleIds}
                    ignoredRuleIds={workerManagedState.ignoredRuleIds}
                    isSelectionEnabled={isSelectionEnabled && !isDrawing}
                    customHierarchyFilter={customHierarchyFilter}
                    customHierarchies={customHierarchies}
                    onSelectRules={handleGridSelection}
                    onRecolorFinish={onRecolorFinish}
                    segments={segments}
                  />
                }
                insightsContent={
                  <>
                    {hasSelection && (
                      <InsightsCard
                        selectionTarget={selectionTarget}
                        selectedGeoIds={selectedGeoIds}
                        currency={selectedBattleCard.localCurrencyCode}
                        showActivityStats={
                          primaryHierarchy === HierarchyType.CustomerAccountHierarchy ||
                          selectedBattleCard?.files?.length > 0
                        }
                        mappableTerritoryRules={mappableTerritoryRules}
                        selectedRuleIds={selectedRuleIds}
                        customerInsights={workerManagedState.customers}
                        geographyNameMap={geographyNameMap}
                        selectedAccountIds={selectedAccounts}
                        onExpandStat={(isExpanded) =>
                          setInsightsState(isExpanded ? InsightsCardState.Table : InsightsCardState.Summary)
                        }
                      />
                    )}
                  </>
                }
              />
            </div>

            <div className={b('controlContainer', { topLeft: true })}>
              {isColoringByTerritoryGroup && (
                <div className={b('colorByTerritoryTag')}>
                  <Tag intent={Intent.PRIMARY} onRemove={() => setIsColoringByTerritoryGroup(false)} large>
                    {formatMessage('COLOR_BY_TERRITORY_GROUP_PILL_MESSAGE', {
                      level: getTerritoryGroupLevelName(territoryGroupLevel)
                    })}
                  </Tag>
                </div>
              )}
              {showToolbar && (
                <div className={b('bar')}>
                  <SelectionTargetDropdownV2
                    value={selectionTarget}
                    isSmallVariant={isSmallVariant}
                    onChange={handleChooseSelectionTarget}
                  />
                  <SelectionToolSettingsBarV2
                    selectedTool={selectionTool}
                    lassoSelectionRestriction={lassoSelectionRestriction}
                    selectionTarget={selectionTarget}
                    isDrawing={isDrawing}
                    disabled={!mapboxMap}
                    onChooseSelectionTool={handleChooseSelectionTool}
                    onChooseLassoRestriction={setLassoSelectionRestriction}
                    onCancelLasso={cancelLasso}
                  />
                  {isHighlightHighwaysOn && (
                    <LineRulerButton
                      onSelectLineRulerTool={handleChooseSelectionTool}
                      selectionTool={selectionTool}
                      disabled={!mapboxMap}
                    />
                  )}
                </div>
              )}
            </div>

            <div className={b('controlContainer', { topRight: true })}>
              <div className={b('bar')}>
                {isMapActionMenuOn && <ActionBarContainerV2 {...actionMenuProps} isSmallVariant={isSmallVariant} />}

                {(isMapActionMenuOn || hasSelection) && (
                  <ClearSelectionButtonV2 onDeselect={selectionActions.clearSelection} disabled={!hasSelection} />
                )}
              </div>
            </div>

            <div className={b('controlContainer', { bottom: true })}>
              <MapboxAttribution />
              <div className={b('barGroup')}>
                {isHighlightHighwaysOn && (
                  <div className={b('bar', { noFlex: true })}>
                    <LayersToggle isMapLoaded={!!mapboxMap} />
                  </div>
                )}

                <div className={b('bar')}>
                  {isAccountPointsFilterEnabled && (
                    <AccountFilterMenuV2
                      filterOptions={accountFilter}
                      onFilterOptionsChange={(filterPatch) => {
                        setAccountFilter((oldFilter) => ({ ...oldFilter, ...filterPatch }));
                      }}
                      shouldShowTerritoryFilterOptions={selectionTarget === MapSelectionTarget.territories}
                      isSmallVariant={isSmallVariant}
                    />
                  )}
                  {showToolbar && (
                    <PinsButtonV2
                      onAddPinSetClick={togglePinSetDialog}
                      pinSets={availablePinSets}
                      loadingCustomPins={isLoadingCustomPins}
                      loadingPinSets={isPinSetsLoading}
                      loadingCustomerFeatures={isLoadingCustomerPins}
                      isMapLoaded={!!mapboxMap}
                    />
                  )}
                </div>
                <div className={b('bar')}>
                  <ZoomBarV2 mapboxMap={mapboxMap} />
                </div>
              </div>
            </div>
          </div>

          <div data-testid="pointer-menus">
            {!actionMenu && !isDrawing && polygonTooltip && (
              <MapTooltip
                polygonTooltip={polygonTooltip}
                selectionTarget={selectionTarget}
                showTgDetails={isColoringByTerritoryGroup}
                lockedRuleIds={lockedRuleIds}
              />
            )}
            {!actionMenu && !isDrawing && accountTooltip && (
              <AccountTooltip
                accountTooltip={accountTooltip}
                currency={selectedBattleCard?.localCurrencyCode}
                lockedRuleIds={lockedRuleIds}
              />
            )}

            {actionMenu?.type === MapActionMenuEnum.Context && (
              <div data-testid="context-menu">
                <Popup
                  className={b('mapContextMenu')}
                  closeButton={false}
                  longitude={actionMenu.x}
                  latitude={actionMenu.y}
                >
                  <MapActionMenu {...actionMenuProps} />
                </Popup>
              </div>
            )}

            {actionMenu?.type === MapActionMenuEnum.IndivisibleClusterBreakdown && (
              <Popup
                className={b('mapContextMenu')}
                closeButton={false}
                longitude={actionMenu.x}
                latitude={actionMenu.y}
                offset={20}
              >
                <IndivisibleClusterList
                  accounts={actionMenu.accounts}
                  selectionTarget={selectionTarget}
                  onClickAccount={selectionActions.clickPin}
                  onClose={handleCloseClusterList}
                />
              </Popup>
            )}

            {lineDistance > 0 && (
              <Popup
                className={b('lineRulerIndicator')}
                longitude={lineRulerIndicatorPosX}
                latitude={lineRulerIndicatorPosY}
                closeButton={false}
                closeOnClick={false}
                offset={[0, 45]}
              >
                <LineRulerDistanceIndicator
                  unit={lineRulerUnit}
                  lineDistance={lineDistance}
                  onDeleteMeasureLine={handleDeleteMeasureLine}
                  onChangeLineUnit={onChangeLineUnit}
                />
              </Popup>
            )}
          </div>

          <div data-testid="sources">
            <PolygonSourceLayers
              sourceGroups={sourceGroups}
              selectionTarget={selectionTarget}
              beforeId={isHighlightHighwaysOn ? 'road-minor-case' : 'poi-label'}
            />
            {customPinFeatures && <CustomPinSetSourceLayers mapPinFeatures={customPinFeatures} />}
            {customerVisuals !== CustomerVisualization.HIDE && !isColoringByTerritoryGroup && (
              <AccountSourceLayers
                ref={accountSourceLayersRef}
                visualizationType={customerVisuals}
                customers={workerManagedState.customers}
                ignoredRuleIds={workerManagedState.ignoredRuleIds}
                accountFilter={accountFilter}
                selectedRuleIds={selectedRuleIds}
              />
            )}
            {!!mapboxMap && <LineRuler lineStringCoordinates={lineCoordinates} />}
          </div>
        </DefaultMapboxMap>
      ) : (
        <div data-testid="map-auth-spinner" className={b('mapAuthSpinner')}>
          <Spinner intent="primary" />
        </div>
      )}

      {actionDialog === MapActions.Create && (
        <UpsertTerritoryRuleDialog
          onUpsertTerritory={() => refetchRules(RuleChangeCauses.Create)}
          onClose={handleDialogClose}
          data-testid="upsert-territory-rule-dialog"
          mappableTerritoryRules={mappableTerritoryRules}
        />
      )}
      {actionDialog === MapActions.Delete && (
        <DeleteTerritoryDialog
          onClose={handleDialogClose}
          data-testid="delete-territory-dialog"
          ruleIds={selectedRuleIds}
          onTerritoryDeleted={() => refetchRules(RuleChangeCauses.Delete)}
        />
      )}
      {actionDialog === MapActions.FixConflict && fixConflictDetails && (
        <FixConflictDialog
          onClose={handleDialogClose}
          data-testid="fix-conflict-territory-dialog"
          fixConflictDetails={fixConflictDetails}
          onTerritoryUpdated={() => refetchRules(RuleChangeCauses.FixConflict)}
        />
      )}
      {actionDialog === MapActions.Reassign && selectionTarget === MapSelectionTarget.accounts && (
        <ReassignHierarchiesDialog
          targetHierarchyType={HierarchyType.CustomerAccountHierarchy}
          hierarchyIds={selectedAccounts}
          mappableTerritoryRules={mappableTerritoryRules}
          onTerritoryUpdated={() => refetchRules(RuleChangeCauses.Reassign)}
          onClose={handleDialogClose}
          lockedRuleIds={workerManagedState.lockedRuleIds}
          editableRuleIds={editableRuleIds}
        />
      )}
      {actionDialog === MapActions.Reassign && selectionTarget === MapSelectionTarget.polygons && (
        <ReassignHierarchiesDialog
          targetHierarchyType={HierarchyType.GeographicTerritoryHierarchy}
          hierarchyIds={selectedGeoIds}
          mappableTerritoryRules={mappableTerritoryRules}
          onTerritoryUpdated={() => refetchRules(RuleChangeCauses.Reassign)}
          onClose={handleDialogClose}
          lockedRuleIds={workerManagedState.lockedRuleIds}
          editableRuleIds={editableRuleIds}
        />
      )}
      {actionDialog === MapActions.Unassign && selectionTarget === MapSelectionTarget.accounts && (
        <UnassignFromTerritoryDialog
          onClose={handleDialogClose}
          data-testid="remove-from-territory-dialog"
          hierarchyIds={selectedAccounts}
          onTerritoryUpdated={() => refetchRules(RuleChangeCauses.Unassign)}
          targetHierarchyType={HierarchyType.CustomerAccountHierarchy}
          editableRuleIds={editableRuleIds}
        />
      )}
      {actionDialog === MapActions.Unassign && selectionTarget === MapSelectionTarget.polygons && (
        <UnassignFromTerritoryDialog
          onClose={handleDialogClose}
          data-testid="remove-from-territory-dialog"
          hierarchyIds={selectedGeoIds}
          onTerritoryUpdated={() => refetchRules(RuleChangeCauses.Unassign)}
          targetHierarchyType={HierarchyType.GeographicTerritoryHierarchy}
          editableRuleIds={editableRuleIds}
        />
      )}
      {actionDialog === MapActions.Undo && (
        <MapUndoDialog
          onClose={handleDialogClose}
          onTerritoryUpdated={() => refetchRules(RuleChangeCauses.FinishUndo)}
        />
      )}

      {(segmentEditorDialogOptions.mode === SegmentEditorDialogModes.EDIT ||
        segmentEditorDialogOptions.mode === SegmentEditorDialogModes.CREATE) && (
        <SegmentEditorDialog
          {...segmentEditorDialogOptions}
          availableHierarchies={customHierarchies}
          onClose={() => setSegmentEditorDialogOptions({ mode: SegmentEditorDialogModes.CLOSED })}
        />
      )}
      {segmentEditorDialogOptions.mode === SegmentEditorDialogModes.DELETE && (
        <DeleteSegmentDialog
          segmentId={segmentEditorDialogOptions.segmentId}
          onClose={() => setSegmentEditorDialogOptions({ mode: SegmentEditorDialogModes.CLOSED })}
        />
      )}

      {showPinSetDialog && (
        <PinSetDialog onClose={togglePinSetDialog} data-testid="pin-set-dialog" pinSets={availablePinSets} />
      )}
      {isCompareModeDialogOpen && (
        <CompareModeDialog data-testid="compare-mode-dialog" onClose={() => setIsCompareModeDialogOpen(false)} />
      )}
      {isMultiMapOpen && <CoinsortObserver onFinish={onFinishCoinsort} />}

      {showAccountMapWarningDialog && <AccountMapWarningDialog />}
    </div>
  );
};

export default TerritoryMap;

const useSendCustomerPinsToWorker = (customerPins: AggregatePin[]) => {
  const postMessage = useMapWorkerPostMessage();
  useEffect(() => {
    postMessage({
      type: 'customer-pins-change',
      customerPins
    });
  }, [customerPins]);
};

const useHandleGridSelection = (onSelectionTargetChanged: (target: MapSelectionTarget) => void) => {
  const postMessage = useMapWorkerPostMessage();
  return useCallback(
    (selectedRuleIds: number[]) => {
      postMessage({ type: 'set-selected-rules', ruleIds: selectedRuleIds });
      if (selectedRuleIds.length) onSelectionTargetChanged(MapSelectionTarget.territories);
    },
    [postMessage]
  );
};
