import React from 'react';

import { IClientSideRowModel, IHeaderParams } from '@ag-grid-community/core';
import { Locked, Unlocked, View, ViewOff } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { formatMessage } from 'utils/messages/utils';

function AggregateHeader<T>({
  api,
  column,
  render
}: IHeaderParams & { render: (value: T) => JSX.Element }): JSX.Element | null {
  const rowModel = api.getModel();
  if (rowModel.getType() !== 'clientSide') return null;

  const rootAggData = (rowModel as IClientSideRowModel).getRootNode()?.aggData;
  if (!rootAggData) return null;

  const field = column.getColDef().field;
  if (!field) return null;

  return render(rootAggData[field]);
}

export const LockHeader = (props: IHeaderParams & { onClick: () => void }) => (
  <AggregateHeader<boolean>
    {...props}
    render={(areAllRulesLocked) => (
      <IconButton
        type="button"
        testId="map-lock-all-cell"
        icon={areAllRulesLocked ? <Locked /> : <Unlocked />}
        tooltipText={formatMessage(areAllRulesLocked ? `UNLOCK_ALL_TERRITORIES` : `LOCK_ALL_TERRITORIES`)}
        onClick={props.onClick}
      />
    )}
  />
);

export const IgnoreHeader = (props: IHeaderParams & { onClick: () => void }) => (
  <AggregateHeader<boolean>
    {...props}
    render={(areAllRulesIgnored) => (
      <IconButton
        type="button"
        testId="map-ignore-all-cell"
        icon={areAllRulesIgnored ? <ViewOff /> : <View />}
        tooltipText={formatMessage(areAllRulesIgnored ? `SHOW_ALL_TERRITORIES` : `HIDE_ALL_TERRITORIES`)}
        onClick={props.onClick}
      />
    )}
  />
);
