import KDBush from 'kdbush';

import { MapBounds } from 'app/models';

import { startPerformanceTimer } from 'utils/helpers/analyticsReporter';

import { getClosestZoomBreakpoint } from './helpers/territoryMapUtils';

type Point<PropertiesType = unknown> = {
  type: 'Feature';
  id: number | string;
  geometry: {
    type: 'Point';
    coordinates: number[];
  };
  properties: PropertiesType;
};

type IndividualPoint<PropertiesType> = Point<PropertiesType & { isCluster?: false | null }>;

type ReservedClusterProperties = {
  isCluster: true;
  clusterId: string;
  clusterZoom: number;
  isClusterDivisible: boolean;
  pointCount: number;
  pointCountAbbreviated: string;
};

type Cluster<PropertiesType> = Point<PropertiesType & ReservedClusterProperties>;

type PointOrCluster<IndividualPointProperties, ClusterProperties> =
  | IndividualPoint<IndividualPointProperties>
  | Cluster<ClusterProperties>;

type SelectedPointOrCluster<IndividualPointProperties, ClusterProperties> = PointOrCluster<
  IndividualPointProperties & { isSelected: true },
  ClusterProperties & { isSelected: true }
>;

interface GroveOptions<IndividualPointProperties, ClusterProperties> {
  radius: number;
  zoomLevels: number[];
  nodeSize: number;
  extent: number;
  reduce: (accumulator: Partial<ClusterProperties>, properties: IndividualPointProperties | ClusterProperties) => void;
}

export class Grove<I, C> {
  private static readonly defaultOptions: GroveOptions<unknown, unknown> = {
    zoomLevels: new Array(16).fill(0).map((_, i) => i),
    radius: 40,
    nodeSize: 64,
    extent: 512,
    reduce: () => {
      // No-op
    }
  };

  private readonly options: GroveOptions<I, C>;
  private readonly bushes = new Map<number, KDBush<PointOrCluster<I, C>>>();

  private readonly canopies = new Map<number, Array<PointOrCluster<I, C>>>();
  private readonly parentByNode = new Map<PointOrCluster<I, C>, Cluster<C>>();
  private readonly childrenByNode = new Map<PointOrCluster<I, C>, PointOrCluster<I, C>[]>();
  private readonly nodesById = new Map<string | number, PointOrCluster<I, C>>();

  private readonly selectedLeafIds = new Set<number | string>();

  private readonly selectedCountByNode = new Map<Cluster<C>, number>();

  constructor(options: Partial<GroveOptions<I, C>>) {
    this.options = { ...Grove.defaultOptions, ...options };
    this.load([]);
  }

  private clear() {
    this.bushes.clear();
    this.canopies.clear();
    this.parentByNode.clear();
    this.childrenByNode.clear();
    this.nodesById.clear();
    this.selectedCountByNode.clear();
  }

  public load(individualPoints: Array<IndividualPoint<I>>) {
    this.clear();
    const timer = startPerformanceTimer('loading data for grove', 50 * this.options.zoomLevels.length);

    const maxZoomLevel = this.options.zoomLevels[this.options.zoomLevels.length - 1];

    const atomicFeatures = this.aggregateAtomicClusters(individualPoints, maxZoomLevel);

    let currentBush = this.createBush(atomicFeatures);
    let currentCanopy = atomicFeatures;
    this.bushes.set(maxZoomLevel, currentBush);
    this.canopies.set(maxZoomLevel, currentCanopy);

    const otherZoomLevels = this.options.zoomLevels.slice(0, -1).reverse();
    for (const zoom of otherZoomLevels) {
      const clusters = new Array<PointOrCluster<I, C>>();
      const bushRadius = this.calculateBushRadius(zoom);

      for (let nucleusIndex = 0; nucleusIndex < currentCanopy.length; nucleusIndex++) {
        const nucleus = currentCanopy[nucleusIndex];
        if (this.parentByNode.has(nucleus)) continue;

        const clusterId = `${zoom}::${nucleusIndex}`;
        const clusterScaffold: Cluster<C> = {
          type: 'Feature',
          id: clusterId,
          geometry: {
            type: 'Point',
            coordinates: [0, 0]
          },
          properties: {
            isCluster: true,
            clusterId,
            clusterZoom: zoom,
            isClusterDivisible: true,
            pointCount: 0,
            pointCountAbbreviated: '0'
            // This does not yet have C, but gets it on the next line
          } satisfies ReservedClusterProperties as C & ReservedClusterProperties
        };
        this.options.reduce(clusterScaffold.properties, nucleus.properties);

        const x = Grove.getXFromPoint(nucleus);
        const y = Grove.getYFromPoint(nucleus);
        const originalCount = Grove.isCluster(nucleus) ? nucleus.properties.pointCount : 1;

        let selectedCount = this.getExistingSelectedCount(nucleus);

        let xSum = x * originalCount;
        let ySum = y * originalCount;

        const children = [nucleus];
        let count = originalCount;
        const neighborIndexes = currentBush.within(x, y, bushRadius);
        for (const neighborIndex of neighborIndexes) {
          if (neighborIndex === nucleusIndex) continue;
          const neighbor = currentCanopy[neighborIndex];
          if (this.parentByNode.has(neighbor)) continue;

          const neighborCount = Grove.isCluster(neighbor) ? neighbor.properties.pointCount : 1;
          count += neighborCount;
          xSum += neighborCount * Grove.getXFromPoint(neighbor);
          ySum += neighborCount * Grove.getYFromPoint(neighbor);
          this.options.reduce(clusterScaffold.properties, neighbor.properties);
          selectedCount += this.getExistingSelectedCount(neighbor);

          // Wire the child up to it's new parent
          children.push(neighbor);
          this.parentByNode.set(neighbor, clusterScaffold);
        }

        if (count === originalCount) {
          clusters.push(nucleus);
          continue;
        }

        this.selectedCountByNode.set(clusterScaffold, selectedCount);

        // Finalize cluster
        clusterScaffold.geometry.coordinates[0] = xToLongitude(xSum / count);
        clusterScaffold.geometry.coordinates[1] = yToLatitude(ySum / count);
        clusterScaffold.properties.pointCount = count;
        clusterScaffold.properties.pointCountAbbreviated = Grove.abbreviateCount(count);

        // Wire cluster into the Grove
        this.nodesById.set(clusterId, clusterScaffold);
        this.parentByNode.set(nucleus, clusterScaffold);
        clusters.push(clusterScaffold);
        this.childrenByNode.set(clusterScaffold, children);
        // To persist reloading while selected this would need to be non-zero initially
      }

      currentBush = this.createBush(clusters);
      this.bushes.set(zoom, currentBush);
      currentCanopy = clusters;
      this.canopies.set(zoom, currentCanopy);
      timer.stop();
    }
  }

  private getExistingSelectedCount(node: PointOrCluster<I, C>) {
    if (Grove.isCluster(node)) return this.selectedCountByNode.get(node) ?? 0;
    if (this.selectedLeafIds.has(node.id)) return 1;
    return 0;
  }

  private aggregateAtomicClusters(individualPoints: IndividualPoint<I>[], maxZoomLevel: number) {
    type LonLatKey = `${number},${number}`;
    const foundationalFeatures = new Map<LonLatKey, PointOrCluster<I, C>>();

    for (const currentPoint of individualPoints) {
      this.nodesById.set(currentPoint.id, currentPoint);
      const key: LonLatKey = `${Grove.getLongitudeFromGeoJsonPoint(
        currentPoint
      )},${Grove.getLatitudeFromGeoJsonPoint(currentPoint)}`;
      const existingFeature = foundationalFeatures.get(key);
      if (!existingFeature) {
        // First time we've seen this location
        foundationalFeatures.set(key, currentPoint);
        continue;
      }

      if (Grove.isCluster(existingFeature)) {
        // Merge with cluster
        existingFeature.properties.pointCount += 1;
        existingFeature.properties.pointCountAbbreviated = Grove.abbreviateCount(existingFeature.properties.pointCount);
        this.childrenByNode.get(existingFeature).push(currentPoint);
        this.parentByNode.set(currentPoint, existingFeature);
        this.options.reduce(existingFeature.properties, currentPoint.properties);
        if (this.selectedLeafIds.has(existingFeature.id)) this.alterSelectedCount(existingFeature, 1);
      } else {
        // Turn the existing point into a cluster
        const clusterId = `O::${maxZoomLevel}::${existingFeature.id}`;
        const overlapCluster: Cluster<C> = {
          type: 'Feature',
          id: clusterId,
          geometry: existingFeature.geometry,
          properties: {
            isCluster: true,
            clusterId,
            clusterZoom: -1,
            isClusterDivisible: false,
            pointCount: 2,
            pointCountAbbreviated: '2'
            // This does not yet have C, but gets it on the next line
          } satisfies ReservedClusterProperties as C & ReservedClusterProperties
        };
        this.nodesById.set(clusterId, overlapCluster);
        this.childrenByNode.set(overlapCluster, [existingFeature, currentPoint]);
        this.parentByNode.set(existingFeature, overlapCluster);
        this.parentByNode.set(currentPoint, overlapCluster);
        this.options.reduce(overlapCluster.properties, existingFeature.properties);
        this.options.reduce(overlapCluster.properties, currentPoint.properties);
        foundationalFeatures.set(key, overlapCluster);

        if (this.selectedLeafIds.has(currentPoint.id)) this.alterSelectedCount(overlapCluster, 1);
        if (this.selectedLeafIds.has(existingFeature.id)) this.alterSelectedCount(overlapCluster, 1);
      }
    }
    return [...foundationalFeatures.values()];
  }

  public getZoomToDecompose(clusterId: string) {
    const node = this.nodesById.get(clusterId);
    if (!node || !Grove.isCluster(node)) throw new Error(`No cluster with id ${clusterId}`);
    if (!node.properties.isClusterDivisible) return null;
    const clusterZoom = node.properties.clusterZoom;

    const decompositionZoom = this.options.zoomLevels[1 + this.options.zoomLevels.indexOf(clusterZoom)];
    if (typeof decompositionZoom !== 'number') throw new Error(`No decomposition zoom for ${clusterZoom}`);

    return decompositionZoom;
  }

  public getLeavesPropertiesForCluster(clusterId: string) {
    const properties = new Array<I>();
    for (const leafId of this.getLeavesForId(clusterId)) {
      const node = this.nodesById.get(leafId);
      if (!node || Grove.isCluster(node)) continue;
      properties.push(node.properties);
    }
    return properties;
  }

  private *getLeavesForId(id: number | string) {
    const startingNode = this.nodesById.get(id);
    if (!startingNode) throw new Error(`No ndoe with ID`);
    const stack = [startingNode];
    while (stack.length) {
      const node = stack.pop();
      if (!node) throw new Error(`Nullish node in stack`);
      if (Grove.isCluster(node)) {
        const children = this.childrenByNode.get(node);
        stack.push(...children);
      } else {
        yield node.id;
      }
    }
  }

  public *getLeavesForIds(ids: Iterable<number | string>) {
    for (const id of ids) {
      yield* this.getLeavesForId(id);
    }
  }

  private static isCluster<I, C>(pointOrCluster: PointOrCluster<I, C>): pointOrCluster is Cluster<C> {
    return !!pointOrCluster.properties.isCluster;
  }

  private getAncestry(id: number | string) {
    let node = this.nodesById.get(id);
    const ancestry: PointOrCluster<I, C>[] = [];
    while (node) {
      ancestry.push(node);
      node = this.parentByNode.get(node);
    }
    return ancestry;
  }

  private static abbreviateCount(count: number): string {
    if (count >= 10_000_000) return `${Math.round(count / 1_000_000)}m`;
    if (count >= 1_000_000) return `${Math.round(count / 100_000) / 10}m`;
    if (count >= 10_000) return `${Math.round(count / 1_000)}k`;
    if (count >= 1000) return `${Math.round(count / 100) / 10}k`;
    return count.toString();
  }

  private isSelected(feature: PointOrCluster<I, C>) {
    return (
      this.selectedLeafIds.has(feature.id) || (Grove.isCluster(feature) && this.selectedCountByNode.get(feature) > 0)
    );
  }

  public getClustersByBounds(
    zoom: number,
    bounds: MapBounds
  ): Array<PointOrCluster<I, C> | SelectedPointOrCluster<I, C>> {
    const timer = startPerformanceTimer('Getting clusters by bounds', 20);
    const closestZoom = getClosestZoomBreakpoint(this.options.zoomLevels, zoom);
    const canopy = this.canopies.get(closestZoom);

    if (!canopy) throw new Error(`No clusters for zoom ${zoom}`);
    const bush = this.bushes.get(closestZoom);
    if (!bush) throw new Error(`No bush for zoom ${zoom}`);

    const featureIndexes = bush.range(
      longitudeToX(bounds.west),
      latitudeToY(bounds.north),
      longitudeToX(bounds.east),
      latitudeToY(bounds.south)
    );

    const output = featureIndexes.map((index) => {
      const feature = canopy[index];
      return this.isSelected(feature)
        ? ({
            ...feature,
            properties: {
              ...feature.properties,
              isSelected: true
            }
          } as SelectedPointOrCluster<I, C>)
        : feature;
    });
    timer.stop();
    return output;
  }

  private calculateBushRadius(zoom: number) {
    return this.options.radius / (this.options.extent * Math.pow(2, zoom));
  }

  private static readonly getXFromPoint = (point: Point<unknown>): number =>
    Math.fround(longitudeToX(Grove.getLongitudeFromGeoJsonPoint(point)));

  private static readonly getYFromPoint = (point: Point<unknown>): number =>
    Math.fround(latitudeToY(Grove.getLatitudeFromGeoJsonPoint(point)));

  private static readonly getLongitudeFromGeoJsonPoint = (point: Point<unknown>): number =>
    point.geometry.coordinates[0];

  private static readonly getLatitudeFromGeoJsonPoint = (point: Point<unknown>): number =>
    point.geometry.coordinates[1];

  private createBush(items: Array<PointOrCluster<I, C>>) {
    return new KDBush<PointOrCluster<I, C>>(
      items,
      Grove.getXFromPoint,
      Grove.getYFromPoint,
      this.options.nodeSize,
      Float32Array
    );
  }

  public deselectAll() {
    this.selectedCountByNode.clear();
    this.selectedLeafIds.clear();
  }

  public selectPoints(idsToSelect: Array<number | string>) {
    const timer = startPerformanceTimer('Selecting points', 20);
    for (const id of idsToSelect) {
      this.selectPoint(id);
    }
    timer.stop();
  }

  public deselectPoints(idsToDeselect: Array<number | string>) {
    const timer = startPerformanceTimer('Deselecting points', 20);
    for (const id of idsToDeselect) {
      this.deselectPoint(id);
    }
    timer.stop();
  }

  private selectPoint(id: number | string) {
    if (this.selectedLeafIds.has(id)) return;
    this.selectedLeafIds.add(id);
    for (const node of this.getAncestry(id)) {
      if (node.properties.isCluster) this.alterSelectedCount(node as Cluster<C>, 1);
    }
  }

  private alterSelectedCount(node: Cluster<C>, delta: number) {
    const count = this.selectedCountByNode.get(node) ?? 0;
    this.selectedCountByNode.set(node, count + delta);
  }

  private deselectPoint(id: number | string) {
    if (!this.selectedLeafIds.has(id)) return;
    this.selectedLeafIds.delete(id);
    for (const node of this.getAncestry(id)) {
      if (node.properties.isCluster) this.alterSelectedCount(node as Cluster<C>, -1);
    }
  }
}

// Longitude/latitude to spherical mercator in [0..1] range
function longitudeToX(lng: number) {
  return 0.5 + lng / 360;
}
function latitudeToY(lat: number) {
  const sin = Math.sin((lat * Math.PI) / 180);
  const y = 0.5 - (0.25 * Math.log((1 + sin) / (1 - sin))) / Math.PI;
  if (y < 0) return 0;
  if (y > 1) return 1;
  return y;
}

// Spherical mercator to longitude/latitude
function xToLongitude(x: number) {
  return (x - 0.5) * 360;
}
function yToLatitude(y: number) {
  const y2 = ((180 - y * 360) * Math.PI) / 180;
  return (360 * Math.atan(Math.exp(y2))) / Math.PI - 90;
}
