import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';

//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { BattlecardType } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './SheetsReferencedCellRenderer.module.pcss';

const b = block(style);

const SheetsReferencedCellRenderer: React.FC<ICellRendererParams> = ({ data }) => {
  if (!data) {
    return null;
  }

  const isRollupBattleCard = data?.battlecardType === BattlecardType.Rollup;

  const content = isRollupBattleCard ? '-' : data?.sheets?.[0]?.sheetName || 'None';

  return (
    <div className={b('gridCell')}>
      <div className={b('sheetsReferencedTextContainer')}>
        <MessageTooltip
          popoverClassName="tooltipContent"
          content={content}
          target={
            <div className={b('sheetsReferencedText')} data-testid="sheets-referenced-cell-renderer">
              {content}{' '}
            </div>
          }
          placement={'top'}
        />
        {!isRollupBattleCard && data?.sheets?.length > 1 && (
          <MessageTooltip
            popoverClassName="tooltipContent"
            content={
              <>
                {data?.sheets?.slice(1)?.map((sheet) => {
                  return (
                    <span key={`${sheet.sheetId}`} data-testid="remaining-sheets-referenced-name">
                      {sheet.sheetName}
                    </span>
                  );
                })}
              </>
            }
            placement={'top'}
            target={
              <span data-testid="remaining-sheets-referenced-tooltip" className={b('extraSheetsReferencedCount')}>
                +{data?.sheets?.length - 1}
              </span>
            }
          />
        )}
      </div>
    </div>
  );
};

export default SheetsReferencedCellRenderer;
