import { MapboxMap } from 'react-map-gl';

import { SourceIds } from 'app/components/TerritoryMap/MapStyleTokens';

import { CombinedRuleId, LassoPolygon } from 'app/models';

import { Grove } from 'utils/Grove';

export type IndividualPointProperties = {
  accountId: number;
  accountName: string;

  ruleId: number | null;
  ruleColor: string | null;
  territoryName: string | null;
  territoryId: string | null;

  isGeoOverassigned: boolean | null;
  accountOverassignmentCount: number | null;

  measureValue: number | null;
};

export type ClusterProperties = {
  ruleColor: string | null;
  combinedRuleId: number | CombinedRuleId; // Never-null
  territoryName: string | null;
  territoryId: string | null;
  isClusterContainsUnassigned: boolean;
  isClusterContainsOverassigned: boolean;
};

export type AccountGrove = Grove<IndividualPointProperties, ClusterProperties>;

export const clusterPropertyReducer = (
  accumulator: Partial<ClusterProperties>,
  incoming: ClusterProperties | IndividualPointProperties
) => {
  accumulator.territoryName ??= incoming.territoryName;
  accumulator.territoryId ??= incoming.territoryId;
  accumulator.ruleColor ??= incoming.ruleColor;
  const incomingRuleId = getIncomingRuleId(incoming);

  accumulator.combinedRuleId ??= incomingRuleId;
  if (accumulator.combinedRuleId !== incomingRuleId) {
    accumulator.combinedRuleId = CombinedRuleId.MIXED;
  }

  if (!accumulator.isClusterContainsUnassigned) {
    accumulator.isClusterContainsUnassigned =
      'isClusterContainsUnassigned' in incoming
        ? incoming.isClusterContainsUnassigned
        : incomingRuleId === CombinedRuleId.UNASSIGNED;
  }

  if (!accumulator.isClusterContainsOverassigned) {
    accumulator.isClusterContainsOverassigned =
      'isClusterContainsOverassigned' in incoming
        ? incoming.isClusterContainsOverassigned
        : incoming.isGeoOverassigned || incoming.accountOverassignmentCount > 0;
  }
};

const getIncomingRuleId = (incoming: ClusterProperties | IndividualPointProperties): number | CombinedRuleId => {
  if ('combinedRuleId' in incoming) return incoming.combinedRuleId;
  if (incoming.isGeoOverassigned) return CombinedRuleId.MIXED;
  if (incoming.accountOverassignmentCount) return CombinedRuleId.MIXED;
  return incoming.ruleId ?? CombinedRuleId.UNASSIGNED;
};

type MapboxMapRefPick<T extends keyof MapboxMap> = {
  current?: {
    getMap: () => Pick<MapboxMap, T>;
  };
};

export function createLassoFunction(
  grove: Pick<AccountGrove, 'getLeavesForIds'>,
  mapboxMapRef: MapboxMapRefPick<'querySourceFeatures'>
) {
  return (lassoPolygon: LassoPolygon) => {
    const accountIds = new Set<number>();
    const mapboxMap = mapboxMapRef.current?.getMap();
    if (!mapboxMap) return accountIds;
    const pinsInLasso = mapboxMap.querySourceFeatures(SourceIds.account.cluster, {
      filter: ['within', lassoPolygon.geometry]
    });

    const clusterIds = new Set<string>();
    for (const pin of pinsInLasso) {
      if (pin.properties.isCluster) {
        clusterIds.add(pin.properties.clusterId);
      } else if (pin.properties.accountId) {
        accountIds.add(pin.properties.accountId);
      }
    }
    for (const accountId of grove.getLeavesForIds(clusterIds)) {
      accountIds.add(accountId as number);
    }
    return accountIds;
  };
}
export type LassoFn = ReturnType<typeof createLassoFunction>;

export function createBreakdownClusterFunction(
  grove: Pick<AccountGrove, 'getZoomToDecompose'>,
  mapboxMapRef: MapboxMapRefPick<'flyTo'>
) {
  return (feature: GeoJSON.Feature<GeoJSON.Point, { clusterId: string }>) => {
    const mapboxMap = mapboxMapRef.current?.getMap();
    if (!mapboxMap) return;
    const zoom = grove.getZoomToDecompose(feature.properties.clusterId);
    if (!zoom) return;
    const [lng, lat] = feature.geometry.coordinates;
    mapboxMap.flyTo({ center: { lng, lat }, zoom });
  };
}

export type BreakdownClusterFn = ReturnType<typeof createBreakdownClusterFunction>;

export function createGetLeavesFunction(grove: Pick<AccountGrove, 'getLeavesPropertiesForCluster'>) {
  return (clusterId: string) => {
    return grove.getLeavesPropertiesForCluster(clusterId);
  };
}

export type GetLeavesFn = ReturnType<typeof createGetLeavesFunction>;
