import React, { useRef, useEffect, useState } from 'react';

import { Placement } from '@blueprintjs/popover2';

//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import block from 'utils/bem-css-modules';

import style from './EllipsisText.module.pcss';

const b = block(style);

interface EllipsisTextProps {
  text: string;
  className?: string;
  popoverClassName?: string;
  placement?: Placement;
}

// ensure that the parent element that contains this
// component has the style "overflow: hidden" set!
const EllipsisText: React.FC<EllipsisTextProps> = ({ text, className, popoverClassName, placement = 'top' }) => {
  const textElementRef = useRef<HTMLDivElement>(null);

  const [isTooltipEnabled, setIsTooltipEnabled] = useState<boolean>(false);

  const classNameString = className ? ` ${className}` : '';

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const isEllipsisRequired = textElementRef?.current?.scrollWidth > textElementRef?.current?.clientWidth;
      setIsTooltipEnabled(isEllipsisRequired);
    });

    resizeObserver.observe(textElementRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <MessageTooltip
      content={text}
      target={
        <div ref={textElementRef} className={b('textContainer')} data-testid="text-container">
          {text}
        </div>
      }
      placement={placement}
      className={`${b()}${classNameString}`}
      popoverClassName={popoverClassName}
      disabled={!isTooltipEnabled}
    />
  );
};

export default React.memo(EllipsisText);
