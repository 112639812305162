import { useCallback, useEffect, useState } from 'react';

import { useMapWorkerPostMessage, useOnMapWorkerMessage } from 'app/contexts/mapWorkerContext';

export const useVisuallySelectedAccounts = (): Set<number> => {
  const [selectedAccountIds, setSelectedAccountIds] = useState(new Set<number>());
  const postMessage = useMapWorkerPostMessage();

  useEffect(() => {
    postMessage({ type: 'get-initial-selected-accounts' });
  }, []);

  useOnMapWorkerMessage(
    useCallback((message) => {
      if (message.type !== 'set-accounts-is-selected') return;
      setSelectedAccountIds((oldSet) => {
        const newSet = new Set(oldSet);
        if (message.isSelected) {
          message.ids.forEach((id) => newSet.add(id));
        } else {
          message.ids.forEach((id) => newSet.delete(id));
        }
        return newSet;
      });
    }, [])
  );

  return selectedAccountIds;
};
