import React from 'react';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { AgGridReact } from '@ag-grid-community/react';
import { AgGridReactProps } from '@ag-grid-community/react/lib/shared/interfaces';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';

import LoadingCellRenderer from 'app/components/AdvancedGrid/CellRenderers/LoadingCellRenderer/LoadingCellRenderer';
import GridLoading from 'app/components/AdvancedGrid/GridLoading/GridLoading';

import block from 'utils/bem-css-modules';
import { config } from 'utils/config';

import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import style from './AdvancedGrid.module.pcss';

const b = block(style);

const DEFAULT_LOADING_CELL_RENDERER = 'defaultLoadingCellRenderer';

const modules = [
  ClientSideRowModelModule,
  RowGroupingModule,
  ServerSideRowModelModule,
  InfiniteRowModelModule,
  StatusBarModule
];

interface AdvancedGridProps extends AgGridReactProps {
  data?: string;
  noDataMessage?: string;
  gridWidth?: number;
  gridHeight?: number;
  showGridLoading?: boolean;
  overwriteGridOptions?: boolean; // to support alignedGrids
}

const AdvancedGrid: React.FC<AdvancedGridProps> = ({
  gridOptions = {},
  frameworkComponents = {},
  loadingCellRenderer = DEFAULT_LOADING_CELL_RENDERER,
  loadingCellRendererParams = {},
  showGridLoading = false,
  data,
  columnDefs,
  noDataMessage,
  getDataPath,
  getRowNodeId,
  onGridReady,
  onSelectionChanged,
  rowSelection,
  autoGroupColumnDef,
  gridHeight,
  gridWidth,
  onRowSelected,
  overwriteGridOptions = false,
  ...rest
}: AdvancedGridProps) => {
  const canShowGridLoading = !!gridHeight && !!gridWidth;
  const rowData = data ? JSON.parse(data) : [];

  const defaultGridOptions = {
    suppressScrollOnNewData: true,
    suppressAnimationFrame: true,
    suppressColumnMoveAnimation: true,
    suppressMovableColumns: true,
    suppressRowDrag: true
  };

  if (
    loadingCellRenderer === DEFAULT_LOADING_CELL_RENDERER &&
    !frameworkComponents[DEFAULT_LOADING_CELL_RENDERER] &&
    !!gridWidth // gridWidth is a required prop for the default loading cell renderer
  ) {
    frameworkComponents[DEFAULT_LOADING_CELL_RENDERER] = LoadingCellRenderer;
    loadingCellRendererParams['gridWidth'] = gridWidth;
  }

  return (
    <>
      {showGridLoading && canShowGridLoading ? (
        <div data-testid="grid-loading">
          <GridLoading gridHeight={gridHeight} gridWidth={gridWidth} />
        </div>
      ) : (
        <div className={b()} data-testid="advanced-grid">
          <div className="ag-theme-alpine">
            <AgGridReact
              modules={modules}
              gridOptions={overwriteGridOptions ? gridOptions : { ...defaultGridOptions, ...gridOptions }}
              columnDefs={columnDefs}
              rowData={rowData}
              debug={config.NODE_ENV === 'development'}
              suppressColumnVirtualisation={config.NODE_ENV === 'test'}
              overlayNoRowsTemplate={noDataMessage}
              getDataPath={getDataPath}
              onGridReady={onGridReady}
              getRowNodeId={getRowNodeId}
              onSelectionChanged={onSelectionChanged}
              rowSelection={rowSelection}
              autoGroupColumnDef={autoGroupColumnDef}
              frameworkComponents={frameworkComponents}
              loadingCellRenderer={loadingCellRenderer}
              loadingCellRendererParams={loadingCellRendererParams}
              onRowSelected={onRowSelected}
              rowClass={b('whiteRow')}
              {...rest}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(AdvancedGrid, (prevProps, nextProps) => {
  // Memoize if the data has not changed or if the loading state is the same or if the grid size is the same. Otherwise, re-render.
  return (
    prevProps.data === nextProps.data &&
    prevProps.showGridLoading === nextProps.showGridLoading &&
    prevProps.gridWidth === nextProps.gridWidth &&
    prevProps.gridHeight === nextProps.gridHeight &&
    prevProps.groupRowRendererParams === nextProps.groupRowRendererParams &&
    prevProps.columnDefs?.length === nextProps.columnDefs?.length &&
    prevProps.suppressRowClickSelection === nextProps.suppressRowClickSelection
  );
});
