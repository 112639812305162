import React from 'react';

import { Classes } from '@blueprintjs/core';
import { Information } from '@carbon/icons-react';
import dayjs from 'dayjs';
import { Field, useFormikContext } from 'formik';

//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { getPlanningCycleEndDate } from 'app/components/AdvancedGrid/Sheets/Account/AccountQuotaUtils';
import { SheetsAddFieldFormValues } from 'app/components/DataPanel/SheetsPanel/SheetDetail/SheetsAddFieldForm/SheetsAddFieldForm';
import FormDateRangePicker from 'app/components/FormFields/FormDateRangePicker/FormDateRangePicker';

import { useScope } from 'app/contexts/scopeProvider';

import {
  ADD_HISTORICAL_FIELD_EMPTY_DATE_RANGE_MAX_YEARS_FROM_CURRENT,
  ADD_HISTORICAL_FIELD_EMPTY_DATE_RANGE_MIN_YEARS_FROM_CURRENT
} from 'app/global/variables';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SheetsAddFieldFormFields.module.pcss';

const b = block(style);

export const HistoricalFieldsPage: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<SheetsAddFieldFormValues>();
  const { selectedPlanningCycle } = useScope();
  const { planningCycleStartDate, planningCycleDuration } = selectedPlanningCycle;

  const handleUpdateMeasureDateRange = (e: React.ChangeEvent<string>) => {
    const startDate = e?.[0] ? dayjs(e[0]).format('YYYY-MM-DD') : planningCycleStartDate;
    const endDate = e?.[1]
      ? dayjs(e[1]).format('YYYY-MM-DD')
      : getPlanningCycleEndDate(planningCycleStartDate, planningCycleDuration);
    setFieldValue('startDate', startDate);
    setFieldValue('endDate', endDate);
  };

  const getMaxDate = () => {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + ADD_HISTORICAL_FIELD_EMPTY_DATE_RANGE_MAX_YEARS_FROM_CURRENT);
    return maxDate;
  };

  const getMinDate = () => {
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - ADD_HISTORICAL_FIELD_EMPTY_DATE_RANGE_MIN_YEARS_FROM_CURRENT);
    return minDate;
  };

  return (
    <div data-testid="historical-fields-page">
      <div data-testid="measure-source-field">
        <label className={`${b('label')} ${Classes.LABEL}`}>{formatMessage('DATA_TABLE_TYPE_WITH_COLON')}</label>
        <div className={b('staticFieldValue')}>{formatMessage('ACTIVITY')}</div>
      </div>
      <div className={b('formInput')} data-testid="measure-date-range-field">
        <label className={`${b('labelWithInformationTooltip')} ${Classes.LABEL}`}>
          {formatMessage('DATE_RANGE')}
          <MessageTooltip
            content={formatMessage('HISTORICAL_MEASURE_DATE_RANGE_TOOLTIP_MESSAGE')}
            target={<Information />}
            placement={'top'}
            className={b('informationTooltip')}
          />
        </label>
        <Field
          name="measureDateRange"
          component={FormDateRangePicker}
          defaultStartDate={values?.startDate || planningCycleStartDate}
          defaultEndDate={values?.endDate || getPlanningCycleEndDate(planningCycleStartDate, planningCycleDuration)}
          position="bottom"
          onChange={handleUpdateMeasureDateRange}
          minDate={getMinDate()}
          maxDate={getMaxDate()}
          fullWidth
          stacked
          bothDatesRequired
          usePortal={false}
        />
      </div>
    </div>
  );
};

export default HistoricalFieldsPage;
