import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';

//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import block from 'utils/bem-css-modules';

import style from './MessageTooltipCellRenderer.module.pcss';

const b = block(style);

interface MessageTooltipCellRendererProps extends ICellRendererParams {
  tooltipAdditionalContentField?: string;
  message?: string;
}

export const MessageTooltipCellRenderer: React.FC<MessageTooltipCellRendererProps> = ({
  value,
  data,
  tooltipAdditionalContentField,
  message
}) => {
  if (!data) {
    return null;
  }

  let toolTipMessage;
  if (message) {
    toolTipMessage = message;
  } else {
    const toolTipAdditionalMessage = data[tooltipAdditionalContentField]
      ? ` (${data[tooltipAdditionalContentField]})`
      : '';
    toolTipMessage = `${value}${toolTipAdditionalMessage}`;
  }

  return (
    <div className={b()}>
      <MessageTooltip
        content={toolTipMessage}
        placement={'top'}
        target={<span className={b('cellContent')}>{value}</span>}
      />
    </div>
  );
};

export default MessageTooltipCellRenderer;
