import React, { useEffect, useRef, useState } from 'react';

import { ChevronUp } from '@carbon/icons-react';
import { Button, Tag } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';
import EllipsisText from 'components/EllipsisText/EllipsisText';

import { CollectionFilter, NamedHierarchy } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ExpandableCustomHierarchyTags.module.pcss';

const b = block(style);

interface ExpandableCustomHierarchyTagsProps {
  customHierarchyFilter: CollectionFilter<number>;
  customHierarchies: NamedHierarchy[];
}

const ExpandableCustomHierarchyTags: React.FC<ExpandableCustomHierarchyTagsProps> = ({
  customHierarchyFilter,
  customHierarchies
}) => {
  const tagContainerRef = useRef<HTMLDivElement>(null);
  const [hiddenTagsCount, setHiddenTagsCount] = useState<number>(0);
  const [hideOverflowTags, setHideOverflowTags] = useState<boolean>(true);

  useEffect(() => {
    if (customHierarchies.length === 0) {
      return;
    }

    let totalTagWidth = 0;
    let currentHiddenTagsCount = 0;
    const tags = tagContainerRef.current.children;

    for (let i = 0; i < tags.length; i++) {
      tags[i].classList.remove(b('hidden'));
    }

    if (hideOverflowTags) {
      for (let i = 0; i < tags.length; i++) {
        totalTagWidth += tags[i].clientWidth;

        if (totalTagWidth > tagContainerRef.current.clientWidth) {
          totalTagWidth -= tags[i].clientWidth;
          tags[i].classList.add(b('hidden'));
          currentHiddenTagsCount++;
        }
      }
    }

    setHiddenTagsCount(currentHiddenTagsCount);
  }, [customHierarchyFilter, hideOverflowTags, customHierarchies]);

  return (
    <div className={b()}>
      {customHierarchies.length > 0 && (
        <>
          <div ref={tagContainerRef} className={b('tagsContainer', { hideOverflowTags })}>
            {customHierarchyFilter.ids.map((customHierarchy) => {
              const selectedCustomHierarchy = customHierarchies.find(
                (currentHierarchy) => currentHierarchy.hierarchyId === customHierarchy
              );
              return selectedCustomHierarchy ? (
                <Tag className={b('customHierarchyTag')} key={selectedCustomHierarchy.hierarchyId} round>
                  <EllipsisText className={b('customHierarchyTagEllipsisText')} text={selectedCustomHierarchy.name} />
                </Tag>
              ) : null;
            })}
          </div>
          <div className={b('controlButtonsContainer')}>
            {hiddenTagsCount > 0 && hideOverflowTags && (
              <Button
                onClick={() => setHideOverflowTags(false)}
                minimal
                small
                text={formatMessage('PLUS', { count: hiddenTagsCount })}
                type="button"
              />
            )}
            {!hideOverflowTags && (
              <div className={b('hideOverflowTagsButton')}>
                <IconButton
                  icon={<ChevronUp />}
                  onClick={() => setHideOverflowTags(true)}
                  type="button"
                  testId="hide-overflow-tags"
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ExpandableCustomHierarchyTags;
