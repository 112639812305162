// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t6rrL {\n    width: 272px;\n    padding: 12px;\n    border-radius: 4px;\n    grid-gap: var(--m);\n    gap: var(--m);\n  }\n  .b4mPJ {\n    margin-bottom: var(--xs);\n    font-size: 12px;\n  }\n  ._3dFfp {\n    margin-top: var(--ml);\n  }\n  .uNi6v {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n  .S-NCG {\n    display: flex;\n    justify-content: center;\n    margin-top: var(--m);\n  }\n  .a3hLZ {\n    display: flex;\n    margin-top: var(--m);\n  }\n  .jBgaF {\n    margin-left: var(--m);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MapGridFilterV2_filterSelectionWrapper": "t6rrL",
	"MapGridFilterV2_filterMapsTitle": "b4mPJ",
	"MapGridFilterV2_customHierarchyFilterMenuWrapper": "_3dFfp",
	"MapGridFilterV2_filterFooter": "uNi6v",
	"MapGridFilterV2_saveSegment": "S-NCG",
	"MapGridFilterV2_filterApplyAndClear": "a3hLZ",
	"MapGridFilterV2_applyFilterButton": "jBgaF"
};
export default ___CSS_LOADER_EXPORT___;
