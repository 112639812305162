// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pyPP\\+ {\n    padding-top: 30px;\n  }\n  .I8o\\+9 {\n    display: flex;\n    justify-content: center;\n    min-height: 200px;\n    align-items: center;\n  }\n  .skzwf {\n    padding: 24px 0;\n  }\n  .yabyV {\n    padding: var(--l) 0;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccountAlignmentUpload_linkContainer": "pyPP+",
	"AccountAlignmentUpload_spinnerContainer": "I8o+9",
	"AccountAlignmentUpload_uploadColumnExtraContext": "skzwf",
	"AccountAlignmentUpload_fileUploadContainer": "yabyV"
};
export default ___CSS_LOADER_EXPORT___;
