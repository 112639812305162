import React, { useState } from 'react';

import { InformationFilled } from '@carbon/icons-react';
import { FieldProps } from 'formik';

//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import FormattedContentEditableNumberInput from 'app/components/FormFields/FormattedContentEditableNumberInput/FormattedContentEditableNumberInput';

import { MeasureFormatType, NumberInputFormatStyle } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './BattleCardInputField.module.pcss';

const b = block(style);

interface BattleCardInputFieldProps extends FieldProps {
  lineItemFormat: MeasureFormatType;
  currency: string;
  placeholder: string;
  index: number;
  onSubmit: (values: unknown) => void;
}

const BattleCardInputField: React.FC<BattleCardInputFieldProps> = ({
  field: { name, value },
  form: { values, errors, setFieldValue, setFieldTouched, resetForm },
  lineItemFormat,
  currency,
  placeholder,
  index,
  onSubmit
}: BattleCardInputFieldProps) => {
  const formatStyle =
    lineItemFormat === MeasureFormatType.CURRENCY ? NumberInputFormatStyle.CURRENCY : NumberInputFormatStyle.PERCENTAGE;

  const [lastConfirmedValue, setLastConfirmedValue] = useState(value);

  const handleConfirm = (inputValue) => {
    if (lastConfirmedValue === inputValue) return;
    if (!lastConfirmedValue && inputValue === '') return;
    setFieldTouched(name);
    setLastConfirmedValue(inputValue);
    setFieldValue(name, inputValue);
    onSubmit(values);
  };

  const handleOnChange = (inputValue) => {
    setFieldValue(name, inputValue);
  };

  const isError =
    errors['panelData'] &&
    errors['panelData'][index] &&
    errors['panelData'][index] !== 'empty' &&
    errors['panelData'][index].measureValue;

  return (
    <div className={b()}>
      <div
        className={b('inputField', {
          error: isError !== undefined,
          currencyFormat: lineItemFormat === MeasureFormatType.CURRENCY,
          percentageFormat: lineItemFormat === MeasureFormatType.PERCENTAGE
        })}
      >
        <FormattedContentEditableNumberInput
          value={value}
          formatStyle={formatStyle}
          currency={currency}
          placeHolder={placeholder}
          onChange={handleOnChange}
          onConfirm={handleConfirm}
          onCancel={resetForm}
        />
      </div>
      {isError && (
        <MessageTooltip
          content={isError}
          placement={'top'}
          target={
            <div className={b('validationMessage')}>
              <InformationFilled />{' '}
            </div>
          }
          rootBoundary={'viewport'}
        />
      )}
    </div>
  );
};

export default BattleCardInputField;
