import React, { useEffect, useMemo, useState, useRef } from 'react';

import { Filter, ChevronDown } from '@carbon/icons-react';

import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import Popover from 'components/Popover/Popover';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCoinsort } from 'app/contexts/coinsortProvider';
import { useRebalancing } from 'app/contexts/rebalancingProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { DefinitionFilterOperatorEnum, HierarchyTypeEnum } from 'app/graphql/generated/graphqlApolloTypes';
import { GET_ROOT_HIERARCHIES } from 'app/graphql/queries/getRootHierarchies';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import {
  HierarchyQuerySpec,
  HierarchyType,
  HierarchySpec,
  HierarchyItem,
  MatchingRulesFilters,
  NoneTagHierarchyId
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import ExpandedHierarchyRule from './BalancingPanel/ExpandedHierarchyRule/ExpandedHierarchyRule';
import style from './TerritoryBalancingGridRuleFilter.module.pcss';

const b = block(style);

export interface CustomHierarchyFilter {
  items: HierarchyItem[];
  kind: DefinitionFilterOperatorEnum;
  hierarchyType: HierarchyTypeEnum;
}

export interface HierarchyFilter {
  [key: string]: CustomHierarchyFilter;
}

const NONE_TAG: HierarchyItem[] = [{ name: 'None', hierarchyId: NoneTagHierarchyId }];

const TerritoryBalancingGridRuleFilter: React.FC<{
  handleDefinitionFiltersUpdate: (selectedFilters: HierarchyFilter) => void;
  setMatchingRulesFilters: React.Dispatch<React.SetStateAction<MatchingRulesFilters>>;
}> = ({ handleDefinitionFiltersUpdate, setMatchingRulesFilters }) => {
  const { selectedPlanningCycle } = useScope();
  const filterButtonRef = useRef<HTMLSpanElement>(null);
  const showToast = useShowToast();
  const { selectedBattleCardId } = useBattleCard();
  const { selectedRebalancingRows, selectedRebalancingHierarchy } = useRebalancing();
  const [filterableHierarchies, setFilterableHierarchies] = useState<HierarchySpec[]>([]);
  const [nonCustomHierarchies, setNonCustomHierarchies] = useState<HierarchySpec[]>([]);
  const [allHierarchiesFilters, setAllHierarchiesFilters] = useState<HierarchyFilter>({});
  const [isMatchOnSelectionActive, setIsMatchOnSelectionActive] = useState<boolean>(false);

  const [matchOnSelectionEnabled] = useTreatment(SplitFeatures.MATCH_ON_SELECTION_TOGGLE);

  const useLazyGetRootHierarchies = createUseLazyQuery<HierarchyQuerySpec, { planningCycleId: number }>(
    GET_ROOT_HIERARCHIES
  );

  const { isCoinsortRunCompleted } = useCoinsort();

  const matchingRulesFilters: MatchingRulesFilters | null = useMemo(() => {
    return isMatchOnSelectionActive
      ? {
          ruleId: selectedRebalancingRows[0]?.ruleId,
          hierarchyType: selectedRebalancingHierarchy?.key as HierarchyType
        }
      : null;
  }, [isMatchOnSelectionActive, selectedRebalancingRows, selectedRebalancingHierarchy]);

  const activeFiltersCount = Object.keys(allHierarchiesFilters).reduce((count, key) => {
    return allHierarchiesFilters[key].items.length > 0 ? count + 1 : count;
  }, 0);

  const [getRootHierarchies, { data: rootHierarchies, loading: isRootHierarchiesLoading }] = useLazyGetRootHierarchies({
    fetchPolicy: 'network-only',
    variables: { planningCycleId: selectedPlanningCycle?.id },
    onError() {
      showToast(formatMessage('UNABLE_TO_RETRIEVE_HIERARCHIES'), 'danger');
    }
  });

  useEffect(() => {
    if (isCoinsortRunCompleted) {
      handleFiltersClear();
    }
  }, [isCoinsortRunCompleted]);

  useEffect(() => {
    getRootHierarchies();
  }, [selectedBattleCardId]);

  useEffect(() => {
    if (rootHierarchies?.getRootHierarchies) {
      const fetchedRootCustomHierarchies = rootHierarchies?.getRootHierarchies.filter(
        (hierarchy) => hierarchy.hierarchyType === HierarchyType.CustomHierarchy
      );
      // The rebalancing hierarchy is not filterable. we use the other hierarchies to filter the territories
      const nonRebalancingHierarchySpec = rootHierarchies?.getRootHierarchies.find(
        (hierarchy) =>
          hierarchy.rootName !== selectedRebalancingHierarchy?.key &&
          hierarchy.hierarchyType !== HierarchyType.CustomHierarchy
      );

      const nonCustomhierarchies = rootHierarchies?.getRootHierarchies.filter(
        (hierarchy) => hierarchy.hierarchyType !== HierarchyType.CustomHierarchy
      );

      setNonCustomHierarchies(nonCustomhierarchies);
      // Filterable hierarchies should include all custom hierarchies and the non-rebalancing hierarchy
      setFilterableHierarchies([nonRebalancingHierarchySpec, ...fetchedRootCustomHierarchies]);
    }
  }, [rootHierarchies]);

  useEffect(() => {
    if (isMatchOnSelectionActive && matchingRulesFilters?.ruleId) {
      setMatchingRulesFilters(matchingRulesFilters);
    }
  }, [matchingRulesFilters]);

  useEffect(() => {
    const nonRebalancingHierarchySpec = nonCustomHierarchies.find(
      (hierarchy) => hierarchy.rootName !== selectedRebalancingHierarchy?.key
    );

    if (nonRebalancingHierarchySpec) {
      setFilterableHierarchies([
        nonRebalancingHierarchySpec,
        ...filterableHierarchies.filter((hierarchy) => hierarchy.hierarchyType === HierarchyType.CustomHierarchy)
      ]);
    }
  }, [selectedRebalancingHierarchy]);

  useEffect(() => {
    if (filterableHierarchies.length) {
      buildBaseHierarchiesFilters();
    }
  }, [filterableHierarchies]);

  const getInclusions = (hierarchyId: number): HierarchyItem[] => {
    return allHierarchiesFilters[hierarchyId]?.items || NONE_TAG;
  };

  const handleHierarchiesFilterUpdate = (remoteFilter: HierarchyItem[], hierarchyId: number): void => {
    setAllHierarchiesFilters((prevState) => ({
      ...prevState,
      [hierarchyId]: {
        ...prevState[hierarchyId],
        items: remoteFilter
      }
    }));
  };

  const buildBaseHierarchiesFilters = (): void => {
    const filters = filterableHierarchies.reduce((acc, hierarchy) => {
      acc[hierarchy.rootHierarchyId] = {
        kind: DefinitionFilterOperatorEnum.EQUALS,
        hierarchyType: hierarchy.hierarchyType,
        items: []
      };
      return acc;
    }, {});

    setAllHierarchiesFilters(filters);
  };

  const handleEmptyFiltersWhenApply = (): void => {
    const updatedHierarchiesFilters = Object.keys(allHierarchiesFilters).reduce((acc, key) => {
      acc[key] = {
        ...allHierarchiesFilters[key],
        items: allHierarchiesFilters[key].items.length ? allHierarchiesFilters[key].items : NONE_TAG
      };
      return acc;
    }, {});

    setAllHierarchiesFilters(updatedHierarchiesFilters);
  };

  const handleFiltersClear = (): void => {
    handleDefinitionFiltersUpdate({});
    buildBaseHierarchiesFilters();
  };

  const handleApplyFilters = (): void => {
    filterButtonRef.current?.click();
    handleDefinitionFiltersUpdate(allHierarchiesFilters);
    handleEmptyFiltersWhenApply();
  };

  const getFiltersTextButton = (): JSX.Element => {
    const buttonText = isRootHierarchiesLoading ? (
      <div className="bp3-skeleton" data-testid="searchable-select-menu-loading" />
    ) : (
      <div data-testid="definition-filters-button">
        {formatMessage('FILTERS')}
        {activeFiltersCount ? (
          <span data-testid="filter-count" className={b('filterCount')}>
            {activeFiltersCount}
          </span>
        ) : null}
      </div>
    );

    return (
      <MessageTooltip
        data-testid="definition-filters-tooltip"
        content={formatMessage('DEFINITION_FILTERS_TOOLTIP')}
        placement="top"
        target={
          <span ref={filterButtonRef}>
            <TextButton
              text={buttonText}
              type="button"
              minimal
              large={false}
              icon={<Filter size={20} />}
              rightIcon={<ChevronDown size={20} />}
              testId={'filter-button'}
            />
          </span>
        }
      />
    );
  };

  return (
    <div className={b()}>
      <div className={b('container')}>
        <Popover
          content={
            <div className={b('filterWrapper')} data-testid="custom-hierarchy-filter">
              <div className={b('tagInputsWrapper')}>
                {filterableHierarchies.map((customHierarchyItem) => (
                  <div key={customHierarchyItem.rootName}>
                    <ExpandedHierarchyRule
                      rootHierarchyId={customHierarchyItem.rootHierarchyId}
                      rootHierarchyName={customHierarchyItem.rootName}
                      hierarchyType={customHierarchyItem.hierarchyType as HierarchyType}
                      inclusions={getInclusions(customHierarchyItem.rootHierarchyId)}
                      onUpdate={handleHierarchiesFilterUpdate}
                      key={`expanded-hierarchy-rule-${customHierarchyItem.rootName}`}
                      data-testid={`expanded-hierarchy-rule-${customHierarchyItem.rootName}`}
                    />
                  </div>
                ))}
              </div>
              <div className={b('buttonContainer')}>
                <TextButton
                  text={formatMessage('APPLY')}
                  testId="apply-button"
                  type="button"
                  large={false}
                  onClick={handleApplyFilters}
                />
              </div>
            </div>
          }
          placement="bottom-start"
          minimal
        >
          {getFiltersTextButton()}
        </Popover>
        {activeFiltersCount > 0 && (
          <TextButton
            text={formatMessage('CLEAR_ALL')}
            type="button"
            minimal
            large={false}
            testId={'clear-button'}
            onClick={handleFiltersClear}
          />
        )}
      </div>

      {matchOnSelectionEnabled && (
        <div className={b('toggleSwitchContainer')}>
          <SwitchButton
            large
            intent={'primary'}
            checked={isMatchOnSelectionActive}
            testId="toggle-switch"
            label={formatMessage('MATCH_ON_SELECTION')}
            onChange={() => {
              setIsMatchOnSelectionActive(!isMatchOnSelectionActive);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TerritoryBalancingGridRuleFilter;
