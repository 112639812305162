import React from 'react';

//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import block from 'utils/bem-css-modules';

import style from './ExtraItemTagCellRenderer.module.pcss';
const b = block(style);

interface ExtraItemTagCellRendererProps {
  shouldRenderNoData: boolean;
  items: string[];
}

const ExtraItemTagCellRenderer: React.FC<ExtraItemTagCellRendererProps> = ({
  shouldRenderNoData,
  items
}: ExtraItemTagCellRendererProps) => {
  if (shouldRenderNoData) {
    return <div data-testid="no-data" />;
  }
  const [firstElement, ...remainingElements] = items;
  const getCellRendererString = () => {
    return (
      <div>
        <span data-testid="first-element">{firstElement}</span>
        {remainingElements.length > 0 && (
          <MessageTooltip
            popoverClassName="tooltipContent"
            content={
              <>
                {remainingElements.map((otherElement, index) => {
                  return (
                    <span key={`${otherElement}-${index}`} data-testid="remaining-elements">
                      {otherElement}
                    </span>
                  );
                })}
              </>
            }
            placement={'top'}
            target={
              <span className={b('remainingElementsCount')} data-testid="remaining-elements-tooltip">
                +{remainingElements.length}
              </span>
            }
          />
        )}
      </div>
    );
  };
  return (
    <div className={b('')} data-testid="extra-item-tag-cell-renderer">
      <div className={b()}>{getCellRendererString()}</div>
    </div>
  );
};
export default ExtraItemTagCellRenderer;
