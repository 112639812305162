import React, { useEffect, useMemo, useState } from 'react';

import { ChevronDown, Edit, Save } from '@carbon/icons-react';
import { Button, DividerV2, Text } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import Popover from 'components/Popover/Popover';

import { CustomHierarchyFilterMenuV2 } from 'app/components/TerritoryMap/CustomHierarchyFilterMenuV2';
import SegmentPicker from 'app/components/TerritoryMap/TerritoryMapGrid/SegmentPicker';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import { SegmentFilter } from 'app/graphql/generated/graphqlApolloTypes';

import { CollectionFilter, defaultCustomHierarchyFilter, NamedHierarchy, SegmentEditorDialogModes } from 'app/models';

import block from 'utils/bem-css-modules';
import { isCollectionFilterEqual } from 'utils/helpers/territoryMapUtils';
import { formatMessage } from 'utils/messages/utils';

import style from './MapGridFilterV2.module.pcss';

const b = block(style);

interface MapGridFilterProps {
  customHierarchies: NamedHierarchy[];
  customHierarchyFilter: CollectionFilter<number>;
  segments: SegmentFilter[];
}
const MapGridFilterV2: React.FC<MapGridFilterProps> = ({
  customHierarchies,
  customHierarchyFilter: remoteFilter,
  segments
}) => {
  const { selectedSegmentId, setSegmentEditorDialogOptions, setSelectedSegmentId } = useDedicatedMapProvider();
  const [isOpen, setIsOpen] = useState(false);
  const [filterDraft, setFilterDraft] = useState(remoteFilter);

  useEffect(() => {
    setFilterDraft(remoteFilter);
  }, [remoteFilter]);

  const selectedSegment = useMemo(
    () => segments.find((segment) => segment.segmentId === selectedSegmentId),
    [segments, selectedSegmentId]
  );

  const clearFilter = () => {
    setFilterDraft(defaultCustomHierarchyFilter);
    applyFilter(defaultCustomHierarchyFilter, null);
  };

  const postMessage = useMapWorkerPostMessage();

  const applyFilter = (filter: CollectionFilter<number>, segmentId: number | null) => {
    setSelectedSegmentId(segmentId);
    postMessage({ type: 'custom-hierarchy-filter-change', filter: { ...remoteFilter, ...filter } });
  };

  const handleClose = () => {
    if (!isCollectionFilterEqual(filterDraft, remoteFilter)) applyFilter(filterDraft, selectedSegmentId);
    setIsOpen(false);
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpened={() => setIsOpen(true)}
      onClosed={handleClose}
      onInteraction={(nextOpenState) => {
        if (!nextOpenState) handleClose();
      }}
      minimal
      placement="bottom-end"
      content={
        <div className={b('filterSelectionWrapper')}>
          <Text className={b('filterMapsTitle')}>{formatMessage('FILTER_MAPS_BY')}</Text>
          <SegmentPicker
            segments={segments}
            selectedSegmentId={selectedSegmentId}
            onApplySegment={(filter, segmentId) => {
              setFilterDraft(filter);
              setSelectedSegmentId(segmentId);
              applyFilter(filter, segmentId);
            }}
            onChooseCreateBlank={() => {
              handleClose();
              setSegmentEditorDialogOptions({
                mode: SegmentEditorDialogModes.CREATE,
                filter: defaultCustomHierarchyFilter
              });
            }}
          />
          {selectedSegmentId === null && (
            <>
              <DividerV2 />
              <div className={b('customHierarchyFilterMenuWrapper')}>
                <CustomHierarchyFilterMenuV2
                  filter={filterDraft}
                  hierarchies={customHierarchies}
                  onUpdateFilter={(filter) => setFilterDraft(filter)}
                />
              </div>
            </>
          )}
          <div className={b('filterFooter')}>
            <div className={b('saveSegment')}>
              {selectedSegment ? (
                <IconButton
                  testId="edit-segment-button"
                  icon={<Edit />}
                  onClick={() => {
                    setSegmentEditorDialogOptions({
                      mode: SegmentEditorDialogModes.EDIT,
                      segment: selectedSegment,
                      filter: filterDraft
                    });
                    handleClose();
                  }}
                  tooltipText={formatMessage('EDIT_SEGMENT')}
                  minimal
                  type="button"
                  tooltipPlacement="bottom"
                />
              ) : (
                <IconButton
                  testId="save-segment-button"
                  icon={<Save />}
                  onClick={() => {
                    setSegmentEditorDialogOptions({ mode: SegmentEditorDialogModes.CREATE, filter: filterDraft });
                    handleClose();
                  }}
                  tooltipText={formatMessage('SAVE_SEGMENT')}
                  minimal
                  type="button"
                  tooltipPlacement="bottom"
                />
              )}
            </div>
            <div className={b('filterApplyAndClear')}>
              <Button
                minimal
                text={formatMessage('CLEAR_ALL')}
                onClick={clearFilter}
                data-testid="clear-filter-button"
              />
              <Button
                className={b('applyFilterButton')}
                text={formatMessage('APPLY')}
                onClick={() => applyFilter(filterDraft, selectedSegmentId)}
                data-testid="apply-filter-button"
              />
            </div>
          </div>
        </div>
      }
    >
      <TextButton
        text={selectedSegment?.segmentName || formatMessage('HIERARCHIES')}
        type="button"
        minimal
        large={false}
        rightIcon={<ChevronDown />}
        testId="filter-button"
        onClick={() => setIsOpen((prevIsMapGridFilterOpen) => !prevIsMapGridFilterOpen)}
        active={isOpen}
      />
    </Popover>
  );
};

export default MapGridFilterV2;
