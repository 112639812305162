import { DocumentNode } from 'graphql';

export const getOperationName = (documentNode: DocumentNode) => {
  for (const defn of documentNode.definitions) {
    if (defn.kind === 'OperationDefinition' && defn.name?.value) {
      return defn.name.value;
    }
  }
  console.error(`Unable to get operation name from documentNode`, documentNode);
  return null;
};
