// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GyX-g {\n  display: flex;\n  flex-direction: column;\n  padding: var(--xs);\n  height: 100%;\n  overflow: auto;\n}\n\n  .yJ0ol {\n    font-size: 14px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SegmentCards": "GyX-g",
	"SegmentCards_title": "yJ0ol"
};
export default ___CSS_LOADER_EXPORT___;
