import React from 'react';

import { Menu } from '@blueprintjs/core';
import { AddAlt, DocumentImport, Edit, OverflowMenuVertical } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import ConfirmDeleteModal from 'components/Dialog/ConfirmDeleteModal/ConfirmDeleteModal';
import { MenuItem } from 'components/menu/MenuItem';
//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import Popover from 'components/Popover/Popover';

import { useScope } from 'app/contexts/scopeProvider';

import { useFileUpload } from 'app/core/fileUpload/fileUploadProvider';

import { useExportData } from 'app/graphql/hooks/useExportData';

import { HierarchySpec, HierarchyType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CommandCenterHierarchyToolbar.module.pcss';

const b = block(style);

export const CommandCenterHierarchyToolbar = ({
  setShowDeleteHierarchyDialog,
  showDeleteHierarchyDialog,
  selectedHierarchy,
  deletingHierarchy,
  handleConfirmDeleteModal
}: {
  setShowDeleteHierarchyDialog: (value: boolean) => void;
  showDeleteHierarchyDialog: boolean;
  selectedHierarchy: HierarchySpec;
  deletingHierarchy: boolean;
  handleConfirmDeleteModal: (e: React.FormEvent<HTMLInputElement>) => void;
}): React.ReactElement => {
  const isGeoSelected = selectedHierarchy?.hierarchyType === HierarchyType.GeographicTerritoryHierarchy;
  const { setShowAddHierarchyDialog, setShowEditHierarchyDialog, setShowUploadHierarchyDialog } = useFileUpload();
  const { selectedPlanningCycle } = useScope();
  const { startExportData } = useExportData();

  return (
    <div className={b()} data-testid="command-center-hierarchy-toolbar">
      <div className={b('iconButton')} data-testid="command-center-hierarchy-toolbar-upload-btn">
        <IconButton
          type="button"
          icon={<DocumentImport />}
          tooltipText={selectedHierarchy ? formatMessage('UPLOAD_HIERARCHY') : formatMessage('UPLOAD_HIERARCHY_NONE')}
          onClick={() => setShowUploadHierarchyDialog(true)}
          testId="command-center-hierarchy-toolbar-upload-button"
          title={formatMessage('UPLOAD')}
          disabled={!selectedHierarchy || isGeoSelected}
        />
      </div>
      <div className={b('iconButton')} data-testid="command-center-hierarchy-toolbar-add-btn">
        <IconButton
          type="button"
          icon={<AddAlt />}
          tooltipText={formatMessage('ADD_HIERARCHY')}
          onClick={() => setShowAddHierarchyDialog(true)}
          testId="command-center-hierarchy-toolbar-add-button"
          title={formatMessage('ADD')}
        />
      </div>
      <div className={b('iconButton')} data-testid="command-center-hierarchy-toolbar-edit-btn">
        <IconButton
          type="button"
          icon={<Edit />}
          tooltipText={selectedHierarchy ? formatMessage('EDIT_HIERARCHY') : formatMessage('EDIT_HIERARCHY_NONE')}
          onClick={() => setShowEditHierarchyDialog(true)}
          testId="command-center-hierarchy-toolbar-edit-button"
          title={formatMessage('EDIT')}
          disabled={!selectedHierarchy || isGeoSelected}
        />
      </div>
      <div className={b('iconButton')} data-testid="command-center-hierarchy-toolbar-delete-btn">
        <MessageTooltip
          disabled={!!selectedHierarchy}
          content={formatMessage('SELECT_A_HIERARCHY')}
          placement="top"
          target={
            <Popover
              disabled={!selectedHierarchy}
              placement="bottom-end"
              content={
                <Menu data-testid="command-center-hierarchy-toolbar-menu">
                  <MenuItem
                    text={formatMessage('EXPORT_TO_CSV')}
                    onClick={() => {
                      startExportData({
                        planningCycleId: selectedPlanningCycle.id,
                        tableName: selectedHierarchy?.rootName
                      });
                    }}
                    data-testid="command-center-hierarchy-toolbar-export-button"
                  />
                  <MenuItem
                    disabled={isGeoSelected}
                    text={formatMessage('DELETE')}
                    onClick={() => {
                      setShowDeleteHierarchyDialog(true);
                    }}
                    data-testid="command-center-hierarchy-toolbar-delete-button"
                  />
                </Menu>
              }
            >
              <IconButton
                type="button"
                icon={<OverflowMenuVertical data-testid="add-icon" tabIndex="0" />}
                testId="command-center-hierarchy-toolbar-options-button"
                title={formatMessage('OPTIONS')}
                disabled={!selectedHierarchy}
              />
            </Popover>
          }
        />
      </div>
      {showDeleteHierarchyDialog && (
        <ConfirmDeleteModal
          isOpen={showDeleteHierarchyDialog}
          onConfirmDelete={handleConfirmDeleteModal}
          onCancel={(e) => {
            e.stopPropagation();
            setShowDeleteHierarchyDialog(false);
          }}
          bodyText={
            <>
              {formatMessage('DELETE_HIERARCHY_WITH_NAME', { name: selectedHierarchy?.rootName })}
              <br />
              <br />
              {formatMessage('DELETE_HIERARCHY_CONFIRMATION_MESSAGE')}
            </>
          }
          titleText={formatMessage('DELETE')}
          isSubmitting={deletingHierarchy}
        />
      )}
    </div>
  );
};
