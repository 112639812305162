import React from 'react';

import { Help } from '@carbon/icons-react';
import { Link } from 'react-router-dom';

import IconButton from 'components/Buttons/IconButton/IconButton';
//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import block from 'utils/bem-css-modules';
import { config } from 'utils/config';
import { formatMessage } from 'utils/messages/utils';

import style from './HelpButton.module.pcss';

const b = block(style);

const HelpButton: React.FC = () => {
  return (
    <MessageTooltip
      content={formatMessage('PRODUCT_DOCUMENTATION')}
      target={
        <Link to={{ pathname: config.DOCS_SITE_URL! }} target="_blank" rel="noopener noreferrer">
          <div className={b()}>
            <IconButton type="button" icon={<Help size={24} />} className={b('helpBtn')} testId={'help-icon-button'} />
          </div>
        </Link>
      }
      placement={'bottom-start'}
      popoverClassName={b('popover')}
    />
  );
};

export default HelpButton;
