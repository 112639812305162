import React from 'react';

import { IllustrationFileBox, Text } from '@varicent/components';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './EmptyFilterMessage.module.pcss';

const b = block(style);

interface EmptyFilterMessageProps {
  territoryCount: number;
}

const EmptyFilterMessage: React.FC<EmptyFilterMessageProps> = ({ territoryCount }) => {
  return (
    <div className={b()}>
      <div className={b('illustration')}>
        <IllustrationFileBox size={192} />
      </div>
      <div data-testid="empty-filter-message">
        <Text>{formatMessage('EMPTY_MAP_FILTER_MESSAGE', { count: territoryCount })}</Text>
      </div>
    </div>
  );
};

export default EmptyFilterMessage;
