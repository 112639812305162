import { datadogRum } from '@datadog/browser-rum';

import { config } from 'utils/config';

import { transformAnalyticsEvent } from './datadogEventTransformers';

const ALLOWED_RUM_ENVS = ['production-eu', 'production-ue2', 'demo3-ue2'];
const isAllowedRUMEnv = (targetEnv: string) => {
  return ALLOWED_RUM_ENVS.includes(targetEnv);
};

const asNumberWithFallback = (value: string | undefined, fallback: number) => (value ? +value : fallback);

export const initializeAnalytics = (): void => {
  if (isAllowedRUMEnv(config.TARGET_ENV)) {
    datadogRum.init({
      applicationId: config.DATADOG_RUM_APPLICATION_ID,
      clientToken: config.DATADOG_RUM_CLIENT_TOKEN,
      enableExperimentalFeatures: ['feature_flags'],
      site: 'datadoghq.com',
      service: 'sales-planning',
      version: config.RELEASE_VERSION || '',
      env: config.TARGET_ENV,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      actionNameAttribute: 'data-testid',
      allowedTracingUrls: [config.APPSYNC_GRAPHQL_ENDPOINT],
      sessionSampleRate: asNumberWithFallback(config.DATADOG_RUM_SESSION_SAMPLE_RATE, 0),
      sessionReplaySampleRate: asNumberWithFallback(config.DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE, 0),
      traceSampleRate: asNumberWithFallback(config.DATADOG_RUM_TRACE_SAMPLE_RATE, 0),
      beforeSend: transformAnalyticsEvent
    });
    datadogRum.setGlobalContextProperty('env', config.TARGET_ENV);
  }
};
