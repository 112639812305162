import React, { CSSProperties, useEffect, useState } from 'react';

import { Button } from '@varicent/components';

//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { useIsMapCapable } from 'app/components/TerritoryMap/hooks/useMapCapability';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';

import { RuleForMap, TerritoryGroupForMap } from 'app/models';

import block from 'utils/bem-css-modules';
import { MapCapability } from 'utils/maps/mapCapabilityRegistry';

import style from './TerritoryColorCellRenderer.module.pcss';
import TerritoryColorCellRendererTooltipContent from './TerritoryColorCellRendererTooltipContent';

const b = block(style);

interface TerritoryColorCellRendererProps {
  onUpdateRules: () => void;
  data: { isIgnored: boolean; isLocked: boolean; rule: RuleForMap; groupForLevel: TerritoryGroupForMap };
}

export const TerritoryColorCellRenderer: React.FC<TerritoryColorCellRendererProps> = (
  props: TerritoryColorCellRendererProps
) => {
  const { isIgnored, isLocked, rule, groupForLevel } = props.data;
  const { openTerritoryColorPaletteId, setOpenTerritoryColorPaletteId } = useMapVariant();
  const { isColoringByTerritoryGroup } = useDedicatedMapProvider();
  const [showChangeColorTooltip, setShowChangeColorTooltip] = useState(false);

  const isCapableOfRecolor = useIsMapCapable(MapCapability.RECOLOR);
  const isRecolorEnabled = isCapableOfRecolor && !isIgnored && !isLocked && !isColoringByTerritoryGroup;

  const togglePalette = (e) => {
    e.stopPropagation();
    const { ruleId } = rule;
    if (openTerritoryColorPaletteId === ruleId) {
      setOpenTerritoryColorPaletteId(null);
    } else {
      setOpenTerritoryColorPaletteId(ruleId);
    }
  };

  const toggleTooltip = () => {
    setShowChangeColorTooltip((prev) => !prev);
  };

  const isPaletteOpen = openTerritoryColorPaletteId === rule.ruleId;
  const isTooltipOpen = isPaletteOpen || showChangeColorTooltip;

  useEffect(() => {
    if (!isRecolorEnabled && isPaletteOpen) {
      setOpenTerritoryColorPaletteId(null);
    }
  }, [isRecolorEnabled, isPaletteOpen]);

  const color = isColoringByTerritoryGroup ? groupForLevel?.color : rule?.color;
  return (
    <MessageTooltip
      data-testid="change-color-tooltip"
      content={
        <TerritoryColorCellRendererTooltipContent
          onUpdateRules={props.onUpdateRules}
          isPaletteOpen={isPaletteOpen}
          rule={rule}
        />
      }
      theme={isPaletteOpen ? 'light' : 'dark'}
      isOpen={isTooltipOpen}
      disabled={!isRecolorEnabled}
      target={
        <Button
          className={b('coloredSquare', { isIgnored })}
          style={{ '--territory-color': color } as CSSProperties}
          disabled={!isRecolorEnabled}
          data-testid={'territory-color-preview'}
          onClick={isRecolorEnabled ? togglePalette : null}
          onPointerEnter={toggleTooltip}
          onPointerLeave={toggleTooltip}
        />
      }
      placement="right"
    />
  );
};

export default TerritoryColorCellRenderer;
