import { useEffect } from 'react';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import { SegmentFilter } from 'app/graphql/generated/graphqlApolloTypes';
import { useGetSegments } from 'app/graphql/queries/getSegments';

export const useSegments = () => {
  const { selectedBattleCardId, selectedDeploymentModelId } = useMapContextRedistributor();

  const postMessage = useMapWorkerPostMessage();

  const { data } = useGetSegments({
    fetchPolicy: 'network-only',
    variables: {
      battlecardId: parseInt(selectedBattleCardId),
      deploymentModelId: selectedDeploymentModelId
    }
  });

  useEffect(() => {
    const allSegments: SegmentFilter[] = data?.getDeploymentModelSpec?.battlecards?.[0]?.segments ?? [];
    const singleClauseSegments = allSegments.filter(
      (segment) => segment.clauses.length === 1 && segment.clauses[0].hierarchies.length > 0
    );

    postMessage({
      type: 'segments-change',
      segments: singleClauseSegments
    });
  }, [data]);
};
